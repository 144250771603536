import React from 'react';

import {useTypoContent} from '../../../../../api/hooks/useTypoContent.hook';
import {TypoElementNewsListitem} from '../../../../../api/models/typo/typo-element-news-listitem';
import {TypoService} from '../../../../../api/services/typo.service';
import {getMediaType, Media} from '../../../../_shared/media/media';
import {Headline} from '../../../_shared/headline/headline';
import {Link} from '../../../_shared/link/link';
import styles from './academy_template.module.scss';

const Academy_Template = (props: TypoElementNewsListitem): JSX.Element => {

    const { uid, title, teaser, bodytext, categories, author, relatedNews, falRelatedFiles, datetime, slug, inverted, returnUrl, view } = props;
    const typoService = new TypoService(useTypoContent());
    const truncateTeaser = teaser.length > 340 ? teaser.slice(0, 340).trim() + '...' : teaser;
    const mainImage = falRelatedFiles ? falRelatedFiles[0] : undefined;
    const image = mainImage?.images.defaultImage;
    const imageProps = mainImage?.properties;
    const imageUrl = image?.publicUrl;
    const imageType = imageProps?.mimetype;

    const generateCategories = () => {
        return categories.map((item) => {

            return (
                <Link href={`${returnUrl}?catid=${item.id}`} key={`cat-${item.id}`} class={styles['link']}>
                    <div className={styles['category']}>
                        {item.title}
                    </div>
                </Link>
            );
        })
    }

    const generateAuthor = (view: 'list' | 'detail') => {
        return (
            <div className={`${styles['card-author']} ${styles[view]} ${view === 'detail' ? styles['spacing'] : ''}`}>
                <div className={styles['author']}>
                    <img className={styles['author-image']} src="/favicon.svg" alt="Profile" />
                    <div className={styles['author-name']}>{author?.author ?? ''}</div>
                </div>
                {view === 'list' && (
                    <div className={styles['created']}>
                        {datetime}
                    </div>
                )}
            </div>
        )
    }

    const generateRelatedNews = () => {
        if (!relatedNews) {
            return;
        }

        return (
            <>
                <h2 className={`${styles['news-related-headline']}`}>
                    {typoService.getLabel('content-elements', 'related-news')}
                </h2>
                <div className={`${styles['news-related-news']}`}>
                    {relatedNews.map((element) => {
                        return <div
                            className={styles['news-item']}
                            key={'news-list-item-container-' + element.uid}>
                            <Academy_Template {...element} returnUrl={returnUrl} inverted={inverted} view="list" />
                        </div>
                    })}
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                    <div className={styles['news-item']}></div>
                </div>
            </>
        )
    }

    return (
        <div
            className={`
                ${styles['academy_template']} 
                ${styles['academy-' + view]}
                ${inverted ? styles['inverted'] : ''}
            `}
        >
            {String(view) === 'list' ? (
                <Link
                    href={slug ?? ''}
                    class={styles['link']}
                >
                    <div className={styles['card']}>
                        {imageUrl && (
                            <div className={styles['card-image']}>
                                <Media mediaId={uid} contentType={getMediaType(imageType)} src={imageUrl}
                                       inverted={inverted}
                                       caption={imageProps?.description as string}
                                       redirectUrl={imageProps?.link as string}
                                       title={imageProps?.title as string}
                                       alt={imageProps?.alternative as string} />
                            </div>
                        )}
                        <div className={styles['card-content']}>
                            {categories && (
                                <div className={styles['card-categories']}>
                                    {generateCategories()}
                                </div>
                            )}
                            <Headline
                                {...props}
                                header={title}
                                bodytext={truncateTeaser}
                                variant="teaser"
                            />
                            {generateAuthor('list')}
                        </div>
                    </div>
                </Link>
            ) : (
                <div className={styles['wrapped']}>
                    <Headline
                        {...props}
                        bodytext={teaser}
                        variant="teaser"
                    />
                    {falRelatedFiles && falRelatedFiles.length > 1 && (
                        <div className={`${styles['thumbnail-container']} ${styles['spacing']}`}>
                            {falRelatedFiles.map((item) => {
                                return(
                                    <div key={`media-${item.properties.id}`} className={styles['thumbnail']}>
                                        <Link href={item.images.defaultImage.publicUrl} class={styles['link']} target="_blank">
                                            <Media
                                                mediaId={item.properties.id}
                                                inverted={inverted}
                                                contentType={getMediaType(item.type)}
                                                src={item.images.defaultImage.publicUrl}
                                                caption={item.properties.description as string}
                                                redirectUrl={item.properties.link as string}
                                                title={item.properties.title as string}
                                                alt={item.properties.alternative as string}
                                            />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    <div className="bodytext-container"
                         dangerouslySetInnerHTML={{
                             __html: bodytext as string
                         }}
                    >
                    </div>
                    <div className={`${styles['card-categories']} ${styles['spacing']}`}>
                        {generateCategories()}
                    </div>
                    {generateAuthor('detail')}
                    {generateRelatedNews()}
                </div>
            )}
        </div>
    );
};

export { Academy_Template };
