import React from 'react';

import { useTypoContent } from '../../../api/hooks/useTypoContent.hook';
import { StrapiLinkProps } from '../../../api/models/strapi/strapi-link-props';
import { StrapiTextFieldProps } from '../../../api/models/strapi/strapi-text-field-props';
import { TypoService } from '../../../api/services/typo.service';
import { LogoHorizontalSVGComponent } from '../../../assets/icons/logo-horizontal';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Media } from '../media/media';
import NewsletterInput from '../newsletter-input/newsletter-input';
import { Seperator } from '../seperator/seperator';
import { Spacer } from '../spacer/spacer';
import { Typography } from '../typography/typography';
import styles from './footer.module.scss';
import { FooterCopyright } from './footer-additional-links/footer-additional-links';
import { FooterLinkContainer } from './footer-link-container/footer-link-container';

export interface FooterProps {
    className?: string;
    isReduced?: boolean;
    typoService?: TypoService;
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {

    const typoService = props.typoService ? props.typoService : new TypoService(useTypoContent());
    const footerLinks = typoService.getFooterNavLinks();
    const newsletterPlaceholder: StrapiTextFieldProps = {
        id: 62,
        fieldHint: typoService.getLabel('page', 'footer-email-placeholder'),
        fieldLabel: typoService.getLabel('page', 'footer-email-placeholder'),
        fieldType: '',
        fieldName: ''
    }

    return (
        <div
            className={styles['footer-wrapper']}
            data-testid="footer-wrapper"
            style={{
                backgroundColor: props.isReduced
                    ? typoService.getLabel('page', 'footer-background-color') ?? '#1a1a1a'
                    : undefined,
                display: !props.isReduced ? 'flex' : undefined,
                flexDirection: !props.isReduced ? 'column' : undefined
            }}
        >
            {!props.isReduced && (
                <Media
                    className={styles['footer-image']}
                    src={typoService.getLabel('page', 'footer-background-url')}
                    alt={typoService.getLabel('page', 'footer-background-alttext')}
                />
            )}
            <div
                style={{
                    backgroundColor: !props.isReduced
                        ? typoService.getLabel('page', 'footer-background-color') ?? '#1a1a1a'
                        : undefined
                }}
            >
                <footer
                    data-testid="footer"
                    className={`
                ${
                        props.isReduced
                            ? styles['reduced-footer']
                            : styles['footer']
                    } ${props.className ?? ''}`}
                >
                    {props.isReduced ? (
                        <FooterCopyright />
                    ) : (
                        <>
                            <div className={styles['footer-full-body']}>
                                <div style={{ zIndex: 3, margin: '0 10px' }}>
                                    <LogoHorizontalSVGComponent
                                        fill="white"
                                        width="220px"
                                    />
                                </div>
                                <div style={{ zIndex: 3 }}>
                                    <div style={{ flex: 1 }}>
                                        <div>
                                            <Typography
                                                variant={
                                                    TypographyVariantsEnum.FOOTER_PARAGRAPH
                                                }
                                            >
                                                {typoService.getLabel('page', 'footer-paragraph')}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                style={{
                                                    color: 'white',
                                                    fontSize: '23px'
                                                }}
                                                variant={
                                                    TypographyVariantsEnum.HEADING4_BOLD
                                                }
                                            >
                                                {typoService.getLabel('page', 'footer-email-headline')}
                                            </Typography>
                                            <Spacer height="40px" />{' '}
                                            <Typography
                                                variant={
                                                    TypographyVariantsEnum.FOOTER_PARAGRAPH
                                                }
                                            >
                                                {typoService.getLabel('page', 'footer-email-paragraph')}
                                            </Typography>
                                            <Spacer height="40px" />
                                            <NewsletterInput
                                                successMessage={typoService.getLabel('page', 'footer-email-success')}
                                                errorMessage={typoService.getLabel('page', 'footer-email-error')}
                                                inputTexts={newsletterPlaceholder}
                                                inverted={true}
                                                otherIcon={[
                                                    'fas',
                                                    'paper-plane'
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            minWidth: ' 300px',
                                            flexDirection: 'row',
                                            flex: 1,
                                            marginRight: 'unset'
                                        }}
                                    >
                                        {footerLinks.map(
                                            ({ links, headline }) => (
                                                <FooterLinkContainer
                                                    key={
                                                        headline?.headlineContent +
                                                        links.join(' ')
                                                    }
                                                    headline={
                                                        headline?.headlineContent
                                                    }
                                                    links={links as unknown as StrapiLinkProps[]}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                                <Seperator
                                    width="100%"
                                    color="white"
                                    height="1px"
                                    margin="20px 0"
                                />
                                <div>
                                    <FooterCopyright typoService={typoService} notReduced />
                                </div>
                            </div>
                        </>
                    )}
                </footer>
            </div>
        </div>
    );
};

Footer.defaultProps = { isReduced: false };
