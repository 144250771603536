import supportedLang from '../../config/supportedLang.json';
import {isServer} from './isServer';

const isStorybook = () => {
    if (isServer()) return false;
    return (
        ((window as unknown) as Record<string, string>)?.STORYBOOK_ENV ===
        'react'
    );
};

const getLanguage = (): string => {
    if (isServer() || isStorybook()) return 'en';
    const currentLang = location.pathname.split('/')?.[1];

    if (supportedLang.includes(currentLang)) return currentLang;
    else return 'en';
};

const getLocalePrice = (num?: number): string => {
    if (num === undefined || num === null) return '';
    if (Math.round(num).toString().length >= 7) {
        return new Intl.NumberFormat(getLanguage(), {
            notation: 'compact',
            compactDisplay: 'short'
        }).format(num);
    } else if (num % 1 === 0) {
        return num.toLocaleString(getLanguage());
    } else {
        return num.toLocaleString(getLanguage(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
}

const getLocaleEthPrice = (num?: number): string => {
    if (num === undefined || num === null) return '';
    return num.toLocaleString(getLanguage(), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 5
    });
}

export {getLanguage, getLocaleEthPrice, getLocalePrice};
