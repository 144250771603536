import { createDataHook } from 'next-data-hooks';

import { backend } from '../../utils/_config/axios';
import { BasePageData } from '../models/strapi/base-page-data';
import { StrapiButtonProps } from '../models/strapi/strapi-button-props';
import { StrapiFaqContainer } from '../models/strapi/strapi-faq-container';
import { StrapiFaqItem } from '../models/strapi/strapi-faq-item';
import { StrapiHeadline } from '../models/strapi/strapi-headline';
import { StrapiLinkProps } from '../models/strapi/strapi-link-props';
import { StrapiMeta } from '../models/strapi/strapi-meta';
import { StrapiStage } from '../models/strapi/strapi-stage';
import { StrapiTextFieldProps } from '../models/strapi/strapi-text-field-props';
import { StrapiTextSectionProps } from '../models/strapi/strapi-text-section-props';

export const useLoginPage = createDataHook('login-page', async ({ locale }) => {
    const localeRes = !locale || locale === 'catchAll' ? 'en' : locale;
    const result = (
        await backend().get<UseRegisterPageData>(`api/strapi/login-page?_locale=${localeRes}`)
    ).data;

    result.faqbox = await Promise.all(
        result.faqbox.map(async (box) => {
            box.fa_qs = await Promise.all(
                box.fa_qs?.map(async (faqIdItem) => {
                    const { id } = (faqIdItem as unknown) as { id: number };

                    return (await backend().get<StrapiFaqItem>(`api/strapi/fa-qs/${id}`))
                        .data;
                })
            );
            return box;
        })
    );

    return result;
});

interface UseRegisterPageData extends BasePageData {
    password: StrapiTextFieldProps;
    emailOrUsername: StrapiTextFieldProps;
    loginButton: StrapiButtonProps;
    faqbox: StrapiFaqContainer[];
    stage: StrapiStage;
    meta: StrapiMeta;
    loginHeader: StrapiTextSectionProps;
    loginFormHeader: StrapiHeadline;
    registerLinkText: string;
    loginFailed: string;
    forgotPassword: StrapiLinkProps;
}
