import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {LogoSvgComponent} from '../../../assets/icons/logo.svg';
import {fetchCurrentProduct, resetFetchCurrentProductState, RootState} from '../../../redux';
import {ButtonVariantsEnum} from '../../../utils/_enums/button-variants.enum';
import {ResponseCodes} from '../../../utils/_enums/response-codes.enum';
import {RoutePath} from '../../../utils/_enums/route-path.enum';
import {useAppSelector} from '../../../utils/_hooks/hooks';
import {LanguageSelectorOptions} from '../../../utils/_models/languageSelectorOptions';
import {Button, ButtonProps} from '../button/button';
import LanguageSelector from '../language-selector/language-selector';
import styles from './header.module.scss';
import {HeaderBackground} from './header-background/header-background';
import {HeaderNavItem} from './header-nav-item/header-nav-item';

export interface Props {
    navLinks?: { label: string; value: RoutePath; external?: boolean }[];
    buttons?: ButtonProps[];
    inverted?: boolean;
    element?: string;
    className?: string;
    showBackground?: boolean;
    languages?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    loggedIn?: boolean;
    pageType?: string;
    onLogoClick?: () => void;
}

export const Header: React.FC<Props> = ({
    navLinks,
    buttons,
    inverted,
    element,
    className,
    showBackground,
    loggedIn,
    onLogoClick,
    forStaticPage,
    languages,
    pageType
}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    const { fetchCurrentProductModelStatus, basket, pending } = useAppSelector(
        (state) => state.checkoutSlice,
    );

    const { hasElementsInBasket } = useAppSelector((state: RootState) => ({
        hasElementsInBasket: state.userSlice.user?.hasElementsInBasket
    }));

    // Add event listener to update scroll position
    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.scrollY;
            setShowMenu(currentPosition <= scrollPosition || currentPosition === 0);
            setScrollPosition(currentPosition);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);

    useEffect(() => {
        if (basket && fetchCurrentProductModelStatus === 200) {
            router.push(RoutePath.CHECKOUT);
        }
    }, [basket, fetchCurrentProductModelStatus]);

    useEffect(() => {
        if (
            fetchCurrentProductModelStatus &&
            fetchCurrentProductModelStatus !== 200 &&
            router.pathname !== RoutePath.CHECKOUT
        ) {
            dispatch(resetFetchCurrentProductState());
        }
    }, [fetchCurrentProductModelStatus]);

    const childElements = (
        <motion.div
            id="main-navbar"
            className={`
                ${styles['sticky']} 
                ${scrollPosition === 0 ? styles['transparent'] : ''}
                ${showMenu ? styles['normal'] : styles['scrolled']}
            `}
        >
            <div
                className={`${styles['header-container']} ${inverted ? styles.inverted : ''
                    }`}
            >
                <div
                    className={styles['logo']}
                    onClick={() => {
                        onLogoClick ? onLogoClick() : undefined;
                        router.push(RoutePath.DEFAULT_PAGE);
                    }}
                >
                    <LogoSvgComponent height={'100%'} />
                </div>

                {navLinks && navLinks.length > 0 && (
                    <nav className={styles['nav-container']}>
                        {navLinks?.map(({value, label, external}, index) => (
                            <HeaderNavItem
                                external={external}
                                key={value + index}
                                href={value}
                                inverted={inverted}
                            >
                                {label}
                            </HeaderNavItem>
                        ))}

                        {languages && languages.length > 0 &&
                            <LanguageSelector
                                isStaticPage={forStaticPage}
                                pageType={pageType}
                                languageOptions={languages}
                            />
                        }

                    </nav>
                )}

                {buttons && buttons.length > 0 &&
                    <div className={styles['buttons']}>
                        {loggedIn && hasElementsInBasket ?
                            <div className={styles['checkout-button-wrapper']}>
                                <Button
                                    onClick={() => {
                                        dispatch(fetchCurrentProduct());
                                    }}
                                    inverted={inverted}
                                    variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                                    icon={{ element: <FontAwesomeIcon icon={['fas', 'shopping-cart']} />, align: 'left' }}
                                    pending={pending || fetchCurrentProductModelStatus === ResponseCodes.OK}
                                />
                            </div> : null
                        }
                        {buttons?.map((props) => (
                            <Button
                                {...props}
                                inverted={inverted}
                                key={props.children as string}
                            />
                        ))}
                    </div>
                }
            </div>
        </motion.div>
    );

    const wrapWithBackground = (
        <div className={styles['header-background-container']}>
            <HeaderBackground />
            <div>{childElements}</div>
        </div>
    );

    return React.createElement(
        element as string,
        {
            className: `${styles[
                showBackground
                    ? 'header-container lite'
                    : 'header-container'
            ]
                } ${inverted ? styles.inverted : ''} ${className ?? ''}`,
            'data-testid': 'header-container'
        },
        showBackground ? wrapWithBackground : childElements
    );
};

Header.defaultProps = {
    navLinks: [],
    buttons: [],
    inverted: false,
    element: 'header',
    showBackground: false,
    loggedIn: false
};
