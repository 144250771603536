import React from 'react';

import { TypoElementText } from '../../../api/models/typo/typo-element-text';
import { Headline } from '../_shared/headline/headline';
import styles from './text.module.scss';

const Text = (props: TypoElementText): JSX.Element => {

    const { id, inverted, bodytext, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['text-section']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} noContentSpacing={true} />
            </div>
            <div className="bodytext-container"
                 dangerouslySetInnerHTML={{
                     __html: bodytext as string
                 }}
            />
        </div>
    );
};

Text.defaultProps = {};

export { Text };
