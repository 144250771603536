import React from 'react';

import {Close} from '../../../assets/icons/close.svg';
import {Modal} from '../modal/modal';
import ModalButton from './button/modal-button';
import styles from './dialog.module.scss';

interface DialogProps {
    className?: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const Dialog = (props: DialogProps): JSX.Element => {
    if (props.children) {
        return (
            <Modal>
                <div className={styles['modal-wrapper']}
                     onClick={(e) => {
                         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore
                         if (e.target.id === 'dialog') {
                             props.onClose();
                         }}}
                     id={'dialog'}
                     data-testid="dialog"
                >
                    <div className={styles['modal-container']}>
                        <div className={styles['wrapper']} >
                            <div className={styles['backdrop-wrapper']} >
                                <div className={styles['content']} >
                                    {props.children}
                                </div>
                            </div>
                            <div className={styles['close-wrapper']}>
                                <ModalButton onClick={props.onClose} >
                                    <Close/>
                                </ModalButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    } else {
        return <></>;
    }
};

export default Dialog;