import React, {useEffect} from 'react';
import {useForm, UseFormProps} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {resetLoginState, RootState, useAppSelector} from '../../../../../redux';
import {LinkVariants} from '../../../../../utils/_enums/link-variants.enum';
import {RoutePath} from '../../../../../utils/_enums/route-path.enum';
import {TypographyVariantsEnum} from '../../../../../utils/_enums/typeography-variants.enum';
import {Button, ButtonProps} from '../../../button/button';
import {ErrorMessage} from '../../../error/error-message';
import {Input, InputProps as TextFieldProps} from '../../../input-fields/input/input';
import TextArea, {TextAreaProps} from '../../../input-fields/text-area/text-area';
import {Link} from '../../../link/link';
import RadioButtonGroup, {RadioButtonGroupProps} from '../../../radio-button-group/radio-button-group';
import {CustomSelectProps as SelectProps, Select} from '../../../select/select';
import {Typography} from '../../../typography/typography';
import {HeaderMobilePopup} from '../header-mobile-popup';
import styles from './header-mobile-form.module.scss';

const resolveVariant = (
    variant: 'input' | 'select' | 'radio' | 'textarea',
    register: any,
    props: any,
    errorMessage?: string
) => {
    switch (variant) {
        case 'input': {
            return (
                <Input
                    key={props?.name}
                    errorMessage={errorMessage}
                    register={register}
                    name={props?.name}
                    {...props}
                />
            );
        }
        case 'select': {
            return (
                <Select
                    key={props?.name}
                    errorMessage={errorMessage}
                    register={register}
                    name={props?.name}
                    {...props}
                />
            );
        }
        case 'radio': {
            return (
                <RadioButtonGroup
                    key={props?.name}
                    errorMessage={errorMessage}
                    register={register}
                    name={props?.name}
                    {...props}
                />
            );
        }
        default: {
            return (
                <TextArea
                    key={props?.name}
                    errorMessage={errorMessage}
                    register={register}
                    name={props?.name}
                    {...props}
                />
            );
        }
    }
};

export interface Props {
    formtitle: string;
    forgotPassword: string;
    onSubmit: <T>(data: T) => void;
    open?: boolean;
    className?: string;
    onCancelClick: (open: Props['open']) => void;
    formConfig?: UseFormProps;
    submitButtonProps?: Omit<ButtonProps, 'onClick'>;
    fields: ((
        | Omit<TextFieldProps, 'register'>
        | Omit<SelectProps<unknown>, 'register'>
        | Omit<RadioButtonGroupProps<unknown>, 'register'>
        | Omit<TextAreaProps, 'register'>
        ) & {
        variant: 'input' | 'select' | 'radio' | 'textarea';
        name: string;
    })[];
    globalError?: { message: string };
}

export const HeaderMobileForm: React.FC<Props> = ({
                                                      onCancelClick,
                                                      formConfig,
                                                      forgotPassword,
                                                      fields,
                                                      onSubmit,
                                                      submitButtonProps,
                                                      formtitle,
                                                      children,
                                                      className,
                                                      open,
                                                      globalError
                                                  }) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm(formConfig);
    const dispatch = useDispatch();

    const loginStatus = useAppSelector(
        (state: RootState) => state.authentification.loginStatus
    );

    useEffect(() => {
        if (!open) {
            dispatch(resetLoginState());
            reset();
        }
    }, [open]);

    useEffect(() => {
        return () => {
            dispatch(resetLoginState());
        };
    }, []);

    if (!open) return null;

    return (
        <HeaderMobilePopup
            className={className}
            backgroundColor="white"
            onClick={() => onCancelClick(!open)}
        >
            <form
                className={`${styles['header-mobile-form']}`}
                noValidate
                onSubmit={handleSubmit((data) => {
                    onSubmit(data);
                })}
            >
                <Typography variant={TypographyVariantsEnum.HEADING5}>
                    {formtitle}
                </Typography>
                <div>
                    {fields.map(({variant, ...props}) =>
                        resolveVariant(
                            variant,
                            register,
                            props,
                            errors?.[props?.name]?.message
                        ))}
                    <ErrorMessage
                        className={styles['global-error-message']}
                        withoutGlobalClass
                    >
                        {globalError &&
                        (Object.keys(errors).length > 0 || (loginStatus && loginStatus !== 200))
                            ? globalError.message
                            : undefined}
                    </ErrorMessage>
                </div>

                <div className={styles['forgot-password-container']}>
                    <Link
                        variant={LinkVariants.READ_MORE}
                        key={'password-link'}
                        className={styles['forgot-password']}
                        href={RoutePath.RESET_PASSWORD}
                    >
                        {forgotPassword}
                    </Link>
                </div>
                <div className={styles['submit-button-container']}>
                    <Button {...submitButtonProps} />
                </div>           
                {children}
            </form>
        </HeaderMobilePopup>
    );
};

HeaderMobileForm.defaultProps = {
    formConfig: {},
    fields: []
};
