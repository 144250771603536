import { AxiosError } from 'axios';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { createDataHook } from 'next-data-hooks';
import { ParsedUrlQuery } from 'querystring';

import { store } from '../../redux';
import { typo } from '../../utils/_config/axios';
import { Typo } from '../models/typo';

export const getTypoData = async (locale: string | undefined, params: ParsedUrlQuery | undefined, token = '') => {
    const localeRes = !locale || locale === 'catchAll' || locale === 'en' ? 'en/' : locale + '/';
    const pageName = params && params.typoPage && Array.isArray(params.typoPage) ? params.typoPage.join('/') : '';
    const authorizationToken = token ?? store.getState().authentification.jwt;
    try {
        const response = await typo.get<Typo>(localeRes + 'page/' + pageName, {
            headers: {
                Authorization: `Bearer ${authorizationToken}`
            }
        })
        return response.data;
    } catch (err) {
        return handleError(err)
    }
}

export const useTypoContent = createDataHook(
    'TypoContent',
    async (context) => {
        const useContext = context as GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
        const jwt = useContext.req.cookies['jwt-cookie'];
        return getTypoData(useContext.locale, useContext.params, jwt ?? '');
    }
)

const handleError = (error: any) => {
    try {
        const responseError = error as AxiosError
        if (responseError &&
            responseError.response &&
            responseError.response.status) {
            if (responseError.response.status !== 404 &&
                responseError.response.status !== 403) {
                console.error('axios error in typo data hook', responseError.response.status);
            }
            return responseError.response.status;
        }
    } catch (err) {
        console.error(err);
    }
}