import { useRouter } from 'next/router';
import React from 'react';

import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import { LinkBase } from '../../link/link-base/link-base';
import { Typography } from '../../typography/typography';
import styles from './header-nav-item.module.scss';

export interface Props {
    href: string;
    children: string;
    target?: string;
    inverted?: boolean;
    external?: boolean;
    onClick?: () => void;
}

export const HeaderNavItem: React.FC<Props> = ({
                                                   href,
                                                   children,
                                                   target,
                                                   inverted,
                                                   external,
                                                   onClick
                                               }) => {
    const countArray = (children as string).length;
    const { asPath } = useRouter();

    const isActive = () => {
        const hrefWithoutDomain = href.replace(/^.*\/\/[^\/]+/, '');
        const hrefWithoutLang = hrefWithoutDomain.replace(/^\/[a-z]{2}\//, '/');
        if (asPath == href) {
            return true;
        } else if (asPath == hrefWithoutDomain) {
            return true;
        } else if (asPath == hrefWithoutLang) {
            return true;
        }
        return false;
    }

    return (
        <LinkBase
            style={{
                width: `${countArray * 11}px`
            }}
            external={external}
            href={href}
            target={target}
            className={`${styles['header-nav-item']} ${inverted ? styles['inverted'] : ''} ${isActive() ? styles['active'] : ''}`}
            onClick={onClick}
        >
            <Typography variant={TypographyVariantsEnum.BODY} element="span">
                {children}
            </Typography>
        </LinkBase>
    );
};

HeaderNavItem.defaultProps = { inverted: false };
