import NextImage from 'next/image';
import React, {useRef, useState} from 'react';
import useDimensions from 'react-cool-dimensions';

import useLoadImage from '../../../api/hooks/use-load-image.hook';
import creatoki from '../../../assets/creatoki.png';
import ControlsPauseButton from '../../../assets/icons/controls-btn-pause.svg';
import ControlsPlayButton from '../../../assets/icons/controls-btn-play.svg';
import {MediaContentType} from '../../../utils/_enums/image-content-type.enum';
import ImageSize from '../../../utils/_enums/image-size.enum';
import useBreakpoint from '../../../utils/_hooks/useBreakpoints.hook';
import {LinkBase} from '../link/link-base/link-base';
import styles from './media.module.scss';

export interface MediaProps {
    src?: string;
    mediaId?: number;
    alt?: string;
    caption?: string;
    title?: string;
    squared?: boolean;
    isProfile?: boolean;
    redirectUrl?: string;
    className?: string;
    classNameWrapper?: string;
    initialLoad?: boolean;
    contentType?: MediaContentType;
    posterUrl?: string;
    isAdmin?: boolean;
    imageSize?: ImageSize;
    objectFit?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectFit'];
    isTeaser?: boolean;
    hexagon?: boolean;
    autoplay?: boolean;
    controls?: boolean;
    inverted?: boolean;
    linkClassName?: string;
    dimensionalCard?: boolean;
    enlargeOnClick?: boolean;
    imageEffect?: {
        effect?: string;
        withSubline?: boolean;
    }
}


const getMediaType = (mediaType: string | null | undefined): MediaContentType => {
    switch (mediaType) {
        case 'image/jpg':
            return MediaContentType.JPG;
        case 'image/png':
            return MediaContentType.PNG;
        case 'video/mp4':
            return MediaContentType.MP4;
        case 'audio/mpeg':
            return MediaContentType.MP3;
        case 'image/gif':
            return MediaContentType.GIF;
        case 'video/youtube':
            return MediaContentType.YOUTUBE;
        case 'video/vimeo':
            return MediaContentType.YOUTUBE;
        default:
            return MediaContentType.JPEG;
    }
}

const Media = (props: MediaProps): JSX.Element => {

    const content = !props.src && props.mediaId
        ? useLoadImage(props.mediaId, props.isAdmin, props.imageSize)
        : { url: props.src, type: props.contentType };

    const { caption,  title } = props

    const [profilePictureError, setProfilePictureError] = useState(false);
    const dimensionalContainer = React.useRef(null);

    const [paddingTop, setPaddingTop] = useState(props.squared ? '100%' : '0');

    const [playing, setPlaying] = useState(false);

    const { observe, width, height } = useDimensions();

    const breakpoint = useBreakpoint();

    const imageSizes = [16, 32, 64, 128, 256, 512, 1024, 2048];

    const resetVideo = () => {
        setPlaying(false);
    };

    const videoRef = useRef<HTMLVideoElement>(null);

    const isVideoPlaying = (video: any) => !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

    const switchVideoControl = (e: any) => {
        e.preventDefault();
        setPlaying(!isVideoPlaying(videoRef.current));
        {
            isVideoPlaying(videoRef.current) ? videoRef.current?.pause() : videoRef.current?.play();
        }
    };

    const map = (val: number, minA: number, maxA: number, minB: number, maxB: number) => {
        return minB + ((val - minA) * (maxB - minB)) / (maxA - minA);
    }

    const cardDimensional = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>, type: 'touch' | 'mouse') => {
        const mouseX = () => {
            if (type === 'touch') {
                const rect = ev.target.getBoundingClientRect();
                return ev.targetTouches[0].pageX - rect.left;
            } else {
                return ev.nativeEvent.offsetX;
            }
        };
        const mouseY = () => {
            if (type === 'touch') {
                const rect = ev.target.getBoundingClientRect();
                return ev.targetTouches[0].pageY - rect.top;
            } else {
                return ev.nativeEvent.offsetY;
            }
        };
        const rotateY = map(mouseX(), 0, Math.floor(width ?? 480), -30, 30);
        const rotateX = map(mouseY(), 0, Math.floor(height ?? 480), 30, -30);
        const brightness = map(mouseY(), 0, Math.floor(height ?? 480), 1.5, .5);
        const scale = .9;

        if (dimensionalContainer && dimensionalContainer.current) {
            // @ts-ignore
            const obj = dimensionalContainer.current.querySelector('img');
            obj.style.filter = `brightness(${brightness})`;
            obj.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(${scale})`;
            if (breakpoint === 'mobile') {
                document.body.style.overflowY = 'hidden';
            }
        }
    }

    const onTouchMove = (ev: React.TouchEvent<HTMLDivElement>) => {
        if (props.dimensionalCard) {
            cardDimensional(ev, 'touch');
        }
    }

    const onMouseMove = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.dimensionalCard) {
            cardDimensional(ev, 'mouse');
        }
    }

    const onMouseLeave = () => {
        if (props.dimensionalCard) {
            if (dimensionalContainer && dimensionalContainer.current) {
                // @ts-ignore
                const obj = dimensionalContainer.current.querySelector('img');
                obj.style.filter = 'brightness(1)';
                obj.style.transform = 'rotateX(0deg) rotateY(0deg) scale(1)';
                if (breakpoint === 'mobile') {
                    document.body.style.overflowY = 'auto';
                }
            }
        }
    }

    const renderCaption = () => {
        return (
            caption && (
                <div className={styles['caption']}>
                    {caption}
                </div>
            )
        );
    }

    const renderMedia = () => {
        return (
            width && content.url
                ? content.type === MediaContentType.MP4
                    ? <div>
                        <video
                            autoPlay={props.autoplay !== undefined ? props.autoplay : !props.isTeaser}
                            controls={props.controls !== undefined ? props.controls : !props.isTeaser}
                            loop={!props.isTeaser}
                            muted={!props.isTeaser}
                            poster={props.posterUrl}
                            className={props.className ? props.className : ''}
                            src={content.url + '#t=0.01'}
                            ref={videoRef}
                            onEnded={resetVideo}
                        />
                        {props.isTeaser &&
                            <div className={styles['custom-play-button']} onClick={(e) => {
                                switchVideoControl(e);
                            }}>
                                {playing ? <ControlsPauseButton /> : <ControlsPlayButton />}
                            </div>
                        }</div>
                    : content.type === MediaContentType.YOUTUBE
                        ? <div className={styles['yt-wrap']}>
                            <div className={styles['yt-container']}>
                                <iframe src={props.autoplay ? content.url + '&autoplay=1' : content.url}
                                        title={props.alt}
                                        frameBorder="0"
                                        allow={props.autoplay ?
                                            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                            : 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                                        allowFullScreen
                                />
                            </div>
                        </div>
                        :  content.type === MediaContentType.MP3 ?
                            <figure className={styles['audio-wrap']}>
                                <audio
                                    controls
                                    controlsList="nodownload"
                                    autoPlay={Boolean(props.autoplay).valueOf()}
                                    src={content.url} />
                            </figure>
                            :
                            <div className={`
                                    ${props.className ? props.className : ''} 
                                    ${props.dimensionalCard ? styles['dimensional-card'] : ''}
                                `}
                                 style={{ paddingTop, position: 'relative' }}
                                 onMouseMove={(ev) => onMouseMove(ev)}
                                 onMouseLeave={() => onMouseLeave()}
                                 onTouchMove={(ev) => onTouchMove(ev)}
                                 onTouchEnd={() => onMouseLeave()}
                                 ref={dimensionalContainer}
                            >
                                <NextImage
                                    src={profilePictureError ? creatoki.src : content.url}
                                    style={props.dimensionalCard ? {perspective: '800px',} : {}}
                                    alt={props.alt}
                                    priority={props.initialLoad}
                                    layout={'fill'}
                                    onError={() => {
                                        if (props.isProfile) {
                                            setProfilePictureError(true);
                                        }
                                    }}
                                    sizes={`${imageSizes.find(value => value > width)}px`}
                                    objectFit={props.objectFit}
                                    onLoad={({ target }) => {
                                        if (!props.squared) {
                                            const { naturalWidth, naturalHeight } = target as HTMLImageElement;
                                            setPaddingTop(`calc(100% / (${naturalWidth} / ${naturalHeight})`);
                                        }
                                    }}
                                />
                            </div>
                : undefined
        )
    }

    const renderMediaComponent = () => (
        <div className={`
                ${styles['media-component']}
                ${props.dimensionalCard ? styles['dimensional'] : ''}
                ${props.hexagon ? styles['hexagon'] : ''}
                ${props.inverted ? styles['inverted'] : ''}
                ${content.type === MediaContentType.MP4 && props.squared ? styles['squared-video'] : ''}
                ${props.classNameWrapper ? props.classNameWrapper : ''}
            `}
             title={title ?? undefined}
             ref={observe}>

            {props.imageEffect && props.imageEffect.effect && props.imageEffect.withSubline ?
                <div className={`${styles['animate__animated']} ${styles['animate__'+props.imageEffect.effect]}`}>
                    {renderMedia()}
                    {renderCaption()}
                </div>
            : props.imageEffect && props.imageEffect.effect ? (
                    <>
                        <div className={`${styles['animate__animated']} ${styles['animate__'+props.imageEffect.effect]}`}>
                            {renderMedia()}
                        </div>
                        {renderCaption()}
                    </>
                ) : (
                    <>
                        {renderMedia()}
                        {renderCaption()}
                    </>
                )
            }
        </div>
    );

    const linkedImage = () => {
        if (props.redirectUrl) {
            return props.redirectUrl;
        } else if (props.enlargeOnClick) {
            return content.url;
        }
        return false;
    }
    const linkedImg = linkedImage();

    if (linkedImg && typeof linkedImg === 'string') {
        return <LinkBase target={props.enlargeOnClick ? '_blank' : ''} href={linkedImg} className={`${styles['clickable']} ${props.linkClassName}`}>
            {renderMediaComponent()}
        </LinkBase>;
    } else {
        return renderMediaComponent();
    }
};

export { getMediaType, Media };
export default Media;

Media.defaultProps = {
    objectFit: 'cover'
};