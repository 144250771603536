import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import { generateUrlToDetailedPage } from '../../../../utils/_helper/generate-url-to-details-page-helper';
import { UniversalTeaserProps } from '../../../../utils/_models/universal-teaser-props';
import { LinkBase } from '../../link/link-base/link-base';
import { Typography } from '../../typography/typography';
import CardTitle from '../card-title/card-title';
import styles from './card-detail.module.scss';

const CardDetail = (props: UniversalTeaserProps) => {
    return (
        <>
            <div className={`${styles['detail']} ${props.inverted ? styles['inverted'] : ''}`}>
                <div className={styles['head']}>
                    <CardTitle {...props}/>
                </div>
                <div className={styles['content-wrapper']}>
                    <div className={styles['content']}>
                        <Typography variant={TypographyVariantsEnum.BODY_SMALL} className={styles['description']}>
                            {props.description}
                        </Typography>
                    </div>
                </div>
            </div>
            {
                props.variantAttributes ?
                    <div className={`${styles['attributes']} ${props.inverted ? styles['inverted'] : ''}`}>
                        <table className={styles['table']}>
                            <tbody>
                            {
                                props.variantAttributes.map((attributes, i) => {
                                    if(i >= 4) return;

                                    return (
                                        <tr key={i} className={styles['row']}>
                                            <td className={styles['cell-attribute']}>
                                                <Typography variant={TypographyVariantsEnum.TABLE_TITLE} className={`${styles['cell']} ${props.inverted ? styles['inverted'] : ''}`}>
                                                    {attributes?.label}
                                                </Typography>
                                            </td>
                                            <td className={styles['cell-value']}>
                                                <Typography variant={TypographyVariantsEnum.TABLE_TITLE} className={`${styles['cell']} ${props.inverted ? styles['inverted'] : ''}`}>
                                                    {attributes?.value}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                : undefined
            }
            <div className={`${styles['link-wrapper']} ${props.inverted ? styles['inverted'] : ''}`}>
                {props.productName && props.title && props.productId && props.variantId
                    ? <LinkBase
                        href={generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId)}
                        className={styles['link']}
                    >
                        <Typography variant={TypographyVariantsEnum.BUTTON}>
                            {props.teaserMenuLabels?.more}
                        </Typography>
                        <FontAwesomeIcon className={styles['icon']} icon={['far', 'external-link']}/>
                    </LinkBase>
                    : undefined
                }
            </div>
        </>
    );
};

export default CardDetail;
