import React from 'react'

import styles from './modal-button.module.scss';

interface modalProps {
  children : React.ReactNode;
  onClick : () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalButton = ( props: modalProps) => {
  return (
      <div
      className={styles['hexagon-wrapper']}
        onClick={props.onClick}
      >
         <div className={styles['hexagon']}>
          <div className={styles['content']}>
              {props.children}
            </div>
        </div>
      </div>
    );
}

export default ModalButton