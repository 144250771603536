import * as React from 'react';
import {SVGProps} from 'react';

const UnlockablesSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14.57} height={19} {...props}>
        <path
            data-name="Vereinigungsmenge 31"
            d="M0 19V7.5h2.971l-.073-4.844L7.356 0l4.25 2.938v2.094H9.893V3.657l-2.5-1.594-2.785 1.406V7.5h9.966V19Zm1.617-1.625h11.336v-8.25H1.617Zm5.761-1.5H6.223l.22-2.219a1.637 1.637 0 1 1 2.44-1.406 1.684 1.684 0 0 1-.794 1.406l.326 2.219Z"
            fill="#426dfd"
        />
    </svg>
)

export default UnlockablesSVG;
