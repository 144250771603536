import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {useForm} from 'react-hook-form';
import {OptionalObjectSchema, TypeOfShape} from 'yup/lib/object';
import {RequiredStringSchema} from 'yup/lib/string';

import {ButtonTypesEnum} from '../../../../utils/_enums/button-types.enum';
import {ButtonVariantsEnum} from '../../../../utils/_enums/button-variants.enum';
import {Button} from '../../button/button';
import {ErrorMessage} from '../../error/error-message';
import styles from './submit-input.module.scss';

export interface SubmitInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    errorMessage?: string;
    fieldToolTip?: string;
    otherIcon?: IconProp;
    className?: string;
    submitPending?: boolean;
    inputClassName?: string;
    inverted?: boolean;
    submitForm: (arg0: any) => void;
    schema?: OptionalObjectSchema<{
        value: RequiredStringSchema<string | undefined,
            Record<string, any>>;
    },
        Record<string, any>,
        TypeOfShape<{
            value: RequiredStringSchema<string | undefined, any>;
        }>>;
}

export const SubmitInput = (props: SubmitInputProps): JSX.Element => {
    const {
        register,
        getValues,
        reset,
        handleSubmit,
        formState: {errors}
    } = useForm<any>({
        resolver: props?.schema ? yupResolver(props?.schema) : undefined
    });

    const onSubmit = () => {
        props.submitForm(getValues('value'));
        reset();
    };

    return (
        <form
            data-testid="submit-input"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={`${styles['input-field-wrapper']} ${
                props.className ? props.className : ''
            }`}
        >
            <div className={styles['submit-input']}>
                <input
                    {...register('value')}
                    placeholder={props.placeholder}
                    className={`
                    ${errors['value']?.message ? styles['error'] : ''}
                    ${props.inputClassName ? props.inputClassName : ''}
                    ${props.inverted ? styles['inverted'] : ''}
                    `}
                />
                <Button
                    type={ButtonTypesEnum.SUBMIT}
                    inverted={props.inverted}
                    className={`${styles['submit-button']} ${props.inverted ? styles['inverted'] : ''}`}
                    pending={props.submitPending}
                    icon={{
                        element: (
                            <FontAwesomeIcon
                                icon={props.otherIcon ?? ['far', 'long-arrow-alt-right']}
                            />
                        ),
                        align: 'left'
                    }}
                    variant={ButtonVariantsEnum.SECONDARY_FILLED}
                />
            </div>
            <ErrorMessage>
                {props.errorMessage ?? errors['value']?.message}
            </ErrorMessage>
        </form>
    );
};
