import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {HTMLAttributes} from 'react';

import {LinkVariants} from '../../../utils/_enums/link-variants.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {Typography} from '../typography/typography';
import styles from './link.module.scss';
import {LinkBase} from './link-base/link-base';

export interface Props
    extends React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement> {
    href: string;
    target?: string;
    children: string;
    icon?: React.ReactNode;
    external?: boolean;
    disabled?: boolean;
    className?: string;
    variant?: LinkVariants;
    download?: string | boolean;
    inverted?: boolean;
}

export const Link: React.FC<Props> = ({
                                          href,
                                          target,
                                          children,
                                          icon,
                                          external,
                                          disabled,
                                          className,
                                          variant = LinkVariants.READ_MORE,
                                          inverted,
                                          ...anchorProps
                                      }) => {
    return (
        <LinkBase
            className={`${styles.link} ${disabled ? styles['disabled'] : ''} ${
                className ?? ''
            } ${styles[variant] ?? ''}`}
            href={href}
            external={external}
            target={target}
            {...anchorProps}
        >
            {icon}
            <Typography
                inverted={inverted}
                variant={
                    variant === LinkVariants.READ_MORE
                        ? TypographyVariantsEnum.BUTTON
                        : variant === LinkVariants.SMALL_LINK
                            ? TypographyVariantsEnum.LINK_SMALL
                            : TypographyVariantsEnum.LINK
                }
                className={
                    className ?? ''
                }
            >
                {children}
            </Typography>
            {variant !== LinkVariants.STANDARD_LINK && variant !== LinkVariants.BUTTON_SECONDARY_OUTLINED ? (
                <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className={`${styles['link-icon']}
                    ${inverted ? styles['inverted'] : ''}`}/>
            ) : null}
        </LinkBase>
    );
};

Link.defaultProps = {external: false};
