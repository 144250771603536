import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { Button } from '../../components/_shared/button/button';
import { LinkBase } from '../../components/_shared/link/link-base/link-base';
import { ButtonTypesEnum } from '../../utils/_enums/button-types.enum';
import { ButtonVariantsEnum } from '../../utils/_enums/button-variants.enum';
import { isObject } from '../../utils/_helper/equal-object-helper';
import { TypoContentHelper } from '../../utils/_helper/typo-content.helper';
import { Typo, TypoLocalizations } from '../models/typo';
import { TypoElementMenu_element } from '../models/typo/typo-element-menu_element';
import {TypoElementNews} from '../models/typo/typo-element-news';
import { TypoElementShortcut } from '../models/typo/typo-element-shortcut';
import { TypoContent } from '../models/typo-content';
import { TypoContentElement } from '../models/typo-content-element';
import { TypoCreatokia } from '../models/typo-creatokia';
import { TypoFooter } from '../models/typo-footer';
import { TypoHeader } from '../models/typo-header';
import { footerMenu, footerMenuEntry, footerMenuHeadline, navMenuEntry, TypoMenu } from '../models/typo-menu';

/**
 * Typo3 service
 */
class TypoService {

    /**
     * Complete typo3 json response
     *
     * @private
     */
    private readonly json: Typo;

    /**
     * @private
     */
    private typoContentHelper: TypoContentHelper;

    /**
     * @param typoData  useTypoContent() Hook
     */
    constructor(typoData: Typo | number | undefined) {
        if (!typoData || typeof typoData == 'number') {
            typoData = {} as Typo;
        }
        this.json = typoData;
        this.json = this.convertPlaceholderText(this.json);
        this.typoContentHelper = new TypoContentHelper(this.json);
    }

    /**
     * Get Json
     */
    public getJson(): Typo | number | undefined {
        return this.json;
    }

    /**
     * Get all content output from typo3 including header, footer and page content
     */
    public getTypoContent(): TypoContent | [] {
        const json = this.json;
        return json && json.content ? json.content : [];
    }

    /**
     * Get header content
     */
    public getHeaderContent(): TypoHeader | never[] {
        const json = this.json;
        const defaultHeader = json && json.creatokia && json.creatokia.defaultNav &&
        json.creatokia.defaultNav.header ? [{
            type: 'shortcut',
            content: { shortcut: json.creatokia.defaultNav.header }
        }] as unknown as TypoHeader : [];

        return json && json.content && json.content.colPos10 ?
            json.content.colPos10 as TypoHeader : defaultHeader;
    }

    /**
     * Get page content
     */
    public getPageContent(): Record<string, TypoContentElement> | [] {
        const json = this.json;
        return json && json.content && json.content.colPos20 ?
            json.content.colPos20 as Record<string, TypoContentElement> : [];
    }

    /**
     * Get footer content
     */
    public getFooterContent(): TypoFooter | never[] {
        const json = this.json;
        const defaultFooter = json && json.creatokia && json.creatokia.defaultNav &&
        json.creatokia.defaultNav.footer ? [{
            type: 'shortcut',
            content: { shortcut: json.creatokia.defaultNav.footer }
        }] as unknown as TypoFooter : [];

        return json && json.content && json.content.colPos30 ?
            json.content.colPos30 as TypoFooter : defaultFooter;
    }

    /**
     * Get the page news content element
     */
    public getNewsContent(): TypoElementNews | undefined {
        return this.typoContentHelper.getNewsContent();
    }

    /**
     * Get the page response
     */
    public getPageResponse(): string | undefined {
        return this.json.response?.join(', ');
    }

    /**
     * Get all languages
     */
    public getLocalizations(): TypoLocalizations[] | boolean {
        const json = this.json;
        return json && json.localizations ? json.localizations as TypoLocalizations[] : false;
    }

    /**
     * Get only the current active language
     */
    public getCurrentLocalization(): TypoLocalizations | void {
        const localisations = this.getLocalizations();
        if (localisations && typeof localisations !== 'boolean') {
            localisations.map((local) => {
                if (local.active) {
                    return local;
                }
            });
        }
    }

    /**
     * Get page settings
     */
    public getCreatokiaContent(): TypoCreatokia | boolean {
        return this.json && this.json.creatokia ? this.json.creatokia as TypoCreatokia : false;
    }

    /**
     * Creates react components from typo3 content elements
     *
     * @param data
     */
    public createContentElement(data: TypoContentElement): JSX.Element | void {
        return this.typoContentHelper.createContentElement(data, false, this.json.id);
    }

    /**
     * Creates Full-Width stage element when it's the first element and removes it from json
     *
     * @param data
     */
    public createStageElement(data: TypoContentElement): JSX.Element | void {
        return this.typoContentHelper.createContentElement(data, true, this.json.id);
    }

    /**
     * Get header nav links
     */
    public getHeaderNavLinks(): navMenuEntry[] | [] {

        const navElementId = 1;

        const typoNavObj = this.getHeaderContent();
        if (!typoNavObj || !isObject(typoNavObj)) {
            return [];
        }

        let typoNav: TypoContentElement = {};
        typoNavObj.map((data: TypoContentElement) => {

            if (data.type !== 'shortcut' || data.content === undefined) {
                return;
            }

            data = data as TypoElementShortcut;
            data.content = data.content as TypoElementShortcut;
            data.content.shortcut = data.content.shortcut as TypoElementMenu_element;

            if (data.content.shortcut === undefined ||
                data.content.shortcut[0] === undefined) {
                return;
            }

            const navObj = data.content.shortcut[0];
            if (navObj.id == navElementId) {
                typoNav = navObj;
                return;
            }
        });

        if (!typoNav || typoNav.content === undefined) {
            return [];
        }

        const mainNavObject = typoNav.content;
        if (!mainNavObject) {
            return [];
        }

        const mainNavMenu: navMenuEntry[] = [];
        mainNavObject.menu.map((data: TypoMenu, idx: string) => {
            mainNavMenu.push({
                external: !!data.target,
                id: idx,
                label: data.title,
                link: data.link,
                active: data.active
            });
        });

        return mainNavMenu;
    }

    /**
     * Generates footer nav links
     */
    public getFooterNavLinks(): footerMenu[] | [] {

        const aboutNavId = 2;
        const supportNavId = 3;
        const legalNavId = 4;

        const typoNavObj = this.getFooterContent();
        if (!typoNavObj || !isObject(typoNavObj)) {
            return [];
        }

        const typoNavs: TypoContentElement[] = [];
        typoNavObj.map((data: TypoContentElement) => {
            if (data.type !== 'shortcut' ||
                data.content === undefined ||
                data.content.shortcut === undefined) {
                return;
            }

            data.content.shortcut.map((menuData: TypoContentElement) => {
                if (menuData.id == aboutNavId ||
                    menuData.id == supportNavId ||
                    menuData.id == legalNavId) {
                    typoNavs.push(menuData);
                }
            });
        });

        const mainFooterMenu: footerMenu[] = [];
        typoNavs.map((data: TypoContentElement, i) => {
            const links: footerMenuEntry[] = []
            const headline: footerMenuHeadline = {
                Style: null,
                cssClass: null,
                element: null,
                headlineContent: data.content.header,
                id: i,
                identifier: null,
                style: null,
                textAlign: null
            }

            data.content.menu.map((data: TypoMenu, idx: string) => {
                links.push({
                    external: !!data.target,
                    id: idx,
                    linkText: data.title,
                    url: data.link,
                    active: data.active
                });
            });
            mainFooterMenu.push({ headline: headline, id: i, links: links })
        });

        return mainFooterMenu;
    }

    /**
     * Get specific "label" by parent category or first by parent and then find a child category
     *
     * @param category
     * @param subCategory
     */
    public getLabel(category: string, subCategory = ''): string {
        const data = this.getCreatokiaContent();
        if (!data || typeof data === 'boolean') {
            return '';
        }

        const categoryItem = data.labels.find(item => {
            return item.slug === category
        })

        if (categoryItem) {
            if (!subCategory) {
                return categoryItem.title;
            } else {
                const subCategoryItem = categoryItem.subcategories.find(item => {
                    return item.slug === subCategory
                })

                if (subCategoryItem) {
                    return subCategoryItem.title;
                }
            }
        }

        return '';
    }

    public getAllowedDimensionalCardProductOwners(): string[] {
        const allowedProductOwners = this.getLabel('system', 'product-owner-dimensional-card');
        if (!allowedProductOwners) {
            return [];
        }
        return allowedProductOwners.trim().split('|').map((str) => {
            return str.trim();
        });
    }

    /**
     * Converts special patterns like button json placed from the RTE Editor in Typo3
     *
     * @param json
     * @private
     */
    private convertPlaceholderText(json: Typo): Typo {
        const currentLocal = this.getCurrentLocalization();
        const langSlug = currentLocal && isObject(currentLocal) ? currentLocal.twoLetterIsoCode : 'en';

        for (const key in json) {
            if (typeof json[key] == 'object' && json[key] !== null) {
                this.convertPlaceholderText(json[key]);
            } else if (json[key] && typeof json[key] == 'string') {

                // Spacing in RTE
                if (key === 'bodytext' || key === 'answer') {
                    json[key] = json[key].split('\n\n').join('<p>&nbsp;</p>');
                }

                // RTE button
                let regex = /<pre class="rtebutton(.*)">[\s\\r\\n]*<code>[\s\\r\\n]*(.*)[\s\\r\\n]*<\/code>[\s\\r\\n]*<\/pre>/gm;
                let matches = json[key].matchAll(regex);
                if (matches) {
                    for (const match of matches) {
                        let classes = 'rtebutton-base' + match[1].replace('rtebutton', '');
                        const jsonButton = JSON.parse(match[2]);
                        const variant = (jsonButton.inputVariant !== '' ? jsonButton.inputVariant : 'primary') as ButtonVariantsEnum;

                        // Uses Page-Styling when button has no set styling
                        if (this.json.creatokia !== undefined) {
                            jsonButton.inputStyle = this.json.creatokia.style;
                        }

                        const buttonData = {
                            variant: variant,
                            className: `rtebutton ${variant}`,
                            disabled: jsonButton.inputDisabled,
                            type: 'button' as ButtonTypesEnum,
                            inverted: jsonButton.inputStyle === 'inverted',
                            buttonUrl: jsonButton.inputUri,
                            external: jsonButton.inputTarget === '_blank',
                            pending: jsonButton.inputPending
                        };

                        jsonButton.inputButtonAlignment !== '' ? classes += ' text-' + jsonButton.inputButtonAlignment : 0;

                        if (buttonData.buttonUrl && !buttonData.disabled) {
                            json[key] = json[key].replace(match[0], ReactDOMServer.renderToString(
                                    <div className={classes}>
                                        <LinkBase
                                            key={jsonButton.inputTitle as string}
                                            href={buttonData.buttonUrl}
                                            external={buttonData.external}
                                        >
                                            <Button
                                                {...buttonData}
                                            >
                                                {jsonButton.inputTitle}
                                            </Button>
                                        </LinkBase>
                                    </div>
                                )
                            );
                        } else {
                            json[key] = json[key].replace(match[0], ReactDOMServer.renderToString(
                                    <div className={classes}>
                                        <Button
                                            {...buttonData}
                                        >
                                            {jsonButton.inputTitle}
                                        </Button>
                                    </div>
                                )
                            );
                        }
                    }
                }

                // Adds language slug to urls on a tags
                regex = /href="(\/page\/.*?)"/gm;
                matches = json[key].matchAll(regex);
                if (matches) {
                    for (const match of matches) {
                        const newUrl = match[1].replace('/page/', '/' + langSlug + '/page/');
                        json[key] = json[key].replace(match[0], 'href="' + newUrl + '"');
                    }
                }

                // Adds langauge slug to page urls from json
                const allowedKeys = ['href', 'headerLink', 'link'];
                if (allowedKeys.includes(key)) {
                    regex = /^(\/page\/.*?)$/gm;
                    matches = json[key].matchAll(regex);
                    if (matches) {
                        for (const match of matches) {
                            const newUrl = match[1].replace('/page/', '/' + langSlug + '/page/');
                            json[key] = json[key].replace(match[0], newUrl);
                        }
                    }
                }
            }
        }

        return json;
    }
}

export { TypoService };