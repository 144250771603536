import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ErrorResponse} from '../../api/models/error-response';
import {CommentFormData} from '../../api/models/typo/form-data/comment-form-data';
import {CommentRemoveData} from '../../api/models/typo/form-data/comment-remove-data';
import CommentService from '../../api/services/comment.service';
import {ReduxStateModel} from '../../utils/_models/redux-state-model';
import {reduxThunkWrapper} from '../_helper/redux-thunk-wrapper';

interface CommentState {
    commentModel: ReduxStateModel;
}

const initialState: CommentState = {
    commentModel: {
        pending: false
    }
};

export const sendCommentForm = createAsyncThunk(
    'api/comment/add',
    async (data: CommentFormData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const commentService = new CommentService();
            const response = await commentService.sendCommentForm(data);
            return response.data;
        }, rejectWithValue);
    }
);

export const removeComment = createAsyncThunk(
    'api/comment/remove',
    async (data: CommentRemoveData, {rejectWithValue}) => {
        return await reduxThunkWrapper(async () => {
            const commentService = new CommentService();
            const response = await commentService.sendRemoveComment(data);
            return response.data;
        }, rejectWithValue);
    }
);

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        removeCommentStatus: (state) => {
            state.commentModel.status = undefined;
        },
    },
    extraReducers: {
        [sendCommentForm.pending.type]: (state) => {
            state.commentModel.pending = true;
            state.commentModel.status = undefined;
        },
        [sendCommentForm.fulfilled.type]: (state) => {
            state.commentModel.pending = false;
            state.commentModel.status = 200;
        },
        [sendCommentForm.rejected.type]: (state, action: PayloadAction<ErrorResponse>) => {
            state.commentModel.status = action.payload.status;
            state.commentModel.pending = false;
        }
    }
});

export const {
    removeCommentStatus
} = commentSlice.actions;
export default commentSlice.reducer;
