import React from 'react';

import { TypoElementMultiItem } from '../../../../../api/models/typo/typo-element-multi';
import { TypoMedia } from '../../../../../api/models/typo-media';
import { StrapiHeadlineStyles } from '../../../../../utils/_enums/strapi-headline-styles.enum';
import { TypographyVariantsEnum } from '../../../../../utils/_enums/typeography-variants.enum';
import { mapStrapiHeadline } from '../../../../../utils/_helper/strapi-mapper';
import Media, { getMediaType } from '../../../../_shared/media/media';
import { Typography } from '../../../../_shared/typography/typography';
import { TypoButtons } from '../../../_shared/typo_buttons/typo_buttons';
import styles from './product-summary.module.scss';

const ProductSummary = (props: TypoElementMultiItem): JSX.Element => {

    const {
        inverted, topline, headline, subline, tag, tagStyle,
        headlineAlign, bodytext, bordered, buttons, media
    } = props;
    const callToMedia = media ? media[0] : {} as TypoMedia;
    const formattedBodytext = bodytext.split(/(<[a-zA-Z0-9]+>\[MEDIA\]<\/[a-zA-Z0-9]+>)/);

    return (
        <div className={`
                ${styles['product-summary']}
                ${bordered ? styles['bordered'] : ''}
                ${inverted ? styles['inverted'] : ''}
            `}>

            <div className={`${headlineAlign ? styles['text-' + headlineAlign] : ''}`}>
                {topline && (
                    <Typography
                        className={styles['topline']}
                        variant={TypographyVariantsEnum.HEADING5_LIGHT}
                        inverted={inverted}
                    >
                        {topline}
                    </Typography>
                )}

                <Typography
                    className={styles['headline']}
                    variant={
                        tagStyle
                            ? mapStrapiHeadline(tagStyle.toUpperCase() as StrapiHeadlineStyles)
                            : TypographyVariantsEnum.HEADING3_BOLD
                    }
                    inverted={inverted}
                    element={tag ? tag : 'h3'}
                >
                    {headline}
                </Typography>

                {subline && (
                    <Typography
                        className={styles['subline']}
                        variant={TypographyVariantsEnum.HEADING5_LIGHT}
                        inverted={inverted}
                    >
                        {subline}
                    </Typography>
                )}
            </div>

            {formattedBodytext ? (
                <div className="bodytext-container">
                    {formattedBodytext.map((text, i) => {
                        if (text.includes('[MEDIA]')) {
                            return (
                                <div key={i}>
                                    <Media
                                        className={styles['media']}
                                        isTeaser={true}
                                        contentType={getMediaType(callToMedia?.properties?.mimeType)}
                                        autoplay={callToMedia?.properties?.autoplay as unknown as boolean}
                                        src={callToMedia?.publicUrl}
                                        caption={callToMedia?.properties?.description as string}
                                        title={callToMedia?.properties?.title as string}
                                        alt={callToMedia?.properties?.alternative as string}
                                        redirectUrl={callToMedia?.properties?.link as string}
                                        linkClassName={callToMedia?.properties?.linkData?.class}
                                        inverted={inverted} />
                                </div>
                            );
                        } else if (text && text.trim() != '') {
                            return (<div key={i} className="bodytext-container bodytext-padding" dangerouslySetInnerHTML={{ __html: text }}></div>);
                        }
                    })}
                </div>
            ) : (
                <>
                    {bodytext && (
                        <div className={`${styles['bodytext']} bodytext-container`}
                             dangerouslySetInnerHTML={{ __html: bodytext }}></div>
                    )}
                </>
            )}
            <TypoButtons inverted={inverted} buttons={buttons} />
        </div>
    );
}

export { ProductSummary };