import React, { CSSProperties } from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';

import variables from '../../../themes/variables.module.scss';
import { ButtonTypesEnum } from '../../../utils/_enums/button-types.enum';
import { ButtonVariantsEnum } from '../../../utils/_enums/button-variants.enum';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../typography/typography';
import styles from './button.module.scss';

export interface ButtonProps {
    variant?: ButtonVariantsEnum;
    className?: string;
    children?: React.ReactNode | string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    title?: string;
    type?: ButtonTypesEnum;
    icon?: {
        element: React.ReactNode;
        align: 'left' | 'right';
        className?: string;
        flip?: boolean;
    };
    inverted?: boolean;
    style?: CSSProperties;
    buttonUrl?: string;
    external?: boolean;
    pending?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {

    const renderText = (): JSX.Element | null => {
        if (props.children) {
            return (
                <Typography
                    className={props.pending ? '' : styles['not-pending']}
                    variant={TypographyVariantsEnum.BUTTON}
                >
                    {props.children}
                </Typography>
            );
        } else {
            return null;
        }
    };

    switch (props.variant) {
        case ButtonVariantsEnum.PRIMARY: {
            return (
                <button
                    data-testid={'button'}
                    style={props?.style}
                    disabled={props.disabled || props.pending}
                    onClick={props?.onClick}
                    className={`
                    ${styles[props.variant as string]}
                    ${props.inverted ? styles.inverted : ''}
                    ${props.className ?? ''}
                    `}
                >
                    {
                        <div>
                            {renderText()}
                            {props.pending
                                ? <div className={props.icon ? '' : styles['loading-spinner']}>
                                    <ImpulseSpinner
                                        size={18}
                                        frontColor={variables.primary_accent}
                                        backColor={variables.greys_light} />
                                </div>
                                : <div
                                    className={props.children && props.icon?.element
                                        ? 'withText'
                                        : undefined}
                                >
                                    {props.icon?.element}
                                </div>}
                        </div>
                    }
                    <div className={styles.shadow} />
                </button>
            );
        }
        case ButtonVariantsEnum.SELECTED: {
            return (
                <button
                    data-testid={'button'}
                    style={props?.style}
                    disabled={props.disabled || props.pending}
                    onClick={props?.onClick}
                    className={`${styles[props.variant as string]} ${
                        props.inverted ? styles.inverted : ''
                    } ${props.className ?? ''}`}
                >
                    <div>
                        {renderText()}
                        <div
                            className={
                                props.children && props.icon?.element
                                    ? 'withText'
                                    : undefined
                            }
                        >
                            {props.icon?.element}
                        </div>
                    </div>
                    <div className={styles.shadow} />
                </button>
            );
        }
        case ButtonVariantsEnum.LEFT_ARROW: {
            return (
                <button
                    data-testid={'button'}
                    style={props?.style}
                    disabled={props.disabled}
                    onClick={props?.onClick}
                    className={`${styles[props.variant as string]} ${
                        props.inverted ? styles.inverted : ''
                    } ${props.className ?? ''}`}
                >
                    <div>
                        {props.icon?.align === 'left' && (
                            <div
                                className={
                                    props.children && props.icon
                                        ? 'withText left'
                                        : undefined
                                }
                            >
                                {props.icon?.element}
                            </div>
                        )}

                        {renderText()}

                        {props.icon?.align === 'right' && (
                            <div
                                className={
                                    props.children && props.icon?.element
                                        ? 'withText right'
                                        : undefined
                                }
                            >
                                {props.icon?.element}
                            </div>
                        )}
                    </div>
                </button>
            );
        }
        case ButtonVariantsEnum.STAGE: {
            return (
                <div
                    style={{ position: 'relative', ...props?.style }}
                    className={props.className}
                >
                    <button
                        data-testid={'button'}
                        disabled={props.disabled}
                        onClick={props?.onClick}
                        className={`${styles.button} ${
                            styles['secondary-filled']
                        }  ${styles[props.variant as string]} ${
                            props.inverted ? styles.inverted : ''
                        } `}
                    >
                        {props.icon?.align === 'left' ? (
                            <div
                                className={
                                    props.children && props.icon
                                        ? 'withText left'
                                        : undefined
                                }
                            >
                                {props.icon?.element}
                            </div>
                        ) : undefined}
                        {renderText()}
                        {props.icon?.align === 'right' ? (
                            <div
                                className={
                                    props.children && props.icon?.element
                                        ? 'withText right'
                                        : undefined
                                }
                            >
                                {props.icon?.element}
                            </div>
                        ) : undefined}
                    </button>
                    <div className={styles.shadow} />
                </div>
            );
        }
        default: {
            return (
                <button
                    data-testid={'button'}
                    style={props?.style}
                    disabled={props.disabled || props.pending}
                    onClick={props?.onClick}
                    className={`${styles.button}  ${
                        styles[props.variant as string]
                    } ${props.inverted ? styles.inverted : ''} ${
                        props.className ?? ''
                    }`}
                >
                    {props.icon ? undefined : renderText()}
                    {
                        props.pending
                            ? <div className={props.icon ? '' : styles['loading-spinner']}>
                                <ImpulseSpinner
                                    size={18}
                                    frontColor={variables.primary_accent}
                                    backColor={variables.greys_light} />
                            </div>
                            : <>
                                {props.icon?.align === 'left'
                                    ? <div className={` 
                                        ${props.children && props.icon
                                        ? 'withText left'
                                        : undefined}
                                        ${props.icon?.className ? styles[props.icon?.className] : ''}
                                    `}>
                                        {props.icon?.element}
                                    </div>
                                    : undefined}
                                {props.icon ? renderText() : undefined}
                                {props.icon?.align === 'right'
                                    ? <div
                                        className={`
                                            ${props.children && props.icon?.element
                                            ? 'withText right'
                                            : undefined}
                                            ${props.icon?.className ? styles[props.icon?.className] : ''}
                                        `}>
                                        {props.icon?.element}
                                    </div>
                                    : undefined}
                            </>
                    }
                </button>
            );
        }
    }
};

Button.defaultProps = {
    variant: ButtonVariantsEnum.PRIMARY,
    type: ButtonTypesEnum.SUBMIT
};
