import React, {useState} from 'react';

import {generateUrlToDetailedPage} from '../../../../utils/_helper/generate-url-to-details-page-helper';
import {UniversalTeaserBuyProps} from '../../../../utils/_models/universal-teaser-props';
import Media from '../../media/media';
import CardFooter from '../card-footer/card-footer';
import CardHeader from '../card-header/card-header';
import CardOverlay from '../card-overlay/card-overlay';
import styles from './card-front.module.scss';

interface CardFrontProps extends UniversalTeaserBuyProps {
    onSwap: () => void;
}

const CardFront = (props: CardFrontProps) => {

    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <div className={`${styles['front']} ${props.inverted ? styles['inverted'] : ''}`}>
            <div className={styles['content']}>
                <CardHeader
                    {...props}
                    onToggleOverlay={() => setShowOverlay(prevState => !prevState)}
                    isOverlayActive={showOverlay}
                />
                {
                    showOverlay
                        ? <div className={styles['overlay']}>
                            <CardOverlay {...props}/>
                        </div>
                        : undefined
                }
                <div className={styles['media']}>
                    <Media
                        src={props.mediaUrl}
                        contentType={props.mediaContentType}
                        redirectUrl={props.productName && props.title && props.productId && props.variantId
                            ? generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId)
                            : undefined}
                        objectFit={'contain'}
                        squared={true}
                        isTeaser={true}
                    />
                </div>
            </div>
            <CardFooter {...props} isFront={true} onSwap={props.onSwap} onBuy={props.onBuy}/>
            <div className={`${styles['teaser-border']} ${props.inverted ? styles['inverted'] : ''}`}></div>
        </div>
    );
};

export default CardFront;
