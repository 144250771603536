import {BasePageData} from '../models/strapi/base-page-data';
import { StrapiButtonProps } from '../models/strapi/strapi-button-props';
import {StrapiStage} from '../models/strapi/strapi-stage';
import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useAccountBasePage = useLanguageDependentDataSSr<UseAccountBasePageData>(
    'my-account'
);

export interface UseAccountBasePageData extends BasePageData{
    profileButton: StrapiButtonProps;
    notificationsButton: StrapiButtonProps;
    portfolioAndGalleryButton: StrapiButtonProps;
    manageWalletButton: StrapiButtonProps;
    changePasswordButton: StrapiButtonProps;
    invoiceAddressButton: StrapiButtonProps;
    supportAndContactButton: StrapiButtonProps;
    logoutButton: StrapiButtonProps;
    stage: StrapiStage;
}
