import React from 'react';

import {TypoElementMulti} from '../../../api/models/typo/typo-element-multi';
import {MediaContentType} from '../../../utils/_enums/image-content-type.enum';
import {Media} from '../../_shared/media/media';
import TeaserCard from '../../_shared/teaser-card/teaser-card';
import {Headline} from '../_shared/headline/headline';
import styles from './multi.module.scss';
import {ProductSummary} from './templates/product-summary/product-summary';

const TypoMulti = (props: TypoElementMulti): JSX.Element => {

    const { id, inverted, elementsPerRow, rowsMobile, rowsTablet, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    const row = (cols: number) => {
        if (cols < 1) {
            cols = 1;
        }
        return cols;
    }

    return (
        <div id={`c${id}`} className={`
            ${'spaceBefore' + appearance.spaceBefore} 
            ${'spaceAfter' + appearance.spaceAfter} 
            ${inverted ? styles['inverted'] : ''}
            ${fullwidth ? styles['fullwidth'] : ''}
            ${wrapper !== undefined ? styles[wrapper] : ''}
        `}>
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className={styles.multi}>
                {
                    props.elements?.map((element, i) => {
                        switch (element.type) {
                            case 'product-summary':
                                return (
                                    <div key={'multi-item-product-summary-' + element.uid}
                                         className={`
                                            ${styles['multi-item-container']} 
                                            ${styles['multi-item-row-' + row(elementsPerRow)]}
                                            ${styles['mobile-multi-item-row-' + row(rowsMobile)]}
                                            ${styles['tablet-multi-item-row-' + row(rowsTablet)]}
                                         `}>
                                        <ProductSummary {...element}
                                                        inverted={inverted} />
                                    </div>
                                );
                            case 'creatoken':
                                return (
                                    <div key={'multi-item-creatoken-' + element.uid}
                                         className={`
                                            ${styles['multi-item-container']} 
                                            ${styles['multi-item-row-' + row(elementsPerRow)]}
                                            ${styles['mobile-multi-item-row-' + row(rowsMobile)]}
                                            ${styles['tablet-multi-item-row-' + row(rowsTablet)]}
                                         `}>
                                        <Media
                                            key={`multi-media-${i}`}
                                            mediaId={i}
                                            inverted={inverted}
                                            src={element.media.length > 0 ? element.media[0].publicUrl : undefined}
                                            contentType={element.media.length > 0 ? element.media[0].properties.mimeType as MediaContentType : undefined}
                                            alt={element.media.length > 0 ? element.media[0].properties.alternative as string : undefined}
                                            caption={element.media.length > 0 ? element.media[0].properties.description as string : undefined}
                                            title={element.media.length > 0 ? element.media[0].properties.title as string : undefined}
                                            redirectUrl={element.media.length > 0 ? element.media[0].properties.linkData?.href : undefined}
                                            linkClassName={element.media.length > 0 ? element.media[0].properties.linkData?.class : undefined}
                                        />
                                    </div>
                                );
                            case 'teaser':
                                return (
                                    <div key={'multi-item-card-' + element.uid}
                                         className={`
                                            ${styles['multi-item-container']} 
                                            ${styles['multi-item-row-' + row(elementsPerRow)]}
                                            ${styles['mobile-multi-item-row-' + row(rowsMobile)]}
                                            ${styles['tablet-multi-item-row-' + row(rowsTablet)]}
                                         `}>
                                        <TeaserCard
                                            key={`multi-card-${i}`}
                                            variantId={element.variantId}
                                            variantProductId={element.variantProductId}
                                            instanceId={element.instanceId}
                                            instanceProductId={element.instanceProductId}
                                        />
                                    </div>
                                );
                        }
                    })
                }

                {/*--Fill empty spaces in flexbox--*/}
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
                <div
                    className={`${styles['multi-item-container']} ${styles['multi-item-placeholder']} ${elementsPerRow ? styles['multi-item-row-' + elementsPerRow] : ''}`}>&nbsp;</div>
            </div>
        </div>
    );
};

export { TypoMulti };