import React, { useEffect, useState } from 'react';

import { useAccountBasePage } from '../../../../api/hooks/useAccountBasePage';
import { useAdminHeader } from '../../../../api/hooks/useAdminHeader.hook';
import { useHeader } from '../../../../api/hooks/useHeader.hook';
import { useLoginPage } from '../../../../api/hooks/useLoginPage.hook';
import { useTypoContent } from '../../../../api/hooks/useTypoContent.hook';
import { TypoService } from '../../../../api/services/typo.service';
import { MenuIcon } from '../../../../assets/icons/menu-icon.svg';
import { UserIcon } from '../../../../assets/icons/user-icon.svg';
import { RootState, useAppSelector } from '../../../../redux';
import { ButtonVariantsEnum } from '../../../../utils/_enums/button-variants.enum';
import {
    DefaultHeaderMobileLoginFormContextEnum
} from '../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import { RoutePath } from '../../../../utils/_enums/route-path.enum';
import useBreakpoints from '../../../../utils/_hooks/useBreakpoints.hook';
import { useDefaultHeaderMobileLoginContext } from '../../../../utils/_hooks/useDefaultHeaderMobileLoginContext.hook';
import { LanguageSelectorOptions } from '../../../../utils/_models/languageSelectorOptions';
import { ButtonProps } from '../../button/button';
import { Header } from '../header';
import { HeaderBackground } from '../header-background/header-background';
import styles from './default-header.module.scss';
import { DefaultHeaderMobileLoginForm } from './default-header-mobile-login-form/default-header-mobile-login-form';
import {
    DefaultHeaderMobileLoginFormContextProvider
} from './default-header-mobile-login-form/default-header-mobile-login-form-context/default-header-mobile-login-form-context';
import {
    DefaultHeaderMobileMyAccountNavigation
} from './default-header-mobile-my-account-navigation/default-header-mobile-my-account-navigation';
import { DefaultHeaderMobileNavigation } from './default-header-mobile-navigation/default-header-mobile-navigation';

interface Props {
    isAdmin?: boolean;
    availableLanguages?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    pageType?: string;
    typoService?: TypoService
}

const DefaultHeaderComponent: React.FC<Props> = ({
                                                     isAdmin,
                                                     availableLanguages,
                                                     forStaticPage,
                                                     pageType,
                                                     typoService
                                                 }) => {

    const defaultHeaderData = useHeader();

    // Typo Nav Overwrite
    if (!typoService) {
        typoService = new TypoService(useTypoContent());
    }
    defaultHeaderData.navLinks = typoService.getHeaderNavLinks();

    const adminHeaderData = useAdminHeader();
    const { dispatch } = useDefaultHeaderMobileLoginContext();
    const value = useBreakpoints();
    const [buttonList, setButtonList] = useState<ButtonProps[]>([]);
    const username = useAppSelector((state: RootState) => state.userSlice.user?.username);
    const userIcon = useAppSelector((state: RootState) => state.userSlice.user?.profileImageUrl);

    useEffect(() => {
        if (value === 'desktop' || value === 'desktopSmall') {
            setButtonList([
                {
                    variant: ButtonVariantsEnum.SECONDARY_OUTLINED,
                    icon: {
                        element: (
                            userIcon ? <img src={userIcon} className={styles['header-profile-icon']} /> : <UserIcon />
                        ),
                        className: userIcon ? 'small-p' : '',
                        align: 'right',
                    },
                    children: username ?? 'Login',
                    onClick: () => {
                        if (username)
                            dispatch({
                                type:
                                DefaultHeaderMobileLoginFormContextEnum.TOGGLEMYACCOUNTNAVIGATION
                            });
                        else
                            dispatch({
                                type:
                                DefaultHeaderMobileLoginFormContextEnum.TOGGLELOGINFORM
                            });
                    }
                }
            ]);
            dispatch({ type: DefaultHeaderMobileLoginFormContextEnum.RESET });
        } else {
            setButtonList([
                {
                    variant:
                    ButtonVariantsEnum.SECONDARY_OUTLINED,
                    icon: {
                        element: (
                            <MenuIcon />
                        ),
                        align: 'right'
                    },

                    onClick: () => {
                        dispatch({
                            type:
                            DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION
                        });
                    }
                },
                {
                    variant:
                    ButtonVariantsEnum.SECONDARY_OUTLINED,
                    icon: {
                        element: (
                            userIcon ? <img src={userIcon} className={styles['header-profile-mobile-icon']} /> :
                                <UserIcon />
                        ),
                        className: userIcon ? 'icon-container' : '',
                        align: 'right'
                    },
                    onClick: () => {
                        if (username)
                            dispatch({
                                type:
                                DefaultHeaderMobileLoginFormContextEnum.TOGGLEMYACCOUNTNAVIGATION
                            });
                        else
                            dispatch({
                                type:
                                DefaultHeaderMobileLoginFormContextEnum.TOGGLELOGINFORM
                            });
                    }
                }
            ]);
        }
    }, [value, username]);

    const renderNavLinks = () => {
        if (isAdmin) {
            return (
                adminHeaderData?.navLinks?.map(({ label, link, external }) => ({
                    label,
                    value: link as RoutePath,
                    external
                })) ?? []
            );
        } else {
            return (
                defaultHeaderData?.navLinks?.map(({ label, link, external }) => ({
                    label,
                    value: link as RoutePath,
                    external
                })) ?? []
            );
        }
    };

    return (
        <header className={styles['default-header-container']}>
            <div>
                <HeaderBackground />
                <Header
                    languages={availableLanguages}
                    element="div"
                    forStaticPage={forStaticPage}
                    inverted={true}
                    loggedIn={Boolean(username)}
                    navLinks={renderNavLinks()}
                    pageType={pageType}
                    buttons={buttonList}
                />
                <DefaultHeaderMobileLoginForm />
                <DefaultHeaderMobileNavigation
                    localization={availableLanguages}
                    forStaticPage={forStaticPage}
                    pageType={pageType}
                />
                <DefaultHeaderMobileMyAccountNavigation
                    localization={availableLanguages}
                    pageType={pageType}
                />
            </div>
        </header>
    );
};

const WithContext: React.FC<Props> & { dataHooks: any } = (props) => {
    return (
        <DefaultHeaderMobileLoginFormContextProvider>
            <DefaultHeaderComponent {...props} />
        </DefaultHeaderMobileLoginFormContextProvider>
    );
};

export const DefaultHeader = WithContext;

WithContext.dataHooks = [
    useTypoContent,
    useHeader,
    useAdminHeader,
    useLoginPage,
    useAccountBasePage
];

WithContext.defaultProps = { isAdmin: false };
