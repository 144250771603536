import * as React from 'react';
import {SVGProps} from 'react';

const MenuSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={54.946}
        height={37.497}
        {...props}
    >
        <path d="M21.812 0h33.133v37.5H0Z" fill="#000"/>
        <g fill={props.fill}>
            <path
                data-name="Pfad 2630"
                d="M41.244 22.443h2.97l1.485-2.506-1.485-2.594h-2.97l-1.485 2.6ZM33.714 22.443h2.97l1.485-2.506-1.485-2.594h-2.97l-1.485 2.6ZM26.184 22.443h2.97l1.485-2.506-1.485-2.594h-2.97l-1.485 2.6Z"
            />
        </g>
    </svg>
)

export default MenuSVG;
