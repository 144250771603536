export enum MediaContentType {
    JPG = 'image/jpg',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    MP4 = 'video/mp4',
    MP3 = 'audio/mpeg',
    GIF = 'image/gif',
    YOUTUBE = 'video/youtube',
    ZIP = 'zip'
}
