import {useEffect, useState} from 'react';

import {MediaContentType} from '../../utils/_enums/image-content-type.enum';
import ImageSize from '../../utils/_enums/image-size.enum';
import VariantService from '../services/variant.service';
import getConfig from 'next/config';

const useLoadImage = (
    imageId?: number,
    isAdmin?: boolean,
    imageSize?: ImageSize
): { url?: string, type?: MediaContentType } => {
    const [imageSrc, setImageSrc] = useState<{ url?: string, type?: MediaContentType }>({});

    const {publicRuntimeConfig} = getConfig();

    useEffect(() => {
        if (!imageId) {
            return;
        }
        const variantService = new VariantService();
        if (isAdmin) {
            (async () => {
                const imageRes = await variantService.getPermAttachmentContent(imageId, publicRuntimeConfig.backendURL, isAdmin, imageSize);
                if (imageRes.data) {
                    const imageUrl = `data:${imageRes.headers['content-type']};base64,${new Buffer(imageRes.data)
                        .toString('base64')}`;
                    setImageSrc({url: imageUrl, type: MediaContentType.PNG});
                }
            })();
        } else {
            (async () => {
                const attachment = await variantService.getPermAttachment(imageId);
                const url = `${publicRuntimeConfig.backendURL}api/perm-attachments/${imageId}/content${imageSize ? `?resolution=${imageSize}` : ''}`
                setImageSrc({
                    url: url,
                    type: attachment?.data.contentContentType
                });
            })();
        }
    }, [imageId]);

    return imageSrc;
};

export default useLoadImage;
