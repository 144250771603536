import React from 'react';
import ReactDOM from 'react-dom';

import { isServer } from '../../../utils/_helper/isServer';

export interface Props {
    selector?: string;
}

/**
 * Generates a new Element as an Child of the body tag by default and if an
 * `selector` prop is provided, it will be set as an child of that element instead.
 *
 * @example
 * <Modal>
 *  <div> Hello World </div>
 * </Modal>
 */
export const Modal: React.FC<Props> = ({ children, selector }) => {
    let element;

    if (isServer()) return null;

    if (!selector) {
        const existingElement = document.getElementById('modal');

        if (existingElement) {
            element = existingElement;
        } else {
            element = document.createElement('div');
            element.setAttribute('id', 'modal');

            document.querySelector('body')?.appendChild(element);
        }
    } else {
        element = document.querySelector(selector);
    }

    if (element) return ReactDOM.createPortal(children, element);
    else throw new Error(`Selector ${selector} not found in DOM`);
};
