import React from 'react';
import {
    IoIosSkipBackward,
    IoIosSkipForward,
    IoMdPause,
    IoMdPlay
} from 'react-icons/io';

import styles from './audio-controls.module.scss';

interface AudioControlsInterface {
    tracks: number;
    isPlaying: boolean;
    onPlayPauseClick: (setIsPlaying: boolean) => void;
    onPrevClick: () => void;
    onNextClick: () => void;
}

const AudioControls = (props: AudioControlsInterface): JSX.Element => {

    const { tracks, isPlaying, onPlayPauseClick, onPrevClick, onNextClick } = props;

    return (
        <div className={styles['audio-controls']}>
            {tracks > 1 && (
                <button
                    type="button"
                    className={styles['prev']}
                    aria-label="Previous"
                    onClick={onPrevClick}
                >
                    <IoIosSkipBackward />
                </button>
            )}
            {isPlaying ? (
                <button
                    type="button"
                    className={styles['pause']}
                    onClick={() => onPlayPauseClick(false)}
                    aria-label="Pause"
                >
                    <IoMdPause />
                </button>
            ) : (
                <button
                    type="button"
                    className={styles['play']}
                    onClick={() => onPlayPauseClick(true)}
                    aria-label="Play"
                >
                    <IoMdPlay />
                </button>
            )}
            {tracks > 1 && (
                <button
                    type="button"
                    className={styles['next']}
                    aria-label="Next"
                    onClick={onNextClick}
                >
                    <IoIosSkipForward />
                </button>
            )}
        </div>
    );
};

AudioControls.defaultProps = {};

export { AudioControls };