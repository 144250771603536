import React from 'react';

import {useTypoContent} from '../../../api/hooks/useTypoContent.hook';
import { TypoElementHtml } from '../../../api/models/typo/typo-element-html';
import {TypoService} from '../../../api/services/typo.service';
import styles from './html.module.scss';

const HtmlTypo = (props: TypoElementHtml): JSX.Element => {

    const { id, inverted, header, bodytext , fullwidth, wrapper} = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    const htmlContent = (text: string) => {
        if (header === '[MISSINGTOKENS]' && text.match('[CODE]')) {
            const typoService = new TypoService(useTypoContent());
            const response = typoService.getPageResponse();
            return text.replace('[CODE]', response ?? '');
        } else {
            return text;
        }
    }

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['html']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['container']}
                 dangerouslySetInnerHTML={{
                     __html: htmlContent(bodytext ?? '') as string
                 }}
            ></div>
        </div>
    );
};

HtmlTypo.defaultProps = {};

export { HtmlTypo };
