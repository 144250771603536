import * as React from 'react';
import {SVGProps} from 'react';

const BookSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24.308}
        height={20.045}
        {...props}
    >
        <path
            d="M12.18 2.906C9.131 1.008 4.755.15 0 0v17.786c4.084.124 9.2.9 12.179 2.26a57.174 57.174 0 0 1 12.13-2.506V.05c-4.756.142-9.083.958-12.129 2.856ZM1.79 16.163V1.95c5.349.4 7.808 1.518 9.336 2.421v13.657a35.4 35.4 0 0 0-9.336-1.865Zm20.746 0a35.317 35.317 0 0 0-9.336 1.866V4.367c1.508-.893 3.974-2.017 9.336-2.417Zm-2.075-10.6a28.632 28.632 0 0 0-6.224 1.734l.568-1.394a32.188 32.188 0 0 1 6.219-1.526Zm.417 3.143a30.589 30.589 0 0 0-6.073 1.554l-.568 1.262a28.63 28.63 0 0 1 6.224-1.735Zm0-2.107a30.589 30.589 0 0 0-6.073 1.554l-.568 1.262a28.63 28.63 0 0 1 6.224-1.735Zm0 6.32a52.481 52.481 0 0 0-6.073 1.554l-.568 1.262A28.63 28.63 0 0 1 20.461 14l.417-1.081Zm0-2.107a30.589 30.589 0 0 0-6.073 1.554l-.568 1.262a28.63 28.63 0 0 1 6.224-1.735ZM3.503 4.486A32.444 32.444 0 0 1 9.75 6.04l.339 1.262a28.574 28.574 0 0 0-6.224-1.734Zm.362 5.305a28.572 28.572 0 0 1 6.224 1.735l-.339-1.262A32.444 32.444 0 0 0 3.503 8.71Zm0-2.107a28.571 28.571 0 0 1 6.224 1.735L9.75 8.157a32.368 32.368 0 0 0-6.247-1.552Zm0 6.32a28.572 28.572 0 0 1 6.224 1.735l-.339-1.262a32.444 32.444 0 0 0-6.247-1.554Zm0-2.107a28.571 28.571 0 0 1 6.224 1.735L9.75 12.37a32.444 32.444 0 0 0-6.247-1.554Z"/>
    </svg>
)

export default BookSVG;