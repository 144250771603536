export enum BasicCardVariants {
    BASIC_CARD_SMALL = 'basic-card-small',
    BASIC_CARD_HORIZONTAL_SMALL = 'basic-card-horizontal-small',
    BASIC_CARD_HORIZONTAL_NORMAL = 'basic-card-horizontal-normal',
    TEXT_IMAGE_TEASER = 'text-image-teaser',
    MAGAZINE_CONTENT_TEASER = 'magazine-content-teaser',
    TEXT_ONLY_TEASER_NORMAL = 'text-only-teaser-normal',
    TEXT_ONLY_TEASER_LARGE = 'text-only-teaser-large',
    CONTENT_TEASER_SMALL = 'content-teaser-small',
    CONTENT_TEASER_NORMAL = 'content-teaser-normal',
    PURCHASE_CARD_NORMAL = 'purchase-card-normal',
    VARIANT_DETAIL = 'variant-detail',
    PODCAST_TEASER = 'podcast-teaser',
    CREATOKEN_CARD = 'creatoken-card'
}
