export enum StrapiHeadlineStyles {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    H2_LIGHT = 'H2_LIGHT',
    H3_LIGHT = 'H3_LIGHT',
    H4_LIGHT = 'H4_LIGHT'
}