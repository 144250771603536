import * as React from 'react';
import {SVGProps} from 'react';

const HeadphonesSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23.645}
        height={18.446}
        {...props}
    >
        <path
            data-name="Vereinigungsmenge 23"
            d="m2.559 18.447-2.56-4.43 2.558-4.431h2.559v8.861Zm15.97-.122V9.463h2.558l2.558 4.431-2.558 4.431Zm-8.113-1.737-1-3.059-.119.62-.019.116-.079.493H7.368l-.584-.659h1.8l.215-.612.4-1.17a.348.348 0 0 1 .044-.091.314.314 0 0 1 .566.09l.609 2.438.785-4.268c.056-.344.661-.345.739 0l.915 3.658.465-1.061c.105-.266.3-.418.418-.156l.751 1.715.478-.547h2.048l-.6.659h-1.118l-.61.609c-.147.2-.511.228-.609 0l-.527-1.268-.692 1.268a.318.318 0 0 1-.609 0l-.61-3.048-.609 4.268a.289.289 0 0 1-.292.255.327.327 0 0 1-.324-.25ZM3.099 8.053C3.646 3.28 6.792 0 11.636 0c4.746 0 7.86 3.119 8.521 7.93a12.6 12.6 0 0 0-1.729 0 6.953 6.953 0 0 0-6.792-6.413c-2.837.039-6.137 1.689-6.656 6.536Z"
        />
    </svg>
)

export default HeadphonesSVG;
