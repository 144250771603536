import React from 'react';

import { TypoElementIframe } from '../../../api/models/typo/typo-element-iframe';
import { Headline } from '../_shared/headline/headline';
import styles from './iframe.module.scss';

const Iframe = (props: TypoElementIframe): JSX.Element => {
    const { id, url, width, height, inverted, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    if (!url) {
        return <div />
    }

    return (
        <div
            id={`c${id}`} className={`
        ${styles['iframe-box']} 
        ${'spaceBefore' + appearance.spaceBefore} 
        ${'spaceAfter' + appearance.spaceAfter}
        ${inverted ? styles['inverted'] : ''} 
        ${fullwidth ? styles['fullwidth'] : ''} 
        ${wrapper !== undefined ? styles[wrapper] : ''}
      `}
      data-testid="iframe-box"
        >
            <Headline {...props} />
            <div className={styles['iframe-container']}>
                <iframe
                    src={url}
                    loading="lazy"
                    style={{ height: height ? height + 'px' : '', width: width ? width + 'px' : '' }}
                />
            </div>
        </div>
    );
};

export { Iframe };
