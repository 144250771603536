import React, { useEffect, useState } from 'react';

import { useCategories } from '../../../api/hooks/useCategories.hook';
import { useGenres } from '../../../api/hooks/useGenres.hook';
import { mapLookupToLanguageHook } from '../../../api/hooks/useLookupTableData.hook';
import { useSubCategories } from '../../../api/hooks/useSubCategories.hook';
import { useTypoContent } from '../../../api/hooks/useTypoContent.hook';
import { DetailedInstance } from '../../../api/models/detailed-instance';
import { InstanceStatus } from '../../../api/models/instance-status';
import { InstanceMeta } from '../../../api/models/instanceMeta';
import { LookupTableBaseModel } from '../../../api/models/lookup-table-base-model';
import { Product } from '../../../api/models/product';
import { Typo } from '../../../api/models/typo';
import { Variant } from '../../../api/models/variant';
import { VariantImage } from '../../../api/models/variant-image';
import { VariantImageType } from '../../../api/models/variant-image-type';
import InstancesService from '../../../api/services/instances.service';
import ProductService from '../../../api/services/product.service';
import VariantService from '../../../api/services/variant.service';
import { Blockchain } from '../../../utils/_enums/blockchain.enum';
import { FilterExpression } from '../../../utils/_enums/filter-expression.enum';
import { SalesType } from '../../../utils/_enums/sales-type';
import { EnumToLanguageMapper } from '../../../utils/_helper/enum-to-language-mapper';
import { selectLanguageForProductAttribute } from '../../../utils/_helper/select-language-for-product-attribute';
import { formatDateAndTime, isDateInFuture, isDateInPast } from '../../../utils/_helper/time-helper';
import { useAppSelector } from '../../../utils/_hooks/hooks';
import { ProductAttribute } from '../../../utils/_models/product-attribute';
import { RequestModel } from '../../../utils/_models/request-model';
import { UniversalTeaserProps } from '../../../utils/_models/universal-teaser-props';
import LoginDialog from '../login-dialog/login-dialog';
import CardBack from './card-back/card-back';
import CardFront from './card-front/card-front';
import styles from './teaser-card.module.scss';

export interface TeaserCardProps {
    variant?: Variant;
    instance?: DetailedInstance;
    inverted?: boolean;
    variantProductId?: number;
    variantId?: number;
    instanceProductId?: number;
    instanceId?: number;
}

const TeaserCard = (props: TeaserCardProps) => {
    const [showBack, setShowBack] = useState(false);
    const [elementToBuy, setElementToBuy] = useState<{ id?: number, type: SalesType } | undefined>(undefined);
    const [teaserProps, setTeaserProps] = useState<UniversalTeaserProps>({});
    const [cheapestInstance, setCheapestInstance] = useState<number | undefined>(undefined);
    const [unlockables, setUnlockables] = useState<RequestModel<VariantImage[] | undefined>>({
        data: undefined,
        finished: false
    });
    const [instanceMeta, setInstanceMeta] = useState<RequestModel<InstanceMeta | undefined>>({
        data: undefined,
        finished: false
    });
    const [product, setProduct] = useState<RequestModel<Product | undefined>>({
        data: undefined,
        finished: false
    });

    const [instance, setInstance] = useState({
        data: undefined,
        finished: false
    });

    const [attributes, setAttributes] = useState([]);

    const {user} = useAppSelector(state => ({
        user: state.userSlice.user
    }));

    const categories = useCategories();
    const subCategories = useSubCategories();
    const genres = useGenres();
    const data: Typo | undefined = useTypoContent();

    const onBuy = () => {
        if (teaserProps.instanceIdToBuy) {
            setElementToBuy({id: teaserProps.instanceIdToBuy, type: SalesType.FIXED_PRICE});
        } else if (teaserProps.salesType === SalesType.T_DROP && teaserProps.variantId) {
            setElementToBuy({id: teaserProps.variantId, type: SalesType.T_DROP});
        }
    }

    async function getInstanceMeta(url: string): Promise<InstanceMeta | undefined> {
        const instancesService = new InstancesService();
        try {
            return await instancesService.getJsonMetaDataByMetaUrl(url);
        } catch (e) {
            console.error(e);
        }
    }

    async function getUnlockables(variantId: number): Promise<VariantImage[] | undefined> {
        const variantService = new VariantService();
        try {
            return (await variantService.getPermAttachments(variantId, VariantImageType.ADDITIONAL_FILES)).data;
        } catch (e) {
            console.error(e);
        }
    }

    async function getProduct(productId: number): Promise<Product | undefined> {
        const productService = new ProductService();
        try {
            return (await productService.getProductById(productId)).data;
        } catch (e) {
            console.error(e);
        }
    }

    async function getFullInstance(productId: number) {
        const instanceService = new InstancesService();
        try {
            return (await instanceService.getInstancesById(productId)).data
        } catch (e) {
            console.error(e)
        }
    }

    // const getEtherscanUrl = () => {
    //     const prefix = Number(process.env.NEXT_PUBLIC_CHAIN_ID) === 4 ? 'rinkeby.' : '';
    //     const id = props.instance
    //         ? props.instance.creatoken
    //             ? props.instance.token
    //             : props.instance.id
    //         : undefined;
    //     const contract = props.instance
    //         ? props.instance.creatoken
    //             ? props.instance.contract
    //             : product?.data?.publisherTokenContract
    //         : undefined;
    //     return `https://${prefix}etherscan.io/token/${contract}?a=${id}`;
    // }

    useEffect(() => {
        if (props.variant) {
            getUnlockables(props.variant.id)
                .then(value => setUnlockables({data: value, finished: true}))
                .catch(() => setUnlockables({data: undefined, finished: true}));
            if (props.variant.productId) {
                getProduct(props.variant.productId)
                    .then(value => setProduct({data: value, finished: true}))
                    .catch(() => setProduct({data: undefined, finished: true}));
            }
        } else if (props.instance) {
            getUnlockables(props.instance.variant.id)
                .then(value => setUnlockables({data: value, finished: true}))
                .catch(() => setUnlockables({data: undefined, finished: true}));
            if (props.instance.metaUrl) {
                getInstanceMeta(props.instance.metaUrl)
                    .then(value => setInstanceMeta({data: value, finished: true}))
                    .catch(() => setInstanceMeta({data: undefined, finished: true}));
            }
            if (props.instance.variant.productId) {
                getProduct(props.instance.variant.productId)
                    .then(value => setProduct({data: value, finished: true}))
                    .catch(() => setProduct({data: undefined, finished: true}));
            }
        }
    }, [props.variant, props.instance]);

    useEffect(() => {
        if(!props.variant && !props.instance) {
            if (props.variantId !== 0 && props.variantProductId !== 0) {
                getUnlockables(props.variantId as number)
                    .then(value => setUnlockables({data: value, finished: true}))
                    .catch(() => setUnlockables({data: undefined, finished: true}));
                if (props.variantProductId) {
                    getProduct(props.variantProductId)
                        .then(value => setProduct({ data: value, finished: true }))
                        .catch(() => setProduct({data: undefined, finished: true}));

                    const variantService = new VariantService();

                    variantService.getDetailedVariants([
                        {
                            param: 'productId',
                            value: props.variantProductId.toString(),
                            comparisonType: FilterExpression.equals
                        }
                    ]).then(value => {
                        const product = value.data.find(item => item.id === props.variantId)

                        setCheapestInstance(product?.cheapestInstanceId)
                    });

                }
            } else if ((props.instanceId && props.instanceProductId) && (props.variantId === 0 && props.variantProductId === 0)) {
                getUnlockables(props.instanceId)
                    .then(value => setUnlockables({data: value, finished: true}))
                    .catch(() => setUnlockables({data: undefined, finished: true}));
                if(props.instanceId) {
                    getFullInstance(props.instanceId)
                        .then(value => setInstance({data: value, finished: true}))
                        .catch(() => setInstance({data: undefined, finished: true}));
                }
                if (instance.data) {
                    getInstanceMeta(instance.data?.metaUrl)
                        .then(value => setInstanceMeta({data: value, finished: true}))
                        .catch(() => setInstanceMeta({data: undefined, finished: true}));
                }
                if (props.instanceProductId) {
                    getProduct(props.instanceProductId)
                        .then(value => setProduct({data: value, finished: true}))
                        .catch(() => setProduct({data: undefined, finished: true}));
                }
            }
        }
    }, [props.variantId, props.variantProductId, props.instanceId, props.instanceProductId, instance.finished]);

    useEffect(() => {
        const tempAttributes = [];
        let tempCategory;
        let tempSubCategory;
        let tempGenre;
        let tempLabels;
        let tempProductLabels;
        let releaseDate;
        let endOfSale;
        let units;
        let categoryLabel;
        let subCategoryLabel;
        let genreLabel;

        if(data) {
            tempLabels = data.creatokia.labels;
            tempProductLabels = tempLabels.find(item => { return item.slug === 'teaser' });

            if(tempProductLabels?.subcategories !== undefined) {
                releaseDate = tempProductLabels.subcategories.find(item => item.slug === 'release-date')?.title;
                endOfSale = tempProductLabels.subcategories.find(item => item.slug === 'end-of-sale')?.title;
                units = tempProductLabels.subcategories.find(item => item.slug === 'units')?.title;

                categoryLabel = tempProductLabels.subcategories.find(item => item.slug === 'category')?.title;
                subCategoryLabel = tempProductLabels.subcategories.find(item => item.slug === 'sub-category')?.title;
                genreLabel = tempProductLabels.subcategories.find(item => item.slug === 'genre')?.title;
            } else {
                console.error('Product labels could not be found!')
                releaseDate = '';
                endOfSale = '';
                units = '';

                categoryLabel = '';
                subCategoryLabel = '';
                genreLabel = '';
            }
        }

        if (props.variant) {
            if (props.variant.salesAttribute) {
                if (props.variant.salesAttribute.saleFrom) {
                    tempAttributes.push({
                        label: releaseDate,
                        value: formatDateAndTime(props.variant.salesAttribute.saleFrom)
                    })
                }
                if (props.variant.salesAttribute.saleTo) {
                    tempAttributes.push({
                        label: endOfSale,
                        value: formatDateAndTime(props.variant.salesAttribute.saleTo)
                    })
                }
                if (props.variant.salesAttribute.mintingCount) {
                    tempAttributes.push({
                        label: units,
                        value: props.variant.salesAttribute.mintingCount
                    })
                }
            }

            props.variant?.variantAttributes.map((item) => {
                const res = selectLanguageForProductAttribute(
                    (product.data?.productAttributes ?? []).find(
                        (att) =>
                            (att as ProductAttribute).id ===
                            item.productAttributeId
                    )
                );

                if (!res || !item.value) return undefined;

                tempAttributes.push({
                    label: res,
                    value: item.value
                })
            })

            tempCategory = mapLookupToLanguageHook(
                categories.data?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.categoryId)
                ?.text;

            tempSubCategory = mapLookupToLanguageHook(
                subCategories.data
                    ?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.subCategoryId)
                ?.text;

            tempGenre = mapLookupToLanguageHook(
                genres.data?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.genreId)
                ?.text;

            if (tempCategory) {
                tempAttributes.push({
                    label: categoryLabel,
                    value: tempCategory
                })
            }

            if (tempSubCategory) {
                tempAttributes.push({
                    label: subCategoryLabel,
                    value: tempSubCategory
                })
            }

            if (tempGenre) {
                tempAttributes.push({
                    label: genreLabel,
                    value: tempGenre
                })
            }

            setAttributes(tempAttributes)
        }

        if (props.instance) {
            if (props.instance.variant.salesAttribute) {
                if (props.instance.variant.salesAttribute.saleFrom) {
                    tempAttributes.push({
                        label: releaseDate,
                        value: formatDateAndTime(props.instance.variant.salesAttribute.saleFrom)
                    })
                }
                if (props.instance.variant.salesAttribute.saleTo) {
                    tempAttributes.push({
                        label: endOfSale,
                        value: formatDateAndTime(props.instance.variant.salesAttribute.saleTo)
                    })
                }
                if (props.instance.variant.salesAttribute.mintingCount) {
                    tempAttributes.push({
                        label: units,
                        value: props.instance.variant.salesAttribute.mintingCount
                    })
                }
            }

            props.instance.variant?.variantAttributes.map((item) => {
                const res = selectLanguageForProductAttribute(
                    (product.data?.productAttributes ?? []).find(
                        (att) =>
                            (att as ProductAttribute).id ===
                            item.productAttributeId
                    )
                );

                if (!res || !item.value) return undefined;

                tempAttributes.push({
                    label: res,
                    value: item.value
                })
            })

            tempCategory = mapLookupToLanguageHook(
                categories.data?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.categoryId)
                ?.text;

            tempSubCategory = mapLookupToLanguageHook(
                subCategories.data
                    ?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.subCategoryId)
                ?.text;

            tempGenre = mapLookupToLanguageHook(
                genres.data?.data as LookupTableBaseModel[])
                .find((att) => att.id === product?.data?.genreId)
                ?.text;

            if (tempCategory) {
                tempAttributes.push({
                    label: categoryLabel,
                    value: tempCategory
                })
            }

            if (tempSubCategory) {
                tempAttributes.push({
                    label: subCategoryLabel,
                    value: tempSubCategory
                })
            }

            if (tempGenre) {
                tempAttributes.push({
                    label: genreLabel,
                    value: tempGenre
                })
            }

            setAttributes(tempAttributes)
        }

        if(!props.variant && !props.instance) {
            if (props.variantId !== 0 && props.variantProductId !== 0) {
                const productData = product.data;
                const variantData = productData?.variants.find(variant => variant.id === props.variantId);

                if (productData && variantData?.salesAttribute) {
                    if (variantData?.salesAttribute.saleFrom) {
                        tempAttributes.push({
                            label: releaseDate,
                            value: formatDateAndTime(variantData?.salesAttribute.saleFrom)
                        })
                    }
                    if (variantData?.salesAttribute.saleTo) {
                        tempAttributes.push({
                            label: endOfSale,
                            value: formatDateAndTime(variantData?.salesAttribute.saleTo)
                        })
                    }
                    if (variantData?.salesAttribute.mintingCount) {
                        tempAttributes.push({
                            label: units,
                            value: variantData?.salesAttribute.mintingCount
                        })
                    }
                }

                variantData?.variantAttributes.map((item) => {
                    const res = selectLanguageForProductAttribute(
                        (productData?.productAttributes ?? []).find(
                            (att) =>
                                (att as ProductAttribute).id ===
                                item.productAttributeId
                        )
                    );

                    if (!res || !item.value) return undefined;

                    tempAttributes.push({
                        label: res,
                        value: item.value
                    })
                })

                tempCategory = mapLookupToLanguageHook(
                    categories.data?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.categoryId)
                    ?.text;

                tempSubCategory = mapLookupToLanguageHook(
                    subCategories.data
                        ?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.subCategoryId)
                    ?.text;

                tempGenre = mapLookupToLanguageHook(
                    genres.data?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.genreId)
                    ?.text;

                if (tempCategory) {
                    tempAttributes.push({
                        label: categoryLabel,
                        value: tempCategory
                    })
                }

                if (tempSubCategory) {
                    tempAttributes.push({
                        label: subCategoryLabel,
                        value: tempSubCategory
                    })
                }

                if (tempGenre) {
                    tempAttributes.push({
                        label: genreLabel,
                        value: tempGenre
                    })
                }

                setAttributes(tempAttributes)
            }

            if ((props.instanceId && props.instanceProductId) && (props.variantId === 0 && props.variantProductId === 0)) {
                const productData = product.data;
                const instanceVariantData = productData?.variants.find(variant => variant.productId === props.instanceProductId);

                if (instanceVariantData?.salesAttribute) {
                    if (instanceVariantData?.salesAttribute.saleFrom) {
                        tempAttributes.push({
                            label: releaseDate,
                            value: formatDateAndTime(instanceVariantData?.salesAttribute.saleFrom)
                        })
                    }
                    if (instanceVariantData?.salesAttribute.saleTo) {
                        tempAttributes.push({
                            label: endOfSale,
                            value: formatDateAndTime(instanceVariantData?.salesAttribute.saleTo)
                        })
                    }
                    if (instanceVariantData?.salesAttribute.mintingCount) {
                        tempAttributes.push({
                            label: units,
                            value: instanceVariantData?.salesAttribute.mintingCount
                        })
                    }
                }

                instanceVariantData?.variantAttributes.map((item) => {
                    const res = selectLanguageForProductAttribute(
                        (productData?.productAttributes ?? []).find(
                            (att) =>
                                (att as ProductAttribute).id ===
                                item.productAttributeId
                        )
                    );

                    if (!res || !item.value) return undefined;

                    tempAttributes.push({
                        label: res,
                        value: item.value
                    })
                })

                tempCategory = mapLookupToLanguageHook(
                    categories.data?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.categoryId)
                    ?.text;

                tempSubCategory = mapLookupToLanguageHook(
                    subCategories.data
                        ?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.subCategoryId)
                    ?.text;

                tempGenre = mapLookupToLanguageHook(
                    genres.data?.data as LookupTableBaseModel[])
                    .find((att) => att.id === product?.data?.genreId)
                    ?.text;

                if (tempCategory) {
                    tempAttributes.push({
                        label: categoryLabel,
                        value: tempCategory
                    })
                }

                if (tempSubCategory) {
                    tempAttributes.push({
                        label: subCategoryLabel,
                        value: tempSubCategory
                    })
                }

                if (tempGenre) {
                    tempAttributes.push({
                        label: genreLabel,
                        value: tempGenre
                    })
                }

                setAttributes(tempAttributes)
            }
        }

    }, [
        props.variant,
        props.variantId,
        props.variantProductId,
        props.instance,
        props.instanceId,
        props.instanceProductId,
        product,
        categories.isSuccess,
        subCategories.isSuccess,
        genres.isSuccess,
        data,
        cheapestInstance
    ])

    useEffect(() => {
        let tempProps: UniversalTeaserProps;
        let tempLabels;
        let tempCategoryLabels;
        let categoryObject;
        let tempGeneralStrings;
        let generalStringObject;
        let blockchainStrings;
        let blockchainObject;
        let teaserMenuStrings;
        let teaserMenuObject;

        if (data) {
            tempLabels = data.creatokia.labels;
            tempCategoryLabels = tempLabels.find(item => { return item.slug === 'product-category' });
            tempGeneralStrings = tempLabels.find(item => { return item.slug === 'teaser' });
            blockchainStrings = tempLabels.find(item => { return item.slug === 'blockchains' });
            teaserMenuStrings = tempLabels.find(item => { return item.slug === 'teaser-menu' });

            if(tempCategoryLabels?.subcategories !== undefined) {
                categoryObject = {
                    audio: tempCategoryLabels.subcategories.find(item => { return item.slug === 'audio' })?.title,
                    art: tempCategoryLabels.subcategories.find(item => { return item.slug === 'art' })?.title,
                    text: tempCategoryLabels.subcategories.find(item => { return item.slug === 'text' })?.title,
                }
            } else {
                console.error('Category labels could not be found!')
                categoryObject = { audio: '', art: '', text: '' }
            }

            if(tempGeneralStrings?.subcategories !== undefined) {
                generalStringObject = {
                    fromLowest: tempGeneralStrings.subcategories.find(item => { return item.slug === 'from' })?.title,
                    buy: tempGeneralStrings.subcategories.find(item => { return item.slug === 'buy' })?.title,
                    options: tempGeneralStrings.subcategories.find(item => { return item.slug === 'options' })?.title,
                    unlockables: tempGeneralStrings.subcategories.find(item => { return item.slug === 'unlockables' })?.title,
                }
            } else {
                console.error('General strings could not be found!')
                generalStringObject = { fromLowest: '', buy: '', options: '', unlockables: '' }
            }

            if(blockchainStrings?.subcategories !== undefined) {
                blockchainObject = {
                    ethereum: blockchainStrings.subcategories.find(item => { return item.slug === 'ethereum' })?.title,
                    polygon: blockchainStrings.subcategories.find(item => { return item.slug === 'polygon' })?.title,
                }
            } else {
                console.error('Blockchain strings could not be found!')
                blockchainObject = { ethereum: '', polygon: '' }
            }

            if(teaserMenuStrings?.subcategories !== undefined) {
                teaserMenuObject = {
                    buyNow: teaserMenuStrings.subcategories.find(item => { return item.slug === 'buy-now' })?.title,
                    cancelResale: teaserMenuStrings.subcategories.find(item => { return item.slug === 'cancel-resale' })?.title,
                    mint: teaserMenuStrings.subcategories.find(item => { return item.slug === 'mint' })?.title,
                    sell: teaserMenuStrings.subcategories.find(item => { return item.slug === 'sell' })?.title,
                    share: teaserMenuStrings.subcategories.find(item => { return item.slug === 'share' })?.title,
                    instanceDetails: teaserMenuStrings.subcategories.find(item => { return item.slug === 'instance-details' })?.title,
                    variantDetails: teaserMenuStrings.subcategories.find(item => { return item.slug === 'variant-details' })?.title,
                    more: teaserMenuStrings.subcategories.find(item => { return item.slug === 'more' })?.title,
                    instanceIDString: teaserMenuStrings.subcategories.find(item => { return item.slug === 'instance-id' })?.title,
                    variantIDString: teaserMenuStrings.subcategories.find(item => { return item.slug === 'variant-id' })?.title,
                }
            } else {
                console.error('Teaser Menu strings could not be found!')
                teaserMenuObject = {
                    buyNow: '',
                    cancelResale: '',
                    mint: '',
                    sell: '',
                    share: '',
                    instanceDetails: '',
                    variantDetails: '',
                    more: '',
                    instanceIDString: '',
                    variantIDString: '',
                }
            }
        }

        if (props.variant) {
            tempProps = {
                unlockables: unlockables.data && unlockables.data.length > 0,
                category: props.variant.categoryId,
                mediaUrl: props.variant?.coverArt?.url,
                mediaContentType: props.variant?.coverArt?.contentContentType,
                title: props.variant.title,
                topline: product.data?.seriesName,
                description: props.variant.variantDescriptionLong,
                variantAttributes: attributes,
                blockchain: Blockchain.ETHEREUM,
                instanceIdToBuy: props.variant.cheapestInstanceId,
                ethPrice: props.variant.cheapestEthPrice ?? props.variant.salesAttribute?.priceEth,
                fiatPrice: props.variant.cheapestFiatPrice ?? props.variant.salesAttribute?.priceFiat,
                currency: props.variant.salesAttribute?.currency,
                inverted: props.inverted,
                canBuy: !!props.variant.cheapestInstanceId
                    || (props.variant.salesAttribute?.salesType === SalesType.T_DROP
                        && isDateInPast(props.variant.salesAttribute?.saleFrom)
                        && isDateInFuture(props.variant.salesAttribute?.saleTo, true)),
                productName: props.variant.productName,
                productId: props.variant.productId,
                variantId: props.variant.id,
                salesType: props.variant.salesAttribute?.salesType,
                tokenContract: props.variant.salesAttribute?.contract?.contractAddress,
                salesFrom: props.variant.salesAttribute?.saleFrom,
                productCategories: categoryObject,
                generalStrings: generalStringObject,
                blockchainStrings: blockchainObject,
                teaserMenuLabels: teaserMenuObject,
            }
            setTeaserProps(tempProps);
        } else if (props.instance && instanceMeta.finished) {
            tempProps = {
                unlockables: unlockables.data && unlockables.data.length > 0,
                category: props.instance.variant.categoryId,
                mediaUrl: instanceMeta.data?.image ?? props.instance.variant.coverArt.url,
                mediaContentType: instanceMeta.data?.image
                    ? EnumToLanguageMapper.urlWithExtensionToMediaContentType(instanceMeta.data.image)
                    : props.instance.variant.coverArt.contentContentType,
                title: instanceMeta.data?.name ?? props.instance.variant.title,
                topline: product.data?.seriesName,
                description: props.instance.variant.variantDescriptionLong,
                variantAttributes: attributes,
                blockchain: Blockchain.ETHEREUM,
                ethPrice: props.instance.priceEth,
                fiatPrice: props.instance.priceFiat,
                currency: props.instance.currency,
                instanceId: props.instance.id,
                instanceIdToBuy: props.instance.id,
                inverted: props.inverted,
                canBuy: props.instance.status === InstanceStatus.OPEN
                    && (!props.instance.ownerId && !props.instance.ownerWallet
                        ? (isDateInPast(props.instance.variant.salesAttribute?.saleFrom)
                            && isDateInFuture(props.instance.variant.salesAttribute?.saleTo, true))
                        : ((props.instance.ownerId && props.instance.ownerId === user?.id)
                            || (!!props.instance.ownerWallet && props.instance.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase()))
                            ? false
                            : isDateInPast(props.instance.saleStartDate)),
                productName: props.instance.variant.productName,
                productId: props.instance.variant.productId,
                variantId: props.instance.variant.id,
                salesType: props.instance.variant.salesAttribute?.salesType,
                canMint: props.instance.status === InstanceStatus.SOLD
                    && !props.instance.minted
                    && !!props.instance.ownerId
                    && props.instance.ownerId === user?.id,
                canCancelResale: props.instance.status === InstanceStatus.OPEN
                    && ((props.instance.ownerId && props.instance.ownerId === user?.id)
                        || (!!props.instance.ownerWallet && props.instance.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase())),
                canSell: props.instance.status === InstanceStatus.SOLD
                    && props.instance.minted
                    && ((props.instance.ownerId && props.instance.ownerId === user?.id)
                        || (!!props.instance.ownerWallet && props.instance.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase())),
                tokenContract: props.instance.variant.salesAttribute?.contract?.contractAddress,
                salesFrom: props.instance.variant.salesAttribute?.saleFrom,
                productCategories: categoryObject,
                generalStrings: generalStringObject,
                blockchainStrings: blockchainObject,
                teaserMenuLabels: teaserMenuObject,
                // etherscanUrl: getEtherscanUrl(),
            }
            setTeaserProps(tempProps);
        }

        if(!props.variant && !props.instance) {
            if (props.variantId !== 0 && props.variantProductId !== 0) {
                const productData = product.data;
                const variantData = productData?.variants.find(variant => variant.id === props.variantId);

                tempProps = {
                    unlockables: unlockables.data && unlockables.data.length > 0,
                    category: productData?.categoryId,
                    mediaUrl: variantData?.coverArt.url,
                    mediaContentType: variantData?.coverArt.contentContentType,
                    title: variantData?.title,
                    topline: productData?.seriesName,
                    description: variantData?.variantDescriptionLong,
                    variantAttributes: attributes,
                    blockchain: Blockchain.ETHEREUM,
                    ethPrice: variantData?.salesAttribute?.priceEth,
                    fiatPrice: variantData?.salesAttribute?.priceFiat,
                    currency: variantData?.salesAttribute?.currency,
                    inverted: props.inverted,
                    instanceIdToBuy: cheapestInstance,
                    canBuy: !!cheapestInstance
                        || (variantData?.salesAttribute?.salesType === SalesType.T_DROP
                            && isDateInPast(variantData?.salesAttribute?.saleFrom)
                            && isDateInFuture(variantData?.salesAttribute?.saleTo, true)),
                    productName: productData?.productName,
                    productId: props.variantProductId,
                    variantId: props.variantId,
                    salesType: variantData?.salesAttribute?.salesType,
                    tokenContract: variantData?.salesAttribute?.contract?.contractAddress,
                    salesFrom: variantData?.salesAttribute?.saleFrom,
                    productCategories: categoryObject,
                    generalStrings: generalStringObject,
                    blockchainStrings: blockchainObject,
                    teaserMenuLabels: teaserMenuObject,
                }

                setTeaserProps(tempProps);
            }
            else if ((props.instanceId && props.instanceProductId) && (props.variantId === 0 && props.variantProductId === 0)) {
                const productData = product.data;
                const instanceData = instance.data;
                const instanceVariantData = productData?.variants.find(variant => variant.id === instanceData?.variant.id);

                tempProps = {
                    unlockables: unlockables.data && unlockables.data.length > 0,
                    category: instanceVariantData?.categoryId,
                    mediaUrl: instanceMeta.data?.image ?? '',
                    mediaContentType: instanceMeta.data?.image
                        ? EnumToLanguageMapper.urlWithExtensionToMediaContentType(instanceMeta.data.image)
                        : instanceVariantData?.coverArt.contentContentType,
                    title: instanceMeta.data?.name ?? instanceVariantData?.title,
                    topline: productData?.seriesName,
                    description: instanceVariantData?.variantDescriptionLong,
                    variantAttributes: attributes,
                    blockchain: Blockchain.ETHEREUM,
                    ethPrice: instanceVariantData?.salesAttribute.priceEth,
                    fiatPrice: instanceVariantData?.salesAttribute.priceFiat,
                    currency: instanceVariantData?.salesAttribute.currency,
                    instanceId: props.instanceId,
                    instanceIdToBuy: props.instanceId,
                    inverted: props.inverted,
                    canBuy: instanceData?.status === InstanceStatus.OPEN
                        && (!instanceData?.ownerId && !instanceData?.ownerWallet
                            ? (isDateInPast(instanceVariantData?.salesAttribute?.saleFrom)
                                && isDateInFuture(instanceVariantData?.salesAttribute?.saleTo, true))
                            : ((instanceData?.ownerId && instanceData?.ownerId === user?.id)
                                || (!!instanceData?.ownerWallet && instanceData?.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase()))
                                ? false
                                : isDateInPast(instanceData?.saleStartDate)),
                    productName: productData?.productName,
                    productId: instanceVariantData?.productId,
                    variantId: instanceVariantData?.id,
                    salesType: instanceVariantData?.salesAttribute?.salesType,
                    canMint: instanceData?.status === InstanceStatus.SOLD
                        && !instanceData?.minted
                        && !!instanceData?.ownerId
                        && instanceData?.ownerId === user?.id,
                    canCancelResale: instanceData?.status === InstanceStatus.OPEN
                        && ((instanceData?.ownerId && instanceData?.ownerId === user?.id)
                            || (!!instanceData?.ownerWallet && instanceData?.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase())),
                    canSell: instanceData?.status === InstanceStatus.SOLD
                        && instanceData?.minted
                        && ((instanceData?.ownerId && instanceData?.ownerId === user?.id)
                            || (!!instanceData?.ownerWallet && instanceData?.ownerWallet?.toLowerCase() === user?.wallet?.toLowerCase())),
                    tokenContract: instanceVariantData?.salesAttribute?.contract?.contractAddress,
                    salesFrom: instanceVariantData?.salesAttribute?.saleFrom,
                    productCategories: categoryObject,
                    generalStrings: generalStringObject,
                    blockchainStrings: blockchainObject,
                    teaserMenuLabels: teaserMenuObject,
                }
                setTeaserProps(tempProps);
            }
        }

    }, [
        user,
        unlockables.data,
        instanceMeta.data,
        product.data,
        instance.data,
        attributes,
        data
    ]);

    return (
        <div className={styles['teaser-card']}>
            <div className={`
            ${styles['teaser-card-content']}
            ${showBack ? styles['card-animation'] : ''}
            ${props.inverted ? styles['inverted'] : ''}
            `}>
                <CardFront {...teaserProps} onBuy={onBuy} onSwap={() => setShowBack(prevState => !prevState)}/>
                <CardBack {...teaserProps} onBuy={onBuy} onSwap={() => setShowBack(prevState => !prevState)}/>
            </div>
            {elementToBuy
                ? <LoginDialog
                    withBuyOption={{
                        setIdToBuy: id => setElementToBuy({id: id, type: elementToBuy.type}),
                        idToBuy: elementToBuy.id,
                        salesType: elementToBuy.type
                    }}
                />
                : undefined
            }
        </div>
    );
};

export default TeaserCard;
