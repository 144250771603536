import { StrapiHeadline } from '../models/strapi/strapi-headline';
import { StrapiImage } from '../models/strapi/strapi-image';
import { StrapiLinkProps } from '../models/strapi/strapi-link-props';
import { StrapiParagraphProps } from '../models/strapi/strapi-paragraph-props';
import { StrapiTextFieldProps } from '../models/strapi/strapi-text-field-props';
import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useFooter = useLanguageDependentDataSSr<UseFooterData>('footer');

interface UseFooterData {
    email: StrapiTextFieldProps;
    copyRight: StrapiParagraphProps;
    paragraph: StrapiParagraphProps;
    background: StrapiImage;
    emailHeadline: StrapiHeadline;
    emailParagraph: StrapiParagraphProps;
    backgroundColor?: string;
    successContent?: string;
    errorContent?: string;
    links: {
        headline: StrapiHeadline;
        links: StrapiLinkProps[];
    }[];
    facebookLink: StrapiLinkProps;
    instagramLink: StrapiLinkProps;
    discordURL: StrapiLinkProps;
    twitterLink: StrapiLinkProps;
    linkedinLink: StrapiLinkProps;
}
