import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useHeader = useLanguageDependentDataSSr<UseHeaderData>('header');

interface UseHeaderData {
    navLinks: {
        label: string;
        link: string;
        external: boolean;
    }[];
    mobileLoginFormTitle: string;
    mobileLoginFormErrorMessage: string;
    registerLinkText: string;
    logoutLinkText: string;
}
