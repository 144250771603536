import countries from 'i18n-iso-countries';
import ISO6391 from 'iso-639-1';

import {UseAccountBasePageData} from '../../api/hooks/useAccountBasePage';
import {MediaContentType} from '../_enums/image-content-type.enum';
import {ProductCategories} from '../_enums/product-categories.enum';
import {AccountRoutePath} from '../_enums/route-path.enum';
import {SalesType} from '../_enums/sales-type';
import {ValueStringMapping} from '../_models/value-string-mapping';
import {getLanguage} from './locale-helper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

export class EnumToLanguageMapper {
    static accountRoutesMapping(
        data?: UseAccountBasePageData
    ): ValueStringMapping<AccountRoutePath>[] {
        if (data) {
            return [
                {
                    value: AccountRoutePath.PROFILE,
                    label: data?.profileButton.buttonText
                },
                {
                    value: AccountRoutePath.NOTIFICATIONS,
                    label: data?.notificationsButton.buttonText
                },
                {
                    value: AccountRoutePath.PORTFOLIO_AND_GALLERY,
                    label: data?.portfolioAndGalleryButton.buttonText
                },
                {
                    value: AccountRoutePath.CHANGE_PASSWORD,
                    label: data?.changePasswordButton.buttonText
                },
                {
                    value: AccountRoutePath.INVOICE_ADDRESS,
                    label: data?.invoiceAddressButton.buttonText
                },
                {
                    value: AccountRoutePath.SUPPORT_AND_CONTACT,
                    label: data?.supportAndContactButton.buttonText
                }
            ];
        } else
            return [];

    }

    static salesTypeToStringMapping(
        fixPrice: string,
        auction: string
    ): ValueStringMapping<SalesType>[] {
        return [
            {
                value: SalesType.FIXED_PRICE,
                label: fixPrice
            },
            {
                value: SalesType.AUCTION,
                label: auction
            }
        ];
    }

    static countriesMapping(): ValueStringMapping[] {
        return Object.keys(countries.getNames(getLanguage())).map((country) => {
            return {
                label: countries.getNames(getLanguage())[country],
                value: country
            };
        });
    }

    static countryCodeToLabel(code: string): string {
        return countries.getNames(getLanguage())[code];
    }

    static languageMapping(): ValueStringMapping[] {
        return ISO6391.getLanguages(ISO6391.getAllCodes()).map((language) => {
            return {
                label: language.nativeName,
                value: language.code
            };
        });
    }

    static languageCodeToLabel(name: string): string {
        const language = ISO6391.getLanguages(ISO6391.getAllCodes()).find(
            (lng) => lng.nativeName === name
        );
        return language ? language.code : '';
    }

    static categoryIdToCategoryEnum(id?: number): ProductCategories | undefined {
        switch (id) {
            case 1:
                return ProductCategories.AUDIO;
            case 2:
                return ProductCategories.EBOOK;
            case 3:
                return ProductCategories.ART;
        }
    }

    static chainIdToName(chainId: number, defaultMessage: string): string {
        switch (chainId) {
            case 1:
                return 'Mainnet';
            case 5:
                return 'Goerli';
            default:
                return defaultMessage;
        }
    }

    static urlWithExtensionToMediaContentType(url?: string): MediaContentType {
        if (url) {
            const indexOfExtension = url.lastIndexOf('.');
            if (indexOfExtension) {
                const extension = url.substring(indexOfExtension).toLowerCase();
                switch (extension) {
                    case '.mp4':
                        return MediaContentType.MP4;
                    case '.png':
                        return MediaContentType.PNG;
                    case '.gif':
                        return MediaContentType.GIF;
                    case '.jpg':
                        return MediaContentType.JPG;
                    case '.jpeg':
                        return MediaContentType.JPEG;
                    default:
                        return MediaContentType.PNG;
                }
            }
        }
        return MediaContentType.PNG;
    }
}
