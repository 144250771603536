import React from 'react';

import {useHeader} from '../../../../../api/hooks/useHeader.hook';
import {DefaultHeaderMobileLoginFormContextEnum} from '../../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import {RoutePath} from '../../../../../utils/_enums/route-path.enum';
import {useDefaultHeaderMobileLoginContext} from '../../../../../utils/_hooks/useDefaultHeaderMobileLoginContext.hook';
import {LanguageSelectorOptions} from '../../../../../utils/_models/languageSelectorOptions';
import {HeaderMobileNavigation} from '../../header-mobile/header-mobile-navigation/header-mobile-navigation';
import styles from './default-header-mobile-navigation.module.scss';

interface DefaultHeaderMobileNavigationProps {
    localization?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    pageType?: string;
}

export const DefaultHeaderMobileNavigation: React.FC<DefaultHeaderMobileNavigationProps> = (
    props: DefaultHeaderMobileNavigationProps
) => {
    const {mobileNavOpen, dispatch} = useDefaultHeaderMobileLoginContext();

    const data = useHeader();

    if (!data) return null;

    const {navLinks} = data;

    return (
        <HeaderMobileNavigation
            cancelButtonOffset={123}
            className={styles['default-header-mobile-navigation']}
            open={mobileNavOpen}
            localizations={props.localization}
            forStaticPage={props.forStaticPage}
            pageType={props.pageType}
            onCancelClick={() =>
                dispatch({
                    type:
                    DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION
                })
            }
            navLinks={
                navLinks?.map(({external, label, link}) => ({
                    label,
                    value: link as RoutePath,
                    external
                })) ?? []
            }
        />
    );
};
