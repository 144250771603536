import React, {ChangeEvent, useState} from 'react';
import {UseFormRegister} from 'react-hook-form';

import {ErrorMessage} from '../../error/error-message';
import ToolTip from '../../tool-tip/tool-tip';
import styles from './text-area.module.scss';

export interface TextAreaProps
    extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    placeholder: string;
    errorMessage?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: UseFormRegister<any>;
    name: string;
    validation?: Parameters<TextAreaProps['register']>[1];
    title?: string;
    rows?: number;
    fieldToolTip?: string;
    className?: string;
    inverted?: boolean;
    defaultCount?: number;
}

const TextArea = (props: TextAreaProps): JSX.Element => {
    const [count, setCount] = useState(props.defaultCount);

    const textAreaLengthCheck = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setCount(event.target.value.length);
    }

    return (
        <div
            data-testid="text-area"
            className={`${styles['text-area-field-wrapper']}
        ${props.className ? props.className : ''}
        ${props.inverted ? styles['inverted'] : ''}
        ${props.disabled ? styles['disabled'] : ''}`}
        >
            {props.title ? (
                <label
                    htmlFor={props.title}
                    className={props.errorMessage ? styles['error'] : ''}
                >
                    {props.title + (props.required ? '*' : '')}
                    {props.fieldToolTip ? (
                        <ToolTip toolTip={props.fieldToolTip}/>
                    ) : null}
                </label>
            ) : undefined}
            <div className={styles['text-area']}>
                <textarea
                    className={props.errorMessage ? styles['error'] : ''}
                    rows={props.rows}
                    {...props.register(props.name, props.validation)}
                    placeholder={props.placeholder}
                    onChange={(event) => textAreaLengthCheck(event)}
                />
                {props.max ? <span className={styles['char-counter']}>{count}/{props.max}</span> : <span className={styles['char-counter']}/>}
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </div>
        </div>
    );
};

export default TextArea;
