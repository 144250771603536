import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {useAccountBasePage} from '../../../../../api/hooks/useAccountBasePage';
import {useAdminHeader} from '../../../../../api/hooks/useAdminHeader.hook';
import {useHeader} from '../../../../../api/hooks/useHeader.hook';
import {logout, RootState, useAppSelector} from '../../../../../redux';
import {DefaultHeaderMobileLoginFormContextEnum} from '../../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import {RoutePath} from '../../../../../utils/_enums/route-path.enum';
import {EnumToLanguageMapper} from '../../../../../utils/_helper/enum-to-language-mapper';
import {useDefaultHeaderMobileLoginContext} from '../../../../../utils/_hooks/useDefaultHeaderMobileLoginContext.hook';
import {LanguageSelectorOptions} from '../../../../../utils/_models/languageSelectorOptions';
import {Link} from '../../../link/link';
import {Seperator} from '../../../seperator/seperator';
import {Spacer} from '../../../spacer/spacer';
import {HeaderMobileNavigation} from '../../header-mobile/header-mobile-navigation/header-mobile-navigation';
import styles from './default-header-mobile-my-account-navigation.module.scss';

interface DefaultHeaderMobileMyAccountNavigationProps {
    localization?: LanguageSelectorOptions[];
    pageType?: string;
}

export const DefaultHeaderMobileMyAccountNavigation: React.FC<DefaultHeaderMobileMyAccountNavigationProps> = (
    props: DefaultHeaderMobileMyAccountNavigationProps
) => {
    const {
        dispatch: dispatchContext,
        myAccountNavOpen
    } = useDefaultHeaderMobileLoginContext();
    const data = useAccountBasePage();
    const adminHeaderData = useAdminHeader();
    const dispatch = useDispatch();
    const headerData = useHeader();

    if (!headerData || !data) return null;

    const {logoutLinkText} = headerData;

    const {loggedIn, userRole} = useAppSelector((state: RootState) => ({
        loggedIn: state.authentification.loggedIn,
        userRole: state.userSlice.user?.role?.type
    }));

    useEffect(() => {
        if (loggedIn !== undefined && !loggedIn) {
            dispatchContext({
                type: DefaultHeaderMobileLoginFormContextEnum.RESET
            });
        }
    }, [loggedIn]);

    return (
        <HeaderMobileNavigation
            cancelButtonOffset={0}
            className={styles['default-header-mobile-my-account-navigation']}
            open={myAccountNavOpen}
            localizations={props.localization}
            pageType={props.pageType}
            userRole={userRole}
            adminNavLinks={adminHeaderData.navLinks}
            onCancelClick={() =>
                dispatchContext({
                    type:
                    DefaultHeaderMobileLoginFormContextEnum.TOGGLEMYACCOUNTNAVIGATION
                })
            }
            navLinks={((EnumToLanguageMapper.accountRoutesMapping(
                data
            ) as unknown) as {
                label: string;
                value: RoutePath;
                external?: boolean;
            }[]).map(({value, ...data}) => ({
                ...data,
                value: (RoutePath.MY_ACCOUNT + value) as RoutePath
            }))}
        >
            <Spacer height="20px"/>
            <Seperator width="100%" height="1px"/>
            <div className={styles['logout-button-container']}>
                <Link
                    className={styles['logout-button']}
                    href={''}
                    onClick={() => {
                        dispatch(logout());
                    }}
                >
                    {logoutLinkText ?? ''}
                </Link>
            </div>
        </HeaderMobileNavigation>
    );
};
