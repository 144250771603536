
import {faTrash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useMyOan} from '../../../../api/hooks/useMyOan.hook';
import {useTypoContent} from '../../../../api/hooks/useTypoContent.hook';
import {CommentRemoveData} from '../../../../api/models/typo/form-data/comment-remove-data';
import {TypoElementComment} from '../../../../api/models/typo/typo-element-comments';
import { User } from '../../../../api/models/user';
import {TypoService} from '../../../../api/services/typo.service';
import creatoki from '../../../../assets/creatoki.png';
import {removeComment} from '../../../../redux/slices/comment.slice';
import {fetchMyOanUser} from '../../../../redux/slices/my-oan.slice';
import {useAppSelector} from '../../../../utils/_hooks/hooks';
import {Typography} from '../../../_shared/typography/typography';
import styles from './comment.module.scss';

const Comment = (props: TypoElementComment): JSX.Element => {

    const { uid, username, replyid, parent, pid, comment, crdate, inverted } = props;

    const dispatch = useDispatch();
    const typoService = new TypoService(useTypoContent());
    const [isRemoved, setIsRemoved] = useState(false);
    const {user} = useAppSelector(state => ({
        user: state.userSlice.user
    }));
    const [commentOwner, setCommentOwner] = useState<User>();

    useEffect(() => {
        (async () => {
            const resp: any = await dispatch(fetchMyOanUser(username));
            if (resp.meta?.requestStatus === 'fulfilled' && resp.payload) {
                setCommentOwner(resp.payload);
            }
        })();
    }, []);

    const deleteComment = () => {
        if (confirm(typoService.getLabel('comments', 'comment-confirm-delete'))) {
            (async () => {
                const resp: any = await dispatch(
                    removeComment({
                        uid: uid,
                        parent: parent,
                        username: username,
                        pid: pid
                    } as CommentRemoveData)
                );

                if (resp.payload.success && resp.payload.data) {
                    setIsRemoved(true);
                }
            })();
        }
    };

    const creationDate = (crdate: number) => {
        const newDate = new Date(crdate * 1000);
        return new Intl.DateTimeFormat('de-DE', {
            year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'
        }).format(newDate).replace(', ',' - ');
    }

    const isMyComment = user && commentOwner?.username === user.username;
    console.log(comment)

    return (
        <div
            id={`u${uid}`}
            data-replyid={replyid}
            className={`${styles['comment-section']} ${inverted ? styles['inverted'] : ''}`}
        >
            {!isRemoved ? (
                <>
                    <div className={styles['avatar']}>
                        <img src={commentOwner?.profileImageUrl ? commentOwner.profileImageUrl : creatoki.src} alt="Avatar" />
                    </div>
                    <div className={styles['comment']}>
                        <div className={styles['text']}>
                            <Typography
                                inverted={inverted}
                            >
                                <div style={{whiteSpace: 'pre-line'}}>
                                    {comment}
                                </div>
                            </Typography>
                        </div>
                        <div className={styles['controls']}>
                            {isMyComment && (
                                <div className={styles['control-delete']} onClick={deleteComment}>
                                    <Typography
                                        inverted={inverted}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> {typoService.getLabel('comments', 'comment-delete')}
                                    </Typography>
                                </div>
                            )}
                            <div className={styles['control-crdate']}>
                                <Typography
                                    inverted={inverted}
                                >
                                    {commentOwner?.username} {typoService.getLabel('comments', 'comment-crdate')} {creationDate(crdate)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles['removed']}>
                    <Typography
                        inverted={inverted}
                    >
                        {typoService.getLabel('comments', 'comment-message-removed')}
                    </Typography>
                </div>
            )}
        </div>
    );
};

Comment.defaultProps = {};

Comment.dataHooks = [
    useMyOan
];

export { Comment };
