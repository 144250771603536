import React from 'react';

import styles from './header-background.module.scss';

/**
 * An HeaderBackground React Component.
 * @author
 * @version 0.1
 */
export const HeaderBackground: React.FC = () => {
    return (
        <div className={styles['header-background']}/>
    );
};
