import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import EthSVG from '../../../../assets/icons/eth.svg';
import FlipSVG from '../../../../assets/icons/flip.svg';
import PolygonSVG from '../../../../assets/icons/polygon.svg';
import {Blockchain} from '../../../../utils/_enums/blockchain.enum';
import {Currency} from '../../../../utils/_enums/currency.enum';
import {TypographyVariantsEnum} from '../../../../utils/_enums/typeography-variants.enum';
import {generateUrlToDetailedPage} from '../../../../utils/_helper/generate-url-to-details-page-helper';
import {getLocaleEthPrice, getLocalePrice} from '../../../../utils/_helper/locale-helper';
import { formatDate, isDateInFuture, toCountdown } from '../../../../utils/_helper/time-helper';
import { useTimer } from '../../../../utils/_hooks/useTimer.hook';
import {UniversalTeaserBuyProps} from '../../../../utils/_models/universal-teaser-props';
import NavButton from '../../button/nav-button/nav-button';
import ToolTip from '../../tool-tip/tool-tip';
import {Typography} from '../../typography/typography';
import CardTitle from '../card-title/card-title';
import styles from './card-footer.module.scss';

interface CardFooterProps extends UniversalTeaserBuyProps {
    className?: string;
    onSwap: () => void;
    isFront: boolean;
}



const CardFooter = (props: CardFooterProps) => {

    const renderBlockchainIcon = () => {
        if (props.blockchain === Blockchain.ETHEREUM) {
            return <ToolTip toolTip={props.blockchainStrings?.ethereum as string}><EthSVG/></ToolTip>
        } else if (props.blockchain === Blockchain.POLYGON) {
            return <ToolTip toolTip={props.blockchainStrings?.polygon as string}><PolygonSVG/></ToolTip>
        }
    }

    const getPrimaryPriceAsString = () => {
        if (props.currency === Currency.ETH) {
            return getLocaleEthPrice(props.ethPrice) + 'Ξ';
        } else {
            return getLocalePrice(props.fiatPrice) + '€';
        }
    }

    const getSecondaryPriceAsString = () => {
        let price;
        if (props.currency === Currency.ETH) {
            price = getLocalePrice(props.fiatPrice) + '€';
        } else {
            price = getLocaleEthPrice(props.ethPrice) + 'Ξ';
        }
        return `(${price})`
    }

    

    const renderCountdown = () => {
        const salesFromDate = new Date(props.salesFrom ?? '')
        const startTimer = useTimer(salesFromDate.getTime());
        const countdownCheck = startTimer.timer < 60*60*24;
        const countdown = toCountdown(startTimer.timer);
        const onlyDateString = formatDate(salesFromDate)
        
        return countdownCheck ? countdown : onlyDateString
    }

    return (
        <div
            className={`${styles['footer']} ${props.inverted ? styles['inverted'] : ''} ${props.className ? props.className : ''}`}>
            {
                props.isFront
                    ? <>
                        <CardTitle {...props}/>
                        <div className={styles['blockchain-icon']}>
                            {renderBlockchainIcon()}
                        </div>
                    </>
                    : <div className={styles['separator']}/>
            }
            {
                props.canBuy
                    ? <div className={styles['price-wrapper']}>
                        <Typography variant={TypographyVariantsEnum.PARAGRAPH} className={styles['price-prefix']}>
                            {props.generalStrings?.fromLowest}
                        </Typography>
                        <Typography variant={TypographyVariantsEnum.HEADING6} className={styles['price']}>
                            {getPrimaryPriceAsString()}
                        </Typography>
                        <Typography variant={TypographyVariantsEnum.PARAGRAPH} className={styles['secondary-price']}>
                            {getSecondaryPriceAsString()}
                        </Typography>
                        <div className={styles['buy-button']}>
                            <NavButton onClick={props.onBuy}>
                                {props.generalStrings?.buy as string}
                            </NavButton>
                        </div>
                    </div>
                    : <div className={styles['price-wrapper']}>
                        {props.productName && props.title && props.productId && props.variantId
                            ? 
                                <span className={styles['countdown']}>
                                     {isDateInFuture(props?.salesFrom) ?
                                        <>
                                            <Typography variant={TypographyVariantsEnum.HEADING6} className={styles['countdown']}>
                                                <span className={styles['clock']}>
                                                    <FontAwesomeIcon icon={['far', 'clock']}/>
                                                </span>
                                                {renderCountdown()}
                                            </Typography>
                                        </> :
                                        <NavButton
                                            href={generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId)}>
                                            {props.generalStrings?.options as string}
                                        </NavButton>
                                     }
                                </span>
                            : undefined}
                            
                    </div>
            }
            <FlipSVG onClick={props.onSwap} className={styles['swap-icon']} inverted={props.inverted}/>
        </div>
    );
};

export default CardFooter;
