import React from 'react';

import {TypoElementNewsListitem} from '../../../../../api/models/typo/typo-element-news-listitem';
import {LinkBase} from '../../../../_shared/link/link-base/link-base';
import {getMediaType, Media} from '../../../../_shared/media/media';
import {Headline} from '../../../_shared/headline/headline';
import styles from './podcast_template.module.scss';

const Podcast_Template = (props: TypoElementNewsListitem): JSX.Element => {

    const { uid, relatedLinks, title, teaser, bodytext, falRelatedFiles, datetime, slug, view, inverted } = props;
    const linkLength = relatedLinks ? relatedLinks.length : 0;
    const mainImage = falRelatedFiles ? falRelatedFiles[0] : undefined;
    const image = mainImage?.images.defaultImage;
    const imageProps = mainImage?.properties;
    const imageUrl = image?.publicUrl;
    const imageType = imageProps?.mimetype;

    return (
        <div
            className={`${styles['podcast_template']} ${styles['news-' + view]}`}
            key={'podcast_template-' + uid}
        >
            {String(view) === 'list' ? (
                <>
                    <div
                        className={styles['right-wrapper']}
                    >
                        <Headline
                            {...props}
                            header={title}
                            subheader={datetime}
                            bodytext={teaser}
                            headerLink={slug}
                            variant="teaser"
                        />
                        {relatedLinks && (
                            <div className={styles['buttons']}>
                                {relatedLinks.map((args, i) => (
                                    <div key={'news-' + uid + '-link-' + args.id}>
                                        <LinkBase
                                            href={args.uri ?? ''}
                                            external={true}
                                            locale={false}
                                        >
                                            {args.title}
                                        </LinkBase>
                                        {i < linkLength - 1 ? '|' : ''}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles['left-wrapper-img']}>
                        <Media mediaId={uid} contentType={getMediaType(imageType)} src={imageUrl}
                               inverted={inverted}
                               caption={imageProps?.description as string}
                               title={imageProps?.title as string}
                               redirectUrl={imageProps?.link as string}
                               alt={imageProps?.alternative as string} />
                    </div>
                </>
            ) : (
                <>
                    <Headline
                        {...props}
                        header={title}
                        subheader={datetime}
                        bodytext=""
                        variant="teaser"
                    />
                    <br />
                    <div className="bodytext-container"
                         dangerouslySetInnerHTML={{
                             __html: bodytext as string
                         }}
                    >
                    </div>
                    {relatedLinks && (
                        <div className={styles['buttons']}>
                            {relatedLinks.map((args, i) => (
                                <div key={'news-' + uid + '-link-' + args.id}>
                                    <LinkBase
                                        href={args.uri ?? ''}
                                        external={true}
                                        locale={false}
                                    >
                                        {args.title}
                                    </LinkBase>
                                    {i < linkLength - 1 ? '|' : ''}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export { Podcast_Template };
