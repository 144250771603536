import React from 'react';

import {Typo} from '../../api/models/typo';
import {TypoAppearance} from '../../api/models/typo.options';
import {TypoFieldButtons} from '../../api/models/typo/field/typo-field-buttons';
import {TypoElementAccord} from '../../api/models/typo/typo-element-accord';
import {TypoElementAnimatedStage} from '../../api/models/typo/typo-element-animated_stage';
import {TypoElementBullets} from '../../api/models/typo/typo-element-bullets';
import {TypoElementComments} from '../../api/models/typo/typo-element-comments';
import {TypoElementCountdown} from '../../api/models/typo/typo-element-countdown';
import {TypoElementDiv} from '../../api/models/typo/typo-element-div';
import {TypoElementFileLinks} from '../../api/models/typo/typo-element-file-links';
import {TypoElementHeader} from '../../api/models/typo/typo-element-header';
import {TypoElementHtml} from '../../api/models/typo/typo-element-html';
import {TypoElementImage} from '../../api/models/typo/typo-element-image';
import {TypoElementMenu_element} from '../../api/models/typo/typo-element-menu_element';
import {TypoElementMp3} from '../../api/models/typo/typo-element-mp3';
import {TypoElementMulti} from '../../api/models/typo/typo-element-multi';
import {TypoElementNews} from '../../api/models/typo/typo-element-news';
import {TypoElementParallax} from '../../api/models/typo/typo-element-parallax';
import {TypoElementPdf} from '../../api/models/typo/typo-element-pdf';
import {TypoElementQuote} from '../../api/models/typo/typo-element-quote';
import {TypoElementShortcut} from '../../api/models/typo/typo-element-shortcut';
import {TypoElementStage} from '../../api/models/typo/typo-element-stage';
import {TypoElementTable} from '../../api/models/typo/typo-element-table';
import {TypoElementText} from '../../api/models/typo/typo-element-text';
import {TypoElementTextimg} from '../../api/models/typo/typo-element-textimg';
import {TypoElementTextmedia} from '../../api/models/typo/typo-element-textmedia';
import {TypoElementTextpic} from '../../api/models/typo/typo-element-textpic';
import {TypoContentElement} from '../../api/models/typo-content-element';
import {TypoGallery} from '../../api/models/typo-gallery';
import {TypoMedia} from '../../api/models/typo-media';
import {Link} from '../../components/typo/_shared/link/link';
import {Accord} from '../../components/typo/accord/accord';
import {AnimatedStage} from '../../components/typo/animated_stage/animated_stage';
import {TypoBullets} from '../../components/typo/bullets/bullets';
import {Comments} from '../../components/typo/comments/comments';
import {Countdown} from '../../components/typo/countdown/countdown';
import {DivTypo} from '../../components/typo/div/div';
import {TypoFileLinks} from '../../components/typo/file-links/file-links';
import {Header} from '../../components/typo/header/header';
import {HtmlTypo} from '../../components/typo/html/html';
import {Iframe} from '../../components/typo/iframe/iframe';
import {Image} from '../../components/typo/image/image';
import {Menu_abstract} from '../../components/typo/menu_abstract/menu_abstract';
import {Menu_categorized_content} from '../../components/typo/menu_categorized_content/menu_categorized_content';
import {Menu_categorized_pages} from '../../components/typo/menu_categorized_pages/menu_categorized_pages';
import {Menu_pages} from '../../components/typo/menu_pages/menu_pages';
import {Menu_related_pages} from '../../components/typo/menu_related_pages/menu_related_pages';
import {Menu_sitemap} from '../../components/typo/menu_sitemap/menu_sitemap';
import {Menu_sitemap_pages} from '../../components/typo/menu_sitemap_pages/menu_sitemap_pages';
import {Menu_subpages} from '../../components/typo/menu_subpages/menu_subpages';
import {Mp3} from '../../components/typo/mp3/mp3';
import {TypoMulti} from '../../components/typo/multi/multi';
import {NewsList} from '../../components/typo/news-list/news-list';
import styles from '../../components/typo/news-list/templates/academy/academy_template.module.scss';
import {Parallax} from '../../components/typo/parallax/parallax';
import {Pdf} from '../../components/typo/pdf/pdf';
import {Quote} from '../../components/typo/quote/quote';
import {Stage} from '../../components/typo/stage/stage';
import {
    Props as StageImageButtonTokenProps
} from '../../components/typo/stage/stage-image-button-token/stage-image-button-token';
import {TypoTable} from '../../components/typo/table/table';
import {Text} from '../../components/typo/text/text';
import {Textimg} from '../../components/typo/textimg/textimg';
import {Textmedia} from '../../components/typo/textmedia/textmedia';
import {Textpic} from '../../components/typo/textpic/textpic';
import {ButtonTypesEnum} from '../_enums/button-types.enum';
import {ButtonVariantsEnum} from '../_enums/button-variants.enum';

/**
 * Typo3 Content Helper
 */
class TypoContentHelper {

    /**
     * Complete typo3 json response
     *
     * @private
     */
    private readonly json: Typo;

    /**
     * Has painted the first element
     *
     * @private
     */
    private hasFirstElement = false;

    /**
     * Has painted the first element
     *
     * @private
     */
    private hasPulledNews = undefined;

    /**
     * Bans element ids from being repainted again
     *
     * @private
     */
    private bannedIds = [];

    /**
     * @param typoData  useTypoContent() Hook
     */
    constructor(typoData: Typo) {
        this.json = typoData;
    }

    /**
     * Converts Typo3 spacing to X em or X px values
     *
     * @param appearance
     * @private
     */
    private static getAppearance(appearance: TypoAppearance): TypoAppearance {
        return appearance;
    }

    /**
     * Get the academy news content element for the stage header on the news detail page
     */
    public getNewsContent(): TypoElementNews | undefined {
        if (this.hasPulledNews) {
            return this.hasPulledNews;
        }

        const pageContent = this.json?.content?.colPos20 as TypoContentElement[];
        if (!pageContent) {
            return undefined;
        }

        let newsItemCE = undefined;
        pageContent.map((item) => {
            if (item.type === 'news_pi1') {
                const newsItem = item as TypoElementNews;
                if ((newsItem.news.settings.templateLayout === 'academy' ||
                    newsItem.news.settings.templateLayout === '') &&
                    newsItem.news.settings.action === 'detail') {
                    newsItemCE = newsItem;
                }
            }
        })
        this.hasPulledNews = newsItemCE;

        return newsItemCE;
    }

    /**
     * Creates react components from typo3 content elements
     * @param getFirstElementStage  Gets the first element and removes it from being painted again
     *                              because it will be used in the 100% with container
     *
     * @param data
     * @param pid
     */
    public createContentElement(data: TypoContentElement, getFirstElementStage = false, pid = 0): JSX.Element | void {

        if (!data || typeof data.id === undefined || !data.type) {
            return;
        }

        const id = data.id;
        const appearance = TypoContentHelper.getAppearance(data.appearance as TypoAppearance);
        let content;

        if (!id || this.bannedIds.includes(id as never)) {
            return;
        }

        const hasFirstElement = this.hasFirstElement;
        const newsCE = this.getNewsContent();
        this.hasFirstElement = true;

        if (getFirstElementStage && !hasFirstElement && (data.type == 'stage' || data.type == 'animated_stage')) {
            this.bannedIds.push(id as never);
        } else if (getFirstElementStage && !hasFirstElement && typeof newsCE !== 'undefined') {
            const newsDetail = newsCE?.news?.detail ?? undefined;
            if (newsDetail) {
                this.bannedIds.push(id as never);
                content = data.content as TypoElementParallax;
                const image = content && content.gallery ? content.gallery[0] : undefined;
                const firstImage = newsDetail.media !== undefined && newsDetail.media[0] !== undefined ? newsDetail.media[0] : undefined;
                let imageUrl = firstImage?.images?.defaultImage?.publicUrl ?? '';
                if (!imageUrl && image?.publicUrl) {
                    imageUrl = image.publicUrl
                }
                return (
                    <Parallax
                        id={content.id}
                        className={content.className}
                        inverted={!newsDetail.isTopNews}
                        fullwidth={this.setFullwidth(content)}
                        withTextBackground={content.withTextBackground}
                        containerSize={content.containerSize}
                        height={content.height}
                        imageUrl={imageUrl}
                        imageAlt={'Background'}
                        scrollStrength={content.scrollStrength}
                        style={{marginTop: '-181px'}}
                        blur={content.blur}
                    >
                        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                            <div className={`${styles['card-categories']} ${styles['spacing']}`} style={{justifyContent: 'center'}}>
                                {newsDetail.categories.map((item, i) => {
                                    if (i >= 3) {
                                        return;
                                    }

                                    return (
                                        <Link href={`${newsCE?.news?.settings?.returnUrl}?catid=${item.id}`} key={`cat-${item.id}`} class={styles['link']}>
                                            <div className={styles['category']}>
                                                {item.title}
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                            <h1 style={{textAlign: 'center', width: '100%'}}>{newsDetail.title}</h1>
                            <img className={styles['author-image']}
                                 style={{display: 'block', width: '80px', margin: '20px auto 0 auto'}}
                                 src="/favicon.svg"
                                 alt="Profile"
                            />
                            <div className={styles['author-name']} style={{fontWeight: 'bold', flex: '0 0 100%', textAlign: 'center'}}>
                                {newsDetail.author?.author ?? ''}
                            </div>
                            <div className={styles['created']} style={{marginTop: '8px', flex: '0 0 100%', textAlign: 'center'}}>
                                {newsDetail.datetime}
                            </div>
                        </div>
                    </Parallax>
                )
            }
        } else if (getFirstElementStage && data.type != 'stage'  && data.type != 'animated_stage') {
            return;
        }

        switch (data.type) {
            case 'stage': {
                content = data.content as TypoElementStage;
                content.className = '';
                const buttons = (content.buttons ? this.getAllButtons(content.buttons as TypoFieldButtons[]) : []) as TypoFieldButtons[];
                const decButton = content.icon !== undefined && content.icon[0] !== undefined ?
                    { image: content.icon[0].publicUrl, shadow: true } as StageImageButtonTokenProps
                    : {};
                const images = this.getAllImagesFromGallery(content.gallery as TypoGallery);
                const img = images !== undefined ? images[0] : {} as TypoMedia;
                const imgProps = img.properties ?? [];
                const backgrounds = imgProps && imgProps.dimensions ? {
                    large: {
                        ext: '.' + imgProps.extension,
                        hash: 'large_typo_' + id,
                        height: imgProps.dimensions.height,
                        mime: imgProps.mimeType,
                        name: imgProps.filename,
                        path: null,
                        size: parseFloat(imgProps.size),
                        url: img.publicUrl,
                        width: imgProps.dimensions.width
                    },
                } : {};

                const image = this.getAllImagesFromGallery(content.gallery);
                const imageUrl = image !== undefined && image[0] !== undefined ? image[0].publicUrl : '';

                return (
                    <Stage
                        id={id}
                        backgrounds={backgrounds}
                        imageUrl={imageUrl}
                        buttons={buttons}
                        decButton={decButton}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'faq': {
                content = data.content as TypoElementAccord;
                return (
                    <Accord
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'textimg': {
                content = data.content as TypoElementTextimg;
                return (
                    <Textimg
                        id={id}
                        images={this.getAllImagesFromGallery(content.gallery)}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'textpic': {
                content = data.content as TypoElementTextpic;
                return (
                    <Textpic
                        id={id}
                        images={this.getAllImagesFromGallery(content.gallery)}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'textmedia': {
                content = data.content as TypoElementTextmedia;
                return (
                    <Textmedia
                        id={id}
                        images={this.getAllImagesFromGallery(content.gallery)}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'image': {
                content = data.content as TypoElementImage;
                return (
                    <Image
                        id={id}
                        images={this.getAllImagesFromGallery(content.gallery)}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'header': {
                content = data.content as TypoElementHeader;
                return (
                    <Header
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'text': {
                content = data.content as TypoElementText;
                return (
                    <Text
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_sitemap': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_sitemap
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_pages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_pages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_categorized_content': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_categorized_content
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_sitemap_pages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_sitemap_pages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_section_pages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_related_pages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_related_pages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_related_pages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_abstract': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_abstract
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_categorized_pages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_categorized_pages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'menu_subpages': {
                content = data.content as TypoElementMenu_element;
                return (
                    <Menu_subpages
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                );
            }
            case 'table': {
                content = data.content as TypoElementTable;
                return (
                    <TypoTable
                        {...content}
                        id={id}
                        bodytext={content.bodytext ?? []}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                    />
                );
            }
            case 'bullets': {
                content = data.content as TypoElementBullets;
                return (
                    <TypoBullets
                        {...content}
                        id={id}
                        bodytext={content.bodytext ?? []}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                    />
                );
            }
            case 'uploads': {
                content = data.content as TypoElementFileLinks;
                return (
                    <TypoFileLinks
                        {...content}
                        id={id}
                        media={content.media ?? []}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                    />
                );
            }
            case 'multi': {
                content = data.content as TypoElementMulti;
                return (
                    <TypoMulti
                        {...content}
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                    />
                );
            }
            case 'news_pi1': {
                content = data as TypoElementNews;
                if (content.news.settings.templateLayout == '') {
                    content.news.settings.templateLayout = 'academy';
                }

                return (
                    <NewsList
                        {...content}
                        id={id as number}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                    />
                )
            }
            case 'html': {
                content = data.content as TypoElementHtml;
                return (
                    <HtmlTypo
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                )
            }
            case 'iframe': {
                content = data.content as TypoElementHtml;
                return (
                    <Iframe
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                )
            }
            case 'div': {
                content = data.content as TypoElementDiv;
                return (
                    <DivTypo
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                )
            }
            case 'shortcut': {
                content = data.content as TypoElementShortcut;
                return (
                    <>
                        {
                            data.content.shortcut.map((content: TypoContentElement) => this.createContentElement(content))
                        }
                    </>
                )
            }
            case 'parallax': {
                content = data.content as TypoElementParallax;
                const image = content && content.gallery ? content.gallery[0] : {};
                const imageUrl = image?.publicUrl;
                const imageAlt = image?.properties?.alternative;

                return (
                    <Parallax
                        id={content.id}
                        className={content.className}
                        inverted={content.contentStyle === 'inverted'}
                        fullwidth={this.setFullwidth(content)}
                        withTextBackground={content.withTextBackground}
                        containerSize={content.containerSize}
                        height={content.height}
                        imageUrl={imageUrl}
                        imageAlt={imageAlt}
                        scrollStrength={content.scrollStrength}
                        blur={content.blur}
                    >
                        {content.children}
                    </Parallax>
                )
            }
            case 'animated_stage': {
                content = data.content as TypoElementAnimatedStage;
                return (
                    <AnimatedStage
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                )
            }
            case 'pdf': {
                content = data.content as TypoElementPdf;
                return (
                    <Pdf
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        fullwidth={this.setFullwidth(content)}
                        {...content}
                    />
                )
            }
            case 'countdown': {
                content = data.content as TypoElementCountdown;
                return (
                    <Countdown
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        {...content}
                    />
                )
            }
            case 'quote': {
                content = data.content as TypoElementQuote;
                return (
                    <Quote
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        {...content}
                    />
                );
            }
            case 'mp3': {
                content = data.content as TypoElementMp3;
                return (
                    <Mp3
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        {...content}
                    />
                );
            }
            case 'comments': {
                content = data.content as TypoElementComments;
                return (
                    <Comments
                        id={id}
                        appearance={appearance}
                        inverted={this.setInverted(content)}
                        {...content}
                        pid={pid}
                    />
                );
            }

            default:
                console.log('Typo3 CElement has not been found: ' + data.type);
                break;
        }
    }

    /**
     * Sets Inverted Option for CElements
     *
     * @param content
     * @private
     */
    private setInverted(content: any): boolean {
        let style = '';
        if (content.contentStyle !== undefined) {
            style = content.contentStyle;
        } else if (content.style !== undefined) {
            style = content.style;
        } else {
            // Content Element has no "Inverted" Option so use Page Option
            return this.json.creatokia.style === 'inverted';
        }

        return style === 'inverted' ||
            (style === 'default' && this.json.creatokia.style == 'inverted');
    }

    /**
     * Sets Fullwidth Option for CElements
     *
     * @param content
     * @private
     */
    private setFullwidth(content: any): boolean {
        let style = '';
        if (content.contentStyle !== undefined) {
            style = content.contentStyle;
        } else if (content.style !== undefined) {
            style = content.style;
        } else {
            // Content Element has no "Fullwidth" Option so use Page Option
            return this.json.creatokia.page === 'fullwidth';
        }

        return style === 'fullwidth' ||
            (style === 'default' && this.json.creatokia.page == 'fullwidth');
    }

    /**
     * Get all images from a gallery content type
     *
     * @param gallery
     * @private
     */
    private getAllImagesFromGallery(gallery: TypoGallery): TypoMedia[] | undefined {

        if (gallery === undefined) {
            return undefined;
        }

        const images: TypoMedia[] = [];
        Object.keys(gallery.rows).map((key: string) => {
            const obj = gallery.rows[key];
            Object.keys(obj.columns).map((k: string) => {
                images.push(obj.columns[k]);
            });
        });
        return images;
    }

    /**
     * Get all buttons from Content Element
     *
     * @private
     * @param contentButtons
     */
    private getAllButtons(contentButtons: TypoFieldButtons[]): unknown[] {

        if (!contentButtons) {
            return [];
        }

        const buttons: unknown[] = [];
        contentButtons.map((data: TypoFieldButtons) => {

            const icon = data.icon !== undefined && data.icon[0] !== undefined ? {
                element: data.icon[0].publicUrl,
                align: data.icon_align,
                flip: data.icon_flip,
            } : null;

            buttons.push({
                variant: data.variant as ButtonVariantsEnum,
                className: data.uri.class,
                children: data.title,
                disabled: data.disabled,
                type: data.type as ButtonTypesEnum,
                icon: icon,
                inverted: this.setInverted(data),
                buttonUrl: data.uri.href,
                external: data.uri.target === '_blank',
                pending: data.pending
            });
        });

        return buttons;
    }
}

export { TypoContentHelper };