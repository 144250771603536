import { AxiosPromise } from 'axios';

import {typo} from '../../utils/_config/axios';
import {CommentFormData} from '../models/typo/form-data/comment-form-data';
import {CommentRemoveData} from '../models/typo/form-data/comment-remove-data';

export default class CommentService {
    public sendCommentForm(
        data: CommentFormData
    ): AxiosPromise<{ valid: boolean }> {
        return typo.post('/en/typo3/api/comment/add', data);
    }

    public sendRemoveComment(
        data: CommentRemoveData
    ): AxiosPromise<{ valid: boolean }> {
        return typo.post('/en/typo3/api/comment/remove', data);
    }
}
