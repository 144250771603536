import { GetServerSidePropsContext } from 'next';
import { createDataHook } from 'next-data-hooks';
import { ParsedUrlQuery } from 'querystring';

export const useContextSsr = createDataHook('host-ssr', async (context) => {
    const localeRes = !context?.locale || context?.locale === 'catchAll' ? 'en' : context?.locale;
    return {
        host:
            (context as GetServerSidePropsContext<ParsedUrlQuery>)?.req?.headers
                ?.host ?? '',
        url:
            (context as GetServerSidePropsContext<ParsedUrlQuery>)?.req?.url ??
            '',
        locale: localeRes
    };
});
