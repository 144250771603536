import React from 'react';

export const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path 
             d="M15.034,17.75l-5.17-4.865.422-.525a9.026,9.026,0,0,0,1.863-5.182V7.166c.026-.551.028-.7.028-.845,0-4.1-2.079-4.583-3.142-4.548A2.841,2.841,0,0,0,6.758,2.816a5.483,5.483,0,0,0-.789,3.4l.008.449.008.248A13.361,13.361,0,0,0,7.419,12.36l.334.525L2.7,17.75H0l5.346-4.865A18.527,18.527,0,0,1,3.917,7.021l0-.058-.01-.3L3.9,6.182C3.9.823,6.729-.25,9.036-.25a4.569,4.569,0,0,1,3.859,1.738,7.763,7.763,0,0,1,1.25,4.746c0,.2,0,.392-.03.932a11.688,11.688,0,0,1-1.625,5.72c2.154,2.1,5.475,4.779,5.513,4.808Z" 
             fill="#fff"
             transform="translate(-0.003 0.25)"/>
        </svg>
    )
}
