import React from 'react';

import styles from './error-message.module.scss';

export interface Props {
    children?: string;
    withoutGlobalClass?: boolean;
    className?: string;
}

export const ErrorMessage: React.FC<Props> = ({
    children,
    withoutGlobalClass,
    className
}) => {
    return (
        <div
            data-testid="error-message"
            className={`${withoutGlobalClass ? '' : 'error-message'} ${
                styles['error-message-wrapper']
            } ${className ?? ''}`}
        >
            <span className={styles['error-message']}>{children}</span>
        </div>
    );
};

ErrorMessage.defaultProps = { withoutGlobalClass: false };
