import React from 'react';

import {TypoLink} from '../../../../api/models/typo-link';

const Link = (props: TypoLink): JSX.Element => {

    const content = props.linkText === undefined || props.linkText === null || props.linkText == '' ? props.children : props.linkText;
    props.class === null ? props.class = undefined : 0;
    props.title === null ? props.title = undefined : 0;
    props.target === null ? props.target = undefined : 0;

    return (
        <a href={props.href} className={props.class} title={props.title} target={props.target}>{content}</a>
    );
};

Link.defaultProps = {};

export { Link };