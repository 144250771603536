import 'animate.css';

import React from 'react';

import { TypoElementStage } from '../../../api/models/typo/typo-element-stage';
import CreatokenSVGComponent from '../../../assets/icons/creatoken.svg';
import { Alignment } from '../../../utils/_enums/alignment.enum';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { ButtonProps } from '../../_shared/button/button';
import { Headline } from '../_shared/headline/headline';
import { TypoButtons } from '../_shared/typo_buttons/typo_buttons';
import styles from './stage.module.scss';
import {
    Props as StageImageButtonTokenProps,
    StageImageButtonToken
} from './stage-image-button-token/stage-image-button-token';

const Stage = (props: TypoElementStage): JSX.Element => {
    const { id, inverted, fullwidth, header, logo, backgrounds, buttons, imageUrl, animationClass, animationSpeedClass } = props;
    let { decButton } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const verticalOrientation = props.contentVertical.toUpperCase() as 'TOP' | 'CENTER' | 'BOTTOM';
    const size = props.contentSize.charAt(0).toUpperCase() + props.contentSize.slice(1) as 'Large' | 'Normal' | 'Small';
    const orientation = props.contentHorizontal.toUpperCase() as 'LEFT' | 'RIGHT' | 'CENTER';
    const textDirection = 'horizontal' as Alignment;

    if (!decButton || typeof decButton != 'object' || Object.keys(decButton).length <= 0) {
        decButton = Stage.defaultProps.decButton as unknown as ButtonProps;
    }

    return (
        <div
            id={`c${id}`}
            data-testid="stage"
            className={`
                ${styles['stage']} 
                ${styles[size as string]} 
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
            `}
        >
            <div
                className={`${styles['stage-background']} ${backgrounds || imageUrl ? '' : styles['default-background']}}`}
                style={backgrounds
                    ? { backgroundImage: `url(${imageUrl ? imageUrl : ''})` }
                    : undefined}
            >
                <div
                    className={`${styles[orientation as string]} ${
                        inverted ? styles['inverted'] : ''
                    } ${styles[`vert-${verticalOrientation?.toLowerCase()}`]}`}
                >
                    {logo}
                    <div
                        className={
                            textDirection === Alignment.HORIZONTAL
                                ? styles['horizontal-text-alignment']
                                : ''
                        }
                    >
                        {header && (
                            <div className={`${styles['title']}`}>
                                <Headline
                                    {...props}
                                    headerClass={`
                                    ${animationClass !== '' ? `animate__animated animate__${animationClass}` : ''}
                                     ${animationSpeedClass !== '' ? `animate__${animationSpeedClass}` : ''}
                                    `}
                                    subHeaderClass={`
                                    ${animationClass !== '' ? `animate__animated animate__${animationClass}` : ''}
                                     ${animationSpeedClass !== '' ? `animate__${animationSpeedClass}` : ''}
                                    `}
                                    variantHeader={'heading1' as TypographyVariantsEnum}
                                    variantSubHeader={'introtext' as TypographyVariantsEnum}
                                />
                            </div>
                        )}
                        <div
                            className={
                                textDirection === Alignment.HORIZONTAL
                                    ? styles['flex-container']
                                    : ''
                            }
                        />
                    </div>
                    <TypoButtons inverted={inverted} buttons={buttons} />
                </div>
                <div>
                    {(decButton as StageImageButtonTokenProps)?.image ? (
                        <div className={styles.decButtonCustomContainer}>
                            <StageImageButtonToken
                                {...(decButton as StageImageButtonTokenProps)}
                                shadow
                            />
                        </div>
                    ) : (
                        <div className={styles.decButtonContainer}>
                            {decButton?.icon?.element}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Stage.defaultProps = {
    orientation: 'CENTER',
    inverted: false,
    size: 'Large',
    decButton: {
        icon: {
            element: <CreatokenSVGComponent />,
            align: 'right'
        }
    },
    verticalOrientation: 'CENTER'
};

export { Stage };