// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { TypoElementFileLinks } from '../../../api/models/typo/typo-element-file-links';
import { Media } from '../../_shared/media/media';
import { Headline } from '../_shared/headline/headline';
import styles from '../multi/multi.module.scss';

const TypoFileLinks = (props: TypoElementFileLinks): JSX.Element => {
    const [mediaDataArray, setMediaDataArray] = useState([]);
    const { id, inverted, media, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    useEffect(() => {
        if (media !== undefined && media.length !== 0) {
            setMediaDataArray(media);
        }
    }, [props.media]);

    return (
        <div id={`c${id}`} className={`
            ${'spaceBefore' + appearance.spaceBefore} 
            ${'spaceAfter' + appearance.spaceAfter} 
            ${inverted ? styles['inverted'] : ''}
            ${fullwidth ? styles['fullwidth'] : ''}
            ${wrapper !== undefined ? styles[wrapper] : ''}
          `}>
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            {
                mediaDataArray.map((mediaData, i) => {
                    const properties = mediaData.properties;

                    return (
                        <Media
                            key={`uploads-${i}`}
                            inverted={inverted}
                            mediaId={properties.fileReferenceUid}
                            src={mediaData.publicUrl}
                            contentType={properties.mimeType}
                            alt={properties.alternative}
                            caption={properties.imageCaption}
                            title={properties.imageTitle}
                            redirectUrl={properties.linkData?.href}
                            linkClassName={properties.linkData?.class}
                        />
                    )
                })
            }
        </div>
    )
}

TypoFileLinks.defaultProps = {};

export { TypoFileLinks }