import classnames from 'classnames/bind';
import React, { FunctionComponent } from 'react';
import { Parallax } from 'react-parallax';

import styles from './Parallax.module.scss';
import { ParallaxProps } from './ParallaxInterface';
import { ParallaxSizeEnum } from './ParallaxSizeEnum';

const cx = classnames.bind(styles);

export const ParallaxContainer: FunctionComponent<ParallaxProps> = ( props ) => {
    const containerClassNames = cx(props.className, {
        'small': props.containerSize === ParallaxSizeEnum.SMALL,
        'medium': props.containerSize === ParallaxSizeEnum.MEDIUM,
        'large': props.containerSize === ParallaxSizeEnum.LARGE,
        'full-height': props.containerSize === ParallaxSizeEnum.FULL_HEIGHT,
    })

    const contentClassNames = cx(props.contentClassName, {
        'content': true
    })

    return (
        <Parallax
            blur={props?.blur}
            bgImage={props?.image}
            bgImageAlt={props?.imageAlt}
            strength={props?.scrollStrength}
            style={props?.style}
        >
            <div
                className={containerClassNames}
                style={{height: props.containerSize === ParallaxSizeEnum.CUSTOM && props.height ? props.height : undefined}}
            >
                <div className={contentClassNames}>
                    {props.children}
                </div>
            </div>
        </Parallax>
    )
}