import parse from 'html-react-parser';
import React from 'react';

import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../typography/typography';
import styles from './text-section.module.scss';

export interface TextSectionProps {
    title?: string;
    titleVariant?: TypographyVariantsEnum;
    description?: string;
    richDescription?: boolean;
}

const TextSection = (props: TextSectionProps): JSX.Element => {
    return (
        <div className={styles['text-section']}>
            <Typography
                variant={
                    props.titleVariant ?? TypographyVariantsEnum.HEADING3_BOLD
                }
            >
                {props.title}
            </Typography>
            <div className={styles['text']}>
                <Typography>
                    {props?.richDescription ? parse(props.description ?? '')
                     : (
                        props.description
                    )}
                </Typography>
            </div>
        </div>
    );
};

TextSection.defaultProps = { richDescription: false };

export default TextSection;
