import React from 'react';

import { TypoElementBullets } from '../../../../api/models/typo/typo-element-bullets';
import styles from '../bullets.module.scss';

const TypoOrderedList = (props: TypoElementBullets): JSX.Element => {
  return (
    <div>
      <ol className={styles.orderedList}>
        {props.bodytext?.map((item, i) => {
          return (
            <li className={styles.listItem} key={`item-${i}`}>
              <span className={styles.text}>{item}</span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

TypoOrderedList.defaultProps = {};

export { TypoOrderedList };