import React from 'react';
import {useDispatch} from 'react-redux';

import {useGlobal} from '../../../api/hooks/useGlobal.hook';
import {connectToMetaMask} from '../../../redux/actions/blockchain.action';
import {addWallet, getUnlockablesWithWallet} from '../../../redux/slices/wallet-slice';
import {useAppSelector} from '../../../utils/_hooks/hooks';
import {Button} from '../button/button';

interface WalletConnectButtonProps {
    className?: string;
    inverted?: boolean;
    buttonText?: string;
    variantId?: number;
}

const WalletConnectButton = (props: WalletConnectButtonProps) => {
    const data = useGlobal();
    const dispatch = useDispatch();

    const {pending, walletId} = useAppSelector((state) => (state.blockchainSlice));
    const addWalletState = useAppSelector(state => state.walletSlice.addWalletModel);
    const getUnlockablesWithWalletModel = useAppSelector(state => state.walletSlice.getUnlockablesWithWalletModel);

    return (
        <Button
            inverted={props?.inverted ?? false}
            className={props.className ?? ''}
            pending={pending || addWalletState.pending || getUnlockablesWithWalletModel.pending}
            onClick={() => {
                if (walletId) {
                    if (props.variantId) {
                        dispatch(getUnlockablesWithWallet({wallet: walletId, variant: props.variantId}));
                    } else {
                        dispatch(addWallet(walletId));
                    }
                } else {
                    dispatch(connectToMetaMask());
                }
            }}
        >
            {props.buttonText
                ? props.buttonText
                : walletId
                    ? data?.buttons?.signMessage?.buttonText
                    : data?.buttons?.connectMetamask?.buttonText}
        </Button>
    );
};

export default WalletConnectButton;
