import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import {useLoginPage} from '../../../api/hooks/useLoginPage.hook';
import {LoginData} from '../../../api/models/login-data';
import {RegisterData} from '../../../api/models/register-data';
import {login, resetLoginState, RootState, useAppSelector} from '../../../redux';
import {ButtonTypesEnum} from '../../../utils/_enums/button-types.enum';
import {RoutePath} from '../../../utils/_enums/route-path.enum';
import {Input} from '../../_shared/input-fields/input/input';
import {Link} from '../../_shared/link/link';
import styles from './login-form.module.scss';
import {AuthenticationFormSubmitButton} from './login-form-submit-button/login-form-submit-button';
import { useCurrentLanguage } from '../../../api/hooks/useCurrentLanguage.hook';

interface AuthenticationFormData extends FormData {
    email: string;
    username: string;
    walletId: string;
    password: string;
    agbConfirmed: boolean;
    newsletterConfirmed: boolean;
    dropletterConfirmed: boolean;
    emailOrUsername: string;
}

interface LoginFormProps {
    withRegisterLink?: boolean;
}

export const LoginForm = ({withRegisterLink}: LoginFormProps): JSX.Element => {
    const data = useLoginPage();
    const dispatch = useDispatch();
    const currLang = useCurrentLanguage();

    const pending = useAppSelector(
        (state: RootState) => state.authentification.pending
    );

    useEffect(() => {
        return () => {
            reset();
            dispatch(resetLoginState());
        };
    }, []);

    const schema = yup.object({
        emailOrUsername: yup
            .string()
            .required(data?.emailOrUsername.fieldErrorMessages?.required ?? ''),
        password: yup
            .string()
            .required(data?.password.fieldErrorMessages?.required ?? '')
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<AuthenticationFormData>({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const dataSubmitted = (data: LoginData | RegisterData) => {
        dispatch(login(data as LoginData));
    };

    if (data) {
        return (
            <>
                <form
                    className={styles['authentification-form']}
                    onSubmit={handleSubmit(dataSubmitted)}
                >
                    {[
                        <Input
                            key="emailOrUsername-input"
                            register={register}
                            name="emailOrUsername"
                            title={data.emailOrUsername.fieldLabel}
                            placeholder={data.emailOrUsername.fieldHint}
                            errorMessage={
                                (errors as any)?.['emailOrUsername']?.message
                            }
                        />,
                        <Input
                            key="password-input"
                            register={register}
                            name="password"
                            title={data.password.fieldLabel}
                            placeholder={data.password.fieldHint}
                            errorMessage={
                                (errors as any)?.['password']?.message
                            }
                            type="password"
                        />,
                        <div key={'forgot-password-link'} className={styles['forgot-password-link']}>
                            <Link
                                href={RoutePath.RESET_PASSWORD}
                            >
                                {data.forgotPassword?.linkText}
                            </Link>
                        </div>,
                        <div key="login-submit-button" className={styles['submit-button']}>
                            <AuthenticationFormSubmitButton
                                type={ButtonTypesEnum.SUBMIT}
                                pending={pending}
                            >
                                {data.loginButton.buttonText}
                            </AuthenticationFormSubmitButton>
                        </div>,
                        <div key={'register-link'} className={styles['register-link']}>
                            {withRegisterLink
                                ? <Link
                                    href={`/${currLang}${RoutePath.REGISTER}`}
                                    target={'_blank'}
                                >
                                    {data.registerLinkText}
                                </Link>
                                : <div/>}
                        </div>
                    ]}
                </form>
            </>
        );
    } else {
        return <div/>;
    }
};
