import React from 'react';

interface Props
    extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height'> {
    size?: string;
}

const ControlsPauseButton: React.FC<Props> = ({fill, size, ...props}) => {
    return (
        <svg id="controls_btn_ellipse_pause_default" data-name="controls/btn/ellipse/pause/default" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <circle id="Ellipse_265" data-name="Ellipse 265" cx="16" cy="16" r="16" fill="#b3b3b3" opacity="0.9"/>
        <g id="ico_pause" data-name="ico pause" transform="translate(13.5 -22.416)">
            <path id="Vereinigungsmenge_26" data-name="Vereinigungsmenge 26" d="M14710.151,14520.882v-11h2.016v11Zm-4.952,0v-11h2v11Z" transform="translate(-14706.184 -14476.964)" fill="#fff"/>
        </g>
        </svg>
    );
};

ControlsPauseButton.defaultProps = {
    fill: '#fff'
};

export default ControlsPauseButton;
