import React from 'react';

import { StrapiFaqItem } from '../../../api/models/strapi/strapi-faq-item';
import { StrapiImage } from '../../../api/models/strapi/strapi-image';
import { TypoElementAccord, TypoElementFaqItem } from '../../../api/models/typo/typo-element-accord';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import Accordion from '../../_shared/accordion/accordion';
import { Typography } from '../../_shared/typography/typography';
import { Headline } from '../_shared/headline/headline';
import styles from './accord.module.scss';

const Accord = (props: TypoElementAccord): JSX.Element => {
    const { id, inverted, faqs, fullwidth, wrapper, childrenHeadline, children } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const faQs: StrapiFaqItem[] = [];
    if (faqs) {
        faqs.map((obj: TypoElementFaqItem) => {
            faQs.push({
                HeadlineDirect: '',
                faqs: {
                    headline: obj.question,
                    description: obj.answer,
                    initialOpen: obj.initial_open,
                    image: {} as StrapiImage
                }
            });
        });
    }

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['accord-box']} 
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
            data-testid="accord-box"
        >
            {!childrenHeadline && (
                <Headline {...props} />
            )}
            {faQs && faQs.length > 0 ? (
                <>
                    {faQs?.map(({ faqs: { description, headline, initialOpen } }) => {
                        return (
                            <Accordion
                                initialOpen={initialOpen ?? false}
                                label={headline}
                                key={'accord-item' + headline}
                                inverted={inverted}
                            >
                                <div className={styles['accord-item-content']}>
                                    <div>
                                        <Typography
                                            variant={TypographyVariantsEnum.BODY}
                                            inverted={inverted}
                                            className={
                                                styles['accord-item-description']
                                            }
                                        >
                                            {description}
                                        </Typography>
                                    </div>
                                </div>
                            </Accordion>
                        );
                    })}
                </>
            ) : (
                <Accordion
                    initialOpen={false}
                    label={childrenHeadline ?? ''}
                    key={'accord-item' + childrenHeadline}
                    inverted={inverted}
                >
                    {children}
                </Accordion>
            )}
        </div>
    );
};

export { Accord };
