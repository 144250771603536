import React from 'react';

import { TypoElementMenu_element } from '../../../api/models/typo/typo-element-menu_element';
import { Headline } from '../_shared/headline/headline';
import { Link } from '../_shared/link/link';
import styles from './menu_subpages.module.scss';

const Menu_subpages = (props: TypoElementMenu_element): JSX.Element => {

    const { id, inverted, menu , fullwidth, wrapper} = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['menu_subpages-section']} 
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className={styles['text']}>
                <ul>
                    {
                        Object.keys(menu).map((key: string) => {
                            const menuObj = menu[key];
                            return (
                                <li key={id + menuObj.link + menuObj.title}>
                                    <Link
                                        target={menuObj.target}
                                        href={menuObj.link}
                                        title={menuObj.title}
                                        linkText={menuObj.title}
                                        class={menuObj.active ? 'active' : ''}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

Menu_subpages.defaultProps = {};

export { Menu_subpages };
