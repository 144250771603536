import {IconName} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LinkR from 'next/link';
import React from 'react';

import {TypographyVariantsEnum} from '../../../../utils/_enums/typeography-variants.enum';
import {Typography} from '../../typography/typography';
import styles from './nav-button.module.scss';

interface NavButtonProps {
    children: string;
    onClick?: () => void;
    href?: string;
    icon?: IconName;
}

const NavButton = (props: NavButtonProps) => {

    const renderButton = () => {
        return (<button onClick={props.onClick}>
            <Typography variant={TypographyVariantsEnum.HEADING6} className={styles['text']}>
                {props.children}
            </Typography>
            <FontAwesomeIcon className={styles['icon']} icon={['fas', props.icon ?? 'chevron-right']}/>
        </button>);
    }

    return (
        <div className={styles['nav-button']}>
            {props.href
                ? <LinkR href={props.href} passHref><a>{renderButton()}</a></LinkR>
                : renderButton()
            }
        </div>
    );
};

export default NavButton;