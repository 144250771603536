import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import { ErrorMessage } from '../../error/error-message';
import ToolTip from '../../tool-tip/tool-tip';
import styles from './input.module.scss';

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: UseFormRegister<any>;
    placeholder?: string;
    validation?: Parameters<InputProps['register']>[1];
    errorMessage?: string;
    fieldToolTip?: string;
    inverted?: boolean;
    valid?: boolean;
}

export const Input: React.FC<InputProps> = ({
    register,
    validation,
    errorMessage,
    className,
    ...props
}) => {
    return (
        <div
            data-testid="input"
            className={`${styles['input-field-wrapper']}
        ${props.disabled ? styles['disabled'] : ''}
        ${props.inverted ? styles['inverted'] : ''}
        ${className ? className : ''}`}
        >
            {props.title ? (
                <label
                    className={errorMessage ? styles['error'] : ''}
                    htmlFor={props.title}
                >
                    {props.title + (props.required ? '*' : '')}
                    {props.fieldToolTip ? (
                        <ToolTip toolTip={props.fieldToolTip} />
                    ) : null}
                </label>
            ) : undefined}
            <div className={styles['input-field']}>
                <input
                    className={`${props.valid ? styles['valid'] : ''} ${
                        errorMessage ? styles['error'] : ''
                    }`}
                    {...props}
                    {...register(props.name as string, {
                        ...validation,
                        valueAsNumber: props.type === 'number'
                    })}
                    placeholder={props.placeholder}
                />
                {props.valid ? (
                    <div className={styles['valid-icon']}>
                        <FontAwesomeIcon icon={['far', 'check']} />
                    </div>
                ) : undefined}
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </div>
        </div>
    );
};
