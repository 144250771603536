import React, {SetStateAction} from 'react';
import {IoMdVolumeHigh, IoMdVolumeLow, IoMdVolumeOff} from 'react-icons/io';

import styles from './audio-volume.module.scss';

interface AudioVolumeInterface {
    colorGrading: string;
    muteVolume: boolean;
    volume: number;
    setVolume: React.Dispatch<React.SetStateAction<number>>;
    setMuteVolume: React.Dispatch<React.SetStateAction<boolean>>;
}

const AudioVolume = (props: AudioVolumeInterface): JSX.Element => {

    const { colorGrading, muteVolume, volume,
        setVolume, setMuteVolume } = props;

    return (
        <div className={styles['volume']}>
            <button onClick={() => setMuteVolume((prev) => !prev)}>
                {muteVolume || volume < 5 ? (
                    <IoMdVolumeOff />
                ) : volume < 40 ? (
                    <IoMdVolumeLow />
                ) : (
                    <IoMdVolumeHigh />
                )}
            </button>
            <input
                type="range"
                className={styles['slider']}
                min={0}
                max={100}
                value={volume}
                style={{
                    background: `linear-gradient(to right, ${colorGrading} ${volume}%, #bababa ${volume}%)`,
                }}
                onChange={(e) => setVolume(e.target.value as unknown as SetStateAction<any>)}
            />
        </div>
    );
};

AudioVolume.defaultProps = {};

export { AudioVolume };
