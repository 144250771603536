import React from 'react';

interface Props
    extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height'> {
    size?: string;
}

const ControlsPlayButton: React.FC<Props> = ({fill, size, ...props}) => {
    return (
        <svg id="controls_btn_ellipse_play_default" data-name="controls/btn/ellipse/play/default" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <circle id="Ellipse_264" data-name="Ellipse 264" cx="16" cy="16" r="16" fill="#b3b3b3" opacity="0.9"/>
        <path id="ico_play" data-name="ico play" d="M1313.123,752.261l7.641,6.766-7.641,6.333Zm4.628,6.766-2.756-2.525v4.9Z" transform="translate(-1300.123 -742.811)" fill={fill}/>
        </svg>
    );
};

ControlsPlayButton.defaultProps = {
    fill: '#fff'
};

export default ControlsPlayButton;
