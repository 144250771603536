import React from 'react';

export const LogoSvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg viewBox="0 0 229 201" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M131.163 90.0304C134.803 90.0304 137.754 87.0797 137.754 83.4399C137.754 79.7998 134.803 76.849 131.163 76.849C127.523 76.849 124.572 79.7998 124.572 83.4399C124.572 87.0797 127.523 90.0304 131.163 90.0304Z" fill="white"/>
            <path d="M197.196 131.912L189.061 136.724L179.529 126.515L153.563 141.462L152.793 140.842L173.912 127.173L173.922 127.166L173.912 127.155V42.5442L173.918 41.3173L162.5 45.3395V0H0.00417131V102.589H0L0.00417131 102.935V103.079L0.012514 103.821L11.4107 99.8047V158.844H173.906V158.838L224.318 158.844L197.196 131.912ZM10.7657 10.7606H151.738V49.1309L10.7657 98.7973V10.7606ZM146.332 120.59L145.941 120.987L131.893 112.393L104.394 129.229L103.433 128.64L117.702 118.334L94.0996 83.4306L66.4291 124.301L73.0718 132.017L72.3858 133.132L59.634 121.232L37.3327 148.081H22.1738V96.0118L163.146 46.338V114.565L151.777 101.26L139.255 114.152L146.332 120.59Z" fill="white"/>
            <path d="M24.812 196.363C26.7773 196.363 28.7716 195.863 30.2818 194.992H30.2837C31.5161 194.348 32.6502 193.396 33.5606 192.494C33.551 192.508 36.2473 195.119 36.2473 195.119L35.9502 195.446C33.0398 198.638 29.3907 200.258 25.1032 200.258H24.5824C20.5784 200.258 17.0932 198.763 14.2233 195.819C11.3611 192.885 9.91074 189.394 9.91074 185.427C9.91074 181.46 11.3862 177.987 14.2947 175.108C17.2012 172.229 20.8021 170.769 25.0434 170.769C29.2847 170.769 32.9511 172.385 35.9349 175.567L36.2165 175.883C36.2165 175.883 33.4025 178.621 33.4121 178.64C32.604 177.929 30.9839 176.589 29.9578 176.008C28.3608 175.104 26.6771 174.664 24.812 174.664C21.9421 174.664 19.4038 175.744 17.2669 177.875C15.1298 180.006 14.0459 182.575 14.0459 185.514C14.0459 188.452 15.1298 191.021 17.2669 193.152C19.4038 195.283 21.9421 196.363 24.812 196.363Z" fill="white"/>
            <path d="M66.9235 171.373H83.1825V174.992H71.0818V183.552H82.4264V187.135H71.0818V195.996H83.3734V199.654H66.9235V171.373Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M96.7722 171.373H102.142L111.596 199.654H107.133L104.902 192.527H93.8965L91.6264 199.654H87.3158L96.7722 171.373ZM95.0711 188.869H103.731L99.4956 175.446H99.3452L95.0711 188.869Z" fill="white"/>
            <path d="M111.174 174.992H118.736V199.652H122.897V174.992H130.461V171.373H111.174V174.992Z" fill="white"/>
            <path d="M191.159 171.373H195.32V199.654H191.159V171.373Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M209.532 171.24H214.902L224.357 199.52H219.893L217.662 192.393H206.657L204.387 199.52H200.076L209.532 171.24ZM207.831 188.736H216.491L212.256 175.313H212.105L207.831 188.736Z" fill="white"/>
            <path d="M154.47 181.033L157.606 177.941H157.608C158.802 180.117 159.469 182.689 159.469 185.514C159.469 194.037 153.53 200.258 145.059 200.258C136.589 200.258 130.613 194.037 130.613 185.514C130.613 176.991 136.702 170.769 145.059 170.769C148.392 170.769 151.357 171.762 153.716 173.492L150.913 176.265C149.303 175.117 147.31 174.466 145.057 174.466C139.044 174.466 134.884 179.027 134.884 185.475C134.884 191.923 139.044 196.485 145.057 196.485C151.071 196.485 155.231 191.923 155.231 185.475C155.231 183.867 154.963 182.373 154.47 181.033Z" fill="white"/>
            <path d="M54.1843 188.039C58.306 187.096 61.0293 183.89 61.0293 179.819C61.0293 175.031 57.1333 171.335 51.6886 171.335H41.2137V199.615H45.374V174.954H51.2739C54.7148 174.954 56.7958 177.14 56.7958 179.856C56.7958 182.571 54.7167 184.796 51.2739 184.796H47.6305L49.6866 188.304L56.5316 199.615H61.3341L54.1863 188.039H54.1843Z" fill="white"/>
            <path d="M184.56 199.519H179.658L171.4 187.227H168.459V199.519H164.312V171.24H168.459V183.645H171.438L179.658 171.24H184.447L174.832 185.417L184.56 199.519Z" fill="white"/>
        </svg>
    );
};
