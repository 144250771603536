import React from 'react';

import { TypographyVariantsEnum } from '../../../../../../utils/_enums/typeography-variants.enum';
import {
    LinkBase,
    Props as LinkBaseProps
} from '../../../../link/link-base/link-base';
import { Typography } from '../../../../typography/typography';
import styles from './header-mobile-navigation-item.module.scss';
export type Props = LinkBaseProps

export const HeaderMobileNavigationItem: React.FC<Props> = ({
    children,
    ...props
}) => {
    return (
        <LinkBase
            className={styles['header-mobile-navigation-item']}
            {...props}
        >
            <Typography variant={TypographyVariantsEnum.MOBILENAVLINK}>
                {children}
            </Typography>
        </LinkBase>
    );
};
