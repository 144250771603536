import React from 'react';

import { TypoFieldButtons } from '../../../../api/models/typo/field/typo-field-buttons';
import { Button, ButtonProps } from '../../../_shared/button/button';
import { LinkBase } from '../../../_shared/link/link-base/link-base';
import styles from './typo_buttons.module.scss';

export interface ButtonsProps {
    inverted?: boolean,
    buttons?: TypoFieldButtons[]
}

const TypoButtons = (props: ButtonsProps): JSX.Element => {
    const { inverted, buttons } = props;

    return (
        <>
            {buttons && buttons.length > 0 && (
                <div className={styles['buttons']}>
                    {buttons.map((props) => {
                        props.children = !props.children ? props.title : props.children;
                        if (props.uri && props.uri.href && !props.disabled)
                            return (
                                <LinkBase
                                    key={props.children as string}
                                    href={props.uri.href}
                                    locale={false}
                                    external={props.uri.target !== '' && props.uri.target !== null}
                                >
                                    <Button
                                        inverted={inverted}
                                        {...props as unknown as ButtonProps}
                                    />
                                </LinkBase>
                            );
                        else
                            return (
                                <Button
                                    inverted={inverted}
                                    {...props as unknown as ButtonProps}
                                />
                            );
                    })}
                </div>
            )}
        </>
    );
};

export { TypoButtons };
