import React from 'react';

import { TypoElementHeader } from '../../../api/models/typo/typo-element-header';
import { Headline } from '../_shared/headline/headline';
import styles from './header.module.scss';

const Header = (props: TypoElementHeader): JSX.Element => {
    const { id, inverted, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['text-section']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} noContentSpacing={true} />
            </div>
        </div>
    );
};

Header.defaultProps = {};

export { Header };
