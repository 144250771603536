import React from 'react';

import {RoutePath} from '../../../../../utils/_enums/route-path.enum';
import {LanguageSelectorOptions} from '../../../../../utils/_models/languageSelectorOptions';
import {Button, ButtonProps} from '../../../button/button';
import LanguageSelector from '../../../language-selector/language-selector';
import {Seperator} from '../../../seperator/seperator';
import {HeaderMobilePopup} from '../header-mobile-popup';
import styles from './header-mobile-navigation.module.scss';
import {HeaderMobileNavigationItem} from './header-mobile-navigation-item/header-mobile-navigation-item';

export interface Props {
    open?: boolean;
    onCancelClick: (open: Props['open']) => void;
    navLinks?: { label: string; value: RoutePath; external?: boolean }[];
    adminNavLinks?: { label: string; link: string; external?: boolean; role: string }[];
    submitButtonProps?: ButtonProps;
    className?: string;
    cancelButtonOffset?: number;
    localizations?: LanguageSelectorOptions[];
    forStaticPage?: boolean;
    pageType?: string;
    userRole?: string;
}

export const HeaderMobileNavigation: React.FC<Props> = ({
                                                            open,
                                                            navLinks,
                                                            adminNavLinks,
                                                            userRole,
                                                            onCancelClick,
                                                            submitButtonProps,
                                                            className,
                                                            cancelButtonOffset,
                                                            children,
                                                            localizations,
                                                            forStaticPage,
                                                            pageType
                                                        }) => {
    if (open) {
        return (
            <HeaderMobilePopup
                cancelButtonOffset={cancelButtonOffset}
                className={className}
                backgroundColor="white"
                onClick={() => onCancelClick(!open)}
            >
                <div className={styles['header-mobile-navigation']}>
                    <ul>
                        {navLinks?.map(({label, value, external}) => (
                            <li key={value}>
                                <HeaderMobileNavigationItem
                                    external={external}
                                    href={value}
                                >
                                    {label}
                                </HeaderMobileNavigationItem>
                            </li>
                        ))}

                        {localizations && localizations.length > 0 &&
                            <li key={'localization'} className={styles['language-selector']}>
                                <LanguageSelector
                                    isInverted={true}
                                    isStaticPage={forStaticPage}
                                    pageType={pageType}
                                    languageOptions={localizations}
                                />
                            </li>
                        }
                    </ul>

                    {userRole
                    && adminNavLinks
                    && adminNavLinks.filter(({role}) => userRole === role).length > 0
                        ? <>
                            <Seperator
                                height="1px"
                                margin="25px 0"
                            />
                            <ul>
                                {adminNavLinks
                                    .filter(({role}) => userRole === role)
                                    .map(({label, link, external}) => (
                                        <li key={link}>
                                            <HeaderMobileNavigationItem
                                                external={external}
                                                href={link}
                                            >
                                                {label}
                                            </HeaderMobileNavigationItem>
                                        </li>
                                    ))}
                            </ul>
                        </>
                        : undefined}

                    {submitButtonProps
                        ? <>
                            <Seperator
                                key="seperator"
                                height="1px"
                                margin="25px 0"
                            />
                            <Button key="submit-button"{...submitButtonProps}/>
                        </>
                        : undefined}

                    {children}
                </div>
            </HeaderMobilePopup>
        );
    } else return null;
};

HeaderMobileNavigation.defaultProps = {open: false};
