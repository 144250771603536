import React from 'react';

import {UniversalTeaserProps} from '../../../../utils/_models/universal-teaser-props';
import CardDetail from '../card-detail/card-detail';
import CardFooter from '../card-footer/card-footer';
import styles from './card-back.module.scss';

interface CardBackProps extends UniversalTeaserProps {
    onSwap: () => void;
    onBuy: () => void;
}

const CardBack = (props: CardBackProps) => {
    return (
        <div className={`${styles['back']} ${props.inverted ? styles['inverted'] : ''}`}>
            <div className={styles['content']}>
                <CardDetail {...props} />
            </div>
            <CardFooter {...props}
                        isFront={false}
                        onBuy={props.onBuy}
                        onSwap={props.onSwap}
                        className={styles['footer']}
            />
            <div className={`${styles['teaser-border']} ${props.inverted ? styles['inverted'] : ''}`}></div>
        </div>
    );
};

export default CardBack;
