import React from 'react';

import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import { generateUrlToDetailedPage } from '../../../../utils/_helper/generate-url-to-details-page-helper';
import { UniversalTeaserProps } from '../../../../utils/_models/universal-teaser-props';
import { LinkBase } from '../../link/link-base/link-base';
import { Typography } from '../../typography/typography';
import styles from './card-title.module.scss';

const CardTitle = (props: UniversalTeaserProps) => {
    return (
        <div className={props.inverted ? styles['inverted'] : ''}>
            <Typography variant={TypographyVariantsEnum.TOPLINE_OR_LABEL} className={styles['topline']}>
                {props.topline}
            </Typography>
            <div className={styles['title-wrapper']}>
                {props.productName && props.title && props.productId && props.variantId
                    ? <LinkBase
                        href={generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId)}>
                        <Typography variant={TypographyVariantsEnum.HEADING6} className={`${styles['title']} ${styles['link-title']}`}>
                            {props.title}
                        </Typography>
                    </LinkBase>
                    : <Typography variant={TypographyVariantsEnum.HEADING6} className={styles['title']}>
                        {props.title}
                    </Typography>
                }
            </div>
        </div>
    )
}

export default CardTitle