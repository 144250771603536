import React from 'react';

import { ButtonVariantsEnum } from '../../../../utils/_enums/button-variants.enum';
import { Button, ButtonProps } from '../../../_shared/button/button';
import styles from './stage-image-button-token.module.scss';

export interface Props extends Omit<ButtonProps, 'icon' | 'variant'> {
    image: string;
    shadow?: boolean;
    icon?: {
        element: React.ReactNode;
        align: 'left' | 'right';
        flip?: boolean;
    };
    editButtonProps?: Omit<ButtonProps, 'children'>;
}

export const StageImageButtonToken: React.FC<Props> = ({
    image,
    editButtonProps,
    shadow,
    ...props
}) => {
    return (
        <div
            className={styles['stage-image-button-token']}
        >
            <Button
                {...props}
                variant={
                    shadow
                        ? ButtonVariantsEnum.STAGE
                        : ButtonVariantsEnum.SECONDARY_FILLED
                }
                className={styles['token-button']}
                icon={{ element: <img src={image ? image + '?resolution=thumbnail' : ''} alt={'Icon'}  />, align: 'left' }}
            />
            {editButtonProps && (
                <Button {...editButtonProps} className={styles.editButton} />
            )}
        </div>
    );
};
