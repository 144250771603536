import React from 'react';

import { Button, ButtonProps } from '../../../_shared/button/button';
import styles from './login-form-submit-button.module.scss';

export const AuthenticationFormSubmitButton: React.FC<ButtonProps> = (
    props
) => {
    return (
        <div className={styles['authentication-form-submit-button-wrapper']}>
            <Button {...props} />
        </div>
    );
};
