export enum ButtonVariantsEnum {
    PRIMARY = 'primary',
    SECONDARY_FILLED = 'secondary-filled',
    SECONDARY_OUTLINED = 'secondary-outlined',
    SECONDARY_OUTLINED_FILLED_ICON = 'secondary-outlined-filled-icon',
    STAGE = 'stage',
    NAVIGATION = 'navigation',
    SELECTED = 'selected',
    LEFT_ARROW = 'left-arrow'
}
