import * as React from 'react';
import {SVGProps} from 'react';

import styles from './icon-styles.module.scss';

interface FlipSVGProps extends SVGProps<SVGSVGElement> {
    inverted?: boolean;
}

const FlipSVG = (props: FlipSVGProps) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={26.667}
        height={44.172}
        className={`${styles['flip-icon']} ${props.inverted ? styles['inverted'] : ''} ${props.className}`}
    >
        <path data-name="Pfad 3843" d="M26.667 0v44.172H0Z" className={styles['outside']}/>
        <path
            data-name="Vereinigungsmenge 29"
            className={styles['inside']}
            d="m15.318 38.978 1.237-1.595c2.17-.362 3.812-1.528 3.812-3.905a3.746 3.746 0 0 0-3.572-3.875l1.619 2.511h-1.859L14.348 28.8l2.207-3.045h1.859l-1.684 2.386c2.918.137 5.233 1.885 5.238 5.437v.017c0 3.693-2.847 5.353-6.65 5.383Z"
        />
    </svg>
)

export default FlipSVG;
