import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useRouter} from 'next/router';
import React, {useState} from 'react';

import {useTypoContent} from '../../../api/hooks/useTypoContent.hook';
import {TypoElementNews} from '../../../api/models/typo/typo-element-news';
import {TypoElementNewsListitem, Views} from '../../../api/models/typo/typo-element-news-listitem';
import {TypoService} from '../../../api/services/typo.service';
import {ButtonVariantsEnum} from '../../../utils/_enums/button-variants.enum';
import {Button} from '../../_shared/button/button';
import {Select} from '../../_shared/select/select';
import styles from './news-list.module.scss';
import {Academy_Template} from './templates/academy/academy_template';
import {Jobs_Template} from './templates/jobs/jobs_template';
import {Podcast_Template} from './templates/podcast/podcast_template';

const NewsList = (props: TypoElementNews): JSX.Element => {

    const { id, inverted, news, fullwidth } = props;
    const wrapper = props.wrapper ?? props.content.wrapper;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const newsType = news.settings.templateLayout;
    const router = useRouter();
    const { catid } = router.query;
    const [initialFilter, setInitialFilter] = useState<boolean>(false);
    const [filter, setFilter] = useState<{value: number, label: string}>();
    const typoService = new TypoService(useTypoContent());

    if (!news.list && !news.detail) {
        return <div />;
    }

    const newsTypeElements = (data: TypoElementNewsListitem, view: Views) => {
        switch (newsType) {
            case 'jobs': {
                return <Jobs_Template {...data} view={view} />
            }
            case 'podcast': {
                return <Podcast_Template {...data} view={view} />
            }
            default: {
                return <Academy_Template {...data} inverted={inverted} view={view} returnUrl={news.settings.returnUrl} />
            }
        }
    }

    const getUniqueCategories = () => {
        if (!news.list || (newsType !== 'academy' && newsType !== 'academyFilter')) {
            return;
        }

        let categories: any[] = [];
        const foundIds: any[] = [];
        news.list.map((element) => {
            element.categories.map((cat) => {
                if (!foundIds.includes(cat.id)) {
                    categories = categories.concat(cat);
                    foundIds.push(cat.id);
                } else {
                    foundIds.push(cat.id);
                }
            });
        });
        return categories;
    }

    const generateCategories = () => {

        if (!news.list || newsType !== 'academy') {
            return;
        }

        const categories: any[] | undefined = getUniqueCategories();
        if (!categories) {
            return;
        }

        return (
            <div className={styles['category-container']}>
                <Select
                    label={typoService.getLabel('content-elements', 'filter-category-label')}
                    placeholder={typoService.getLabel('content-elements', 'filter-category-placeholder')}
                    className={styles['select']}
                    onChange={(opt) => setFilter({value: opt.value, label: opt.label})}
                    name="categoryId"
                    isSearchable={true}
                    value={filter}
                    options={categories.map(cat => {
                        return {
                            value: cat.id,
                            label: cat.title
                        }
                    })}
                    inverted={inverted}
                />
                {filter && (
                    <Button
                        onClick={() => setFilter('' as unknown as {value: number, label: string})}
                        variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                        inverted={inverted}
                        icon={{
                            element: (
                                <FontAwesomeIcon
                                    icon={['fas', 'undo']}
                                />
                            ),
                            align: 'right'
                        }}
                    />
                )}
            </div>
        )
    }

    const newsFilter = () => {
        if (!news.list) {
            return undefined;
        }

        if (!filter || !filter.value) {
            return news.list;
        }

        let newsToReturn: any[] = [];
        news.list.map((news) => {
            news.categories.map((category) => {
                if (category.id == filter.value) {
                    newsToReturn = newsToReturn.concat(news);
                    return false;
                }
            })
        })
        return newsToReturn;
    }

    if (!initialFilter && catid) {
        const categories: any[] | undefined = getUniqueCategories();
        if (categories) {
            const defaultVal = catid ? categories.find(
                (cat) => cat.id == catid
            ) : undefined;
            if (defaultVal) {
                setFilter({value: defaultVal.id, label: defaultVal.title});
            }
        }
        setInitialFilter(true);
    }
    const applicationNews = newsFilter();

    return (
        <>
            {generateCategories()}
            <div
                id={`c${id}`}
                className={`
                    ${styles['news-list']}
                    ${styles['news-' + newsType + '-' + (applicationNews ? 'list' : 'detail')]}
                    ${'spaceBefore' + appearance.spaceBefore} 
                    ${'spaceAfter' + appearance.spaceAfter} 
                    ${inverted ? styles['inverted'] : ''}
                    ${fullwidth ? styles['fullwidth'] : ''}
                    ${wrapper !== undefined ? styles[wrapper] : ''}
                `}
            >
                {applicationNews ? (
                    <>
                        {applicationNews.map((element) => {
                            return <div
                                className={styles['news-item']}
                                key={'news-list-item-container-' + element.uid}>
                                {newsTypeElements(element, 'list' as Views)}
                            </div>
                        })}

                        {/*Fill empty space because flex would stretch the last item*/}
                        {newsType === 'academy' || newsType === 'academyFilter' && (
                            <>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            <div className={styles['news-item']}></div>
                            </>
                        )}
                    </>
                ) : (
                    newsTypeElements(news.detail as TypoElementNewsListitem, 'detail' as Views)
                )}
            </div>
        </>
    );
};

export { NewsList };
