import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {useRouter} from 'next/dist/client/router';
import React, {useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as yup from 'yup';

import {StrapiTextFieldProps} from '../../../api/models/strapi/strapi-text-field-props';
import {resetNewsletterSubscription, sendNewsletterSubscription} from '../../../redux';
import {ToasterTypes} from '../../../utils/_enums/toaster-types.enum';
import {useAppSelector} from '../../../utils/_hooks/hooks';
import {SubmitInput} from '../input-fields/submit-input/submit-input';
import styles from './newsletter-input.module.scss';

interface NewsletterInputProps {
    successMessage: string;
    errorMessage: string;
    inputTexts?: StrapiTextFieldProps;
    className?: string;
    otherIcon?: IconProp;
    inverted?: boolean;
}

const NewsletterInput = (props: NewsletterInputProps) => {
    const router = useRouter();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { pending, submitted} = useAppSelector((state) => state.newsletterSlice.newsletter);
    const error = useAppSelector((state)=>state.globalSlice.errorCodes)
    const {addToast} = useToasts();

    useEffect(() => {
        if (formSubmitted) {
            if (submitted && !pending && props.successMessage) {
                addToast(props.successMessage, {
                    appearance: ToasterTypes.SUCCESS
                });
                dispatch(resetNewsletterSubscription());
            } else if (!pending && error && error.length > 0) {
                dispatch(resetNewsletterSubscription());
            }
            setFormSubmitted(false);
        }
    }, [submitted, error]);


    const subscribeToNewsletter = (email: string) => {
        setFormSubmitted(true);
        if (executeRecaptcha) {
            const token = executeRecaptcha('newsletter_subscription');
            token.then((res) => {
                const token = res;
                dispatch(
                    sendNewsletterSubscription({
                        email,
                        token,
                        languageCode: router.locale ?? 'de'
                    })
                );
            });
        }
    };
    if (props.inputTexts) {
        return (
            <SubmitInput
                placeholder={props.inputTexts.fieldHint}
                submitForm={subscribeToNewsletter}
                className={styles['newsletter-input']}
                inputClassName={props.className ?? ''}
                name={'email'}
                inverted={props.inverted}
                submitPending={pending}
                otherIcon={props.otherIcon}
                schema={
                    props.inputTexts.fieldErrorMessages
                        ? yup.object({
                            value: yup
                                .string()
                                .required(props.inputTexts.fieldErrorMessages?.required)
                                .email(props.inputTexts.fieldErrorMessages?.valid)
                        })
                        : undefined
                }
            />
        );
    } else {
        return <div/>;
    }

}

export default NewsletterInput;
