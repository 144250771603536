// @ts-nocheck
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import React, { useEffect, useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

import { TypoElementTable } from '../../../api/models/typo/typo-element-table';
import { Headline } from '../_shared/headline/headline';
import styles from './table.module.scss';

const TypoTable = (props: TypoElementTable): JSX.Element => {
    const [tableDataArray, setTableDataArray] = useState([]);
    const [tableHeadArray, setTableHeadArray] = useState([]);
    const [tableBodyArray, setTableBodyArray] = useState([]);

    useEffect(() => {
        if (props.bodytext !== undefined || props.bodytext.length !== 0) {
            setTableDataArray(props.bodytext);
        }
    }, [props.bodytext]);

    useEffect(() => {
        if (tableDataArray.length > 0) {
            const tableHeadData = tableDataArray[0];
            const tableBodyData = tableDataArray.filter((data, i) => {
                return i !== 0;
            });

            setTableHeadArray(tableHeadData);
            setTableBodyArray(tableBodyData);
        }

    }, [tableDataArray]);

    return (
        <div id={`c${props.id}`} className={
          `
            ${props.inverted ? styles['inverted'] : ''}
            ${props.fullwidth ? styles['fullwidth'] : ''}
            ${props.wrapper !== undefined ? styles[props.wrapper] : ''}
          `
        }>
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <Table key={`table-${props.id}`} className={styles.table}>
                <Thead>
                    <Tr>
                        {
                            tableHeadArray.map((cell, i) => {
                                return (
                                    <Th key={`head-cell-${i}`}>
                                        {cell}
                                    </Th>
                                );
                            })
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        tableBodyArray.map((rows, i) => {
                            return (
                                <Tr key={`table-row-${i}`}>
                                    {
                                        rows.map((cells, i) => {
                                            return (
                                                <Td key={`body-cell-${i}`}>
                                                    {cells}
                                                </Td>
                                            );
                                        })
                                    }
                                </Tr>
                            );
                        })
                    }
                </Tbody>
            </Table>
        </div>
    );
};

TypoTable.defaultProps = {};

export { TypoTable };