import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../typography/typography';
import styles from './accordion.module.scss';

interface AccordionProps {
    initialOpen: boolean;
    children: React.ReactNode;
    label: string;
    onlyMobil?: boolean;
    reverseContent?: boolean;
    inverted?: boolean;
}

const Accordion = (props: AccordionProps): JSX.Element => {
    const inverted = props.inverted
    const [isOpen, setIsOpen] = useState(props.initialOpen);
    const [contentStyle, setContentStyle] = useState(
        props.initialOpen
            ? `${styles['accordion']} ${styles['active']}`
            : styles['accordion']
    );

    const toggleAccordion = (): void => {
        if (!isOpen) {
            setContentStyle(`${styles['accordion']} ${styles['active']}`);
        } else {
            setContentStyle(styles['accordion']);
        }
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={`accordion ${contentStyle} ${inverted ? styles['inverted'] : ''} ${props.onlyMobil ? styles['only-mobile'] : ''
                }`}
            data-testid="accordion"
        >
            <div
                onClick={(): void => toggleAccordion()}
                className={`accordion-header ${styles['accordion-header']}`}
            >
                
                <Typography
                    element="span"
                    variant={TypographyVariantsEnum.FAQ_HEADING}
                    className={styles['accordion-label']}
                >
                    {props.label}
                </Typography>

                <div className={styles['accordion-icon-wrapper']}>
                    <FontAwesomeIcon
                        className={styles['accordion-icon']}
                        icon={isOpen ? ['far', 'chevron-up'] : ['far', 'chevron-down']}
                        color={'black'}
                    />
                </div>

            </div>

            <div className={`${styles['content']} ${props.reverseContent ? styles['content-reverse'] : ''}`}  id={'content'}>
                {props.children}
            </div>

        </div>
    );
};

export default Accordion;
