import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LinkR from 'next/link';
import React from 'react';
import {useToasts} from 'react-toast-notifications';

import {useGlobal} from '../../../../api/hooks/useGlobal.hook';
import {ToasterTypes} from '../../../../utils/_enums/toaster-types.enum';
import {generateUrlToDetailedPage} from '../../../../utils/_helper/generate-url-to-details-page-helper';
import {UniversalTeaserBuyProps} from '../../../../utils/_models/universal-teaser-props';
import styles from './card-overlay.module.scss';

const CardOverlay = (props: UniversalTeaserBuyProps) => {
    // const dispatch = useDispatch();
    // const [modalType, setModalType] = useState<CardModalTypes | undefined>(undefined);
    const {addToast} = useToasts();
    const globalData = useGlobal();

    const renderLinkToDetailPage = (label: string | undefined, toSalesBox?: boolean) => {
        if (props.productName && props.title && props.productId && props.variantId) {
            return (
                <LinkR
                    href={generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId, undefined, undefined, toSalesBox)}
                    passHref>
                    <a>
                        {label}
                    </a>
                </LinkR>
            )
        }
    }

    // const renderModalContent = () => {
    //     switch (modalType) {
    //         case CardModalTypes.MINT:
    //             return props.instanceId && props.tokenContract
    //                 ? <MintingBox
    //                     instanceId={props.instanceId}
    //                     tokenContract={props.tokenContract}/>
    //                 : undefined
    //         case CardModalTypes.RESALE:
    //             return <ResaleBox/>;
    //         case CardModalTypes.CANCEL_RESALE:
    //             return 'Cancel resale';
    //     }
    // }

    // const cancelResale = () => {
    //     if (props.instanceId) {
    //         dispatch(cancelFixPrice(props.instanceId));
    //     }
    // }

    const copyLinkToClipboard = () => {
        if (props.productName && props.title && props.productId && props.variantId) {
            const ta = document.createElement('textarea');
            ta.innerText = `${generateUrlToDetailedPage(props.productName, props.title, props.productId, props.variantId, props.instanceId, undefined, true)}`;
            document.body.appendChild(ta);
            ta.select();
            document.execCommand('copy');
            ta.remove();
            addToast(globalData.errorMessages?.copiedSuccessfully ?? '', {
                appearance: ToasterTypes.SUCCESS
            });
        }
    }

    return (
        <div className={styles['overlay']}>
            {
                props.canBuy
                    ? <div>
                        <p onClick={props.onBuy}>{props.teaserMenuLabels?.buyNow}</p>
                    </div>
                    : undefined
            }
            {
                props.canMint
                    ? <div>
                        {renderLinkToDetailPage(props.teaserMenuLabels?.mint, true)}
                    </div>
                    : undefined
            }
            {
                props.canCancelResale
                    ? <div>
                        {renderLinkToDetailPage(props.teaserMenuLabels?.cancelResale, true)}
                    </div>
                    : undefined
            }
            {
                props.canSell
                    ? <div>
                        {renderLinkToDetailPage(props.teaserMenuLabels?.sell, true)}
                    </div>
                    : undefined
            }
            <div>
                <p onClick={() => copyLinkToClipboard()}>
                    <span>{props.teaserMenuLabels?.share}</span>
                    <span className={styles['copy-icon-wrapper']}>
                        <FontAwesomeIcon
                            icon={['far', 'copy']}
                        />
                    </span>
                </p>
            </div>
            {
                props.productName && props.title && props.productId && props.variantId
                    ? <div>
                        {renderLinkToDetailPage(
                            props.instanceId
                                ? props.teaserMenuLabels?.instanceDetails
                                : props.teaserMenuLabels?.variantDetails
                        )}
                    </div>
                    : undefined
            }
            {/*{*/}
            {/*    modalType !== undefined*/}
            {/*        ? <Dialog onClose={() => setModalType(undefined)}>*/}
            {/*            {renderModalContent()}*/}
            {/*        </Dialog>*/}
            {/*        : undefined*/}
            {/*}*/}
            {
                props.instanceId
                    ? <div>
                        {`${props.teaserMenuLabels?.instanceIDString}: ${props.instanceId}`}
                    </div>
                    : undefined
            }
        </div>
    );
};

export default CardOverlay;