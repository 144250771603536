import * as React from 'react';
import {SVGProps} from 'react';

const EthSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24.195}
        height={20.793}
        {...props}
    >
        <g data-name="hexagon eth">
            <path
                data-name="Pfad 442"
                d="M18.147 0H6.049L0 10.4l6.049 10.4h12.1L24.2 10.4Z"
                fill="#E2E7FB"
            />
        </g>
        <path
            data-name="Pfad 3836"
            d="m11.647 3.638-4.148 6.884 4.148-1.885Z"
            fill="#8a92b2"
        />
        <path
            data-name="Pfad 3837"
            d="m11.647 8.636-4.148 1.885 4.148 2.453Z"
            fill="#62688f"
        />
        <path
            data-name="Pfad 3838"
            d="m15.796 10.522-4.149-6.884v5Z"
            fill="#62688f"
        />
        <path
            data-name="Pfad 3839"
            d="m11.647 12.974 4.149-2.453-4.149-1.885Z"
            fill="#454a75"
        />
        <path
            data-name="Pfad 3840"
            d="m7.499 11.309 4.148 5.846v-3.4Z"
            fill="#8a92b2"
        />
        <path
            data-name="Pfad 3841"
            d="M11.647 13.76v3.4l4.151-5.846Z"
            fill="#62688f"
        />
    </svg>
)

export default EthSVG;
