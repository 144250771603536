import {BasePageData} from '../models/strapi/base-page-data';
import {StrapiHeadline} from '../models/strapi/strapi-headline';
import { StrapiMeta } from '../models/strapi/strapi-meta';
import { StrapiStage } from '../models/strapi/strapi-stage';
import { StrapiTextSectionProps } from '../models/strapi/strapi-text-section-props';
import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useMyOan = useLanguageDependentDataSSr<MyOanData>(
    'my-oan'
);

export interface MyOanData extends BasePageData{
    stage: StrapiStage;
    meta: StrapiMeta;
    walletId: string;
    ownedItemsHeadline: StrapiHeadline;
    copiedSuccessfully: string;
    noNftNote: StrapiTextSectionProps;
}
