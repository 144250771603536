import * as React from 'react';
import {SVGProps} from 'react';

const PolygonSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24.195}
        height={20.793}
        {...props}
    >
        <g data-name="hexagon eth">
            <path
                data-name="Pfad 442"
                d="M18.147 0H6.049L0 10.4l6.049 10.4h12.1L24.2 10.4Z"
                fill="#E4D8FA"
            />
        </g>
        <path
            data-name="Pfad 3803"
            d="m14.569 15.379-2.89-1.795v-1.85h1.148v1.211l1.729 1.074 1.841-1.2v-2.191l-1.835-1.059-4.913 2.822-3-1.77V7.357l3-1.951 3.083 1.945v1.436h-1.148v-.8L9.658 6.772 7.799 7.983v1.986l1.858 1.1 4.907-2.822 2.984 1.719v3.48Z"
            fill="#7b3fe4"
        />
    </svg>
)

export default PolygonSVG;
