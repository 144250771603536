import React from 'react';

import { useFooter } from '../../../api/hooks/useFooter.hook';
import { Footer } from '../footer/footer';
import { DefaultHeader } from '../header/default-header/default-header';
import styles from './header-footer-wrapper.module.scss';

export interface Props {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    stage?: React.ReactNode;
    className?: string;
    inverted?: boolean;
}

export const HeaderFooterWrapper: React.FC<Props> & { dataHooks: any } = ({
    children,
    header,
    footer,
    className,
    stage,
    inverted
}) => {
    return (
        <div
            className={`${styles['header-footer-wrapper']}  ${
                inverted && styles.inverted
            } ${className ?? ''}`}
        >
            {header}
            {stage}
            <main>{children}</main>
            {footer}
        </div>
    );
};

HeaderFooterWrapper.defaultProps = {
    header: <DefaultHeader />,
    footer: <Footer />
};

HeaderFooterWrapper.dataHooks = [...DefaultHeader.dataHooks, useFooter];
