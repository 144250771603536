import React from 'react';

import {TypoElementNewsListitem} from '../../../../../api/models/typo/typo-element-news-listitem';
import {LinkBase} from '../../../../_shared/link/link-base/link-base';
import {Headline} from '../../../_shared/headline/headline';
import styles from './jobs_template.module.scss';

const Jobs_Template = (props: TypoElementNewsListitem): JSX.Element => {

    const { uid, relatedLinks, title, teaser, bodytext, datetime, slug, view } = props;
    const linkLength = relatedLinks ? relatedLinks.length : 0;

    return (
        <div
            className={`${styles['jobs_template']} ${styles['jobs-' + view]}`}
            key={'jobs_template-' + uid}
        >
            {String(view) === 'list' ? (
                <>
                    <Headline
                        {...props}
                        header={title}
                        subheader={datetime}
                        bodytext={teaser}
                        headerLink={slug}
                        variant="teaser"
                    />
                    {relatedLinks && (
                        <div className={styles['buttons']}>
                            {relatedLinks.map((args, i) => (
                                <div key={'jobs-' + uid + '-link-' + args.id}>
                                    <LinkBase
                                        href={args.uri ?? ''}
                                        external={true}
                                        locale={false}
                                    >
                                        {args.title}
                                    </LinkBase>
                                    {i < linkLength - 1 ? '|' : ''}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Headline
                        {...props}
                        header={title}
                        subheader={datetime}
                        bodytext=""
                        variant="teaser"
                    />
                    <br />
                    <div className="bodytext-container"
                         dangerouslySetInnerHTML={{
                             __html: bodytext as string
                         }}
                    >
                    </div>
                    {relatedLinks && (
                        <div className={styles['buttons']}>
                            {relatedLinks.map((args, i) => (
                                <div key={'jobs-' + uid + '-link-' + args.id}>
                                    <LinkBase
                                        href={args.uri ?? ''}
                                        external={true}
                                        locale={false}
                                    >
                                        {args.title}
                                    </LinkBase>
                                    {i < linkLength - 1 ? '|' : ''}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export { Jobs_Template };
