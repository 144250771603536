import LinkR from 'next/link';
import React, { HTMLAttributes } from 'react';

import styles from './link-base.module.scss';

export interface Props
    extends React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
        HTMLAnchorElement | HTMLButtonElement> {
    href: string;
    locale?: string | false;
    target?: string;
    external?: boolean;
    className?: string;
    scroll?: boolean;
}

export const LinkBase: React.FC<Props> = ({
                                              href,
                                              locale,
                                              target,
                                              children,
                                              onClick,
                                              external,
                                              className,
                                              scroll,
                                              ...anchorProps
                                          }) => {
    if (onClick && !href) {
        return (
            <button
                data-testid="link-base"
                {...(anchorProps as React.DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>,
                    HTMLButtonElement>)}
                className={`${styles['link-base']} ${className}`}
                onClick={
                    onClick as React.DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>,
                        HTMLButtonElement>['onClick']
                }
            >
                {children}
            </button>
        );
    }
    if (external || target)
        return (
            <a
                data-testid="link-base"
                {...(anchorProps as React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>,
                    HTMLAnchorElement>)}
                className={`${styles['link-base']} ${className}`}
                href={href}
                onClick={
                    onClick as React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>,
                        HTMLAnchorElement>['onClick']
                }
                target={target ? target : external ? '_blank' : undefined}
                rel={target === '_blank' ? 'noreferrer' : undefined}
            >
                {children}
            </a>
        );
    else
        return (
            <LinkR href={href} scroll={scroll} locale={locale} passHref>
                <a
                    data-testid="link-base"
                    {...(anchorProps as React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>,
                        HTMLAnchorElement>)}
                    onClick={
                        onClick as React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>,
                            HTMLAnchorElement>['onClick']
                    }
                    className={`${styles['link-base']} ${className}`}
                >
                    {children}
                </a>
            </LinkR>
        );
};

LinkBase.defaultProps = { external: false, scroll: true };
