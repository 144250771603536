import React from 'react';

import {TypoElementMp3item} from '../../../../../api/models/typo/typo-element-mp3-item';
import {AudioBar} from '../../utility/audio-bar/audio-bar';
import {AudioControls} from '../../utility/audio-controls/audio-controls';
import {AudioVolume} from '../../utility/audio-volume/audio-volume';
import styles from './big-template.module.scss';

const BigTemplate = (props: TypoElementMp3item): JSX.Element => {

    const { track, tracks, trackStyling, colorGrading, trackProgress,
        duration, muteVolume, audioRef, isPlaying, volume, setIsPlaying,
        onScrub, setMuteVolume, onScrubEnd, toPrevTrack, toNextTrack,
        setVolume, formatTime } = props;

    const { title, description, image } = track;

    return (
        <div className={styles['audio-player']}>
            <div className={styles['track-info']}>
                {image && (
                    <img
                        className={styles['artwork']}
                        src={image}
                        alt={`${title} cover`}
                    />
                )}
                <h3 className={styles['title']}>{title}</h3>
                <h4 className={styles['description']}>{description}</h4>
                <AudioControls
                    tracks={tracks}
                    isPlaying={isPlaying}
                    onPrevClick={toPrevTrack}
                    onNextClick={toNextTrack}
                    onPlayPauseClick={setIsPlaying}
                />
                <div className={styles['progress-timer']}>
                    <span>{formatTime(audioRef.current?.currentTime)}</span>
                    <span>{formatTime(audioRef.current?.duration)}</span>
                </div>
                <div className={styles['controls']}>
                    <AudioBar 
                        trackProgress={trackProgress} 
                        duration={duration} 
                        onScrub={onScrub} 
                        onScrubEnd={onScrubEnd} 
                        trackStyling={trackStyling} 
                    />
                    <AudioVolume 
                        colorGrading={colorGrading} 
                        muteVolume={muteVolume} 
                        volume={volume} 
                        setVolume={setVolume} 
                        setMuteVolume={setMuteVolume} 
                    />
                </div>
            </div>
        </div>
    );
};

BigTemplate.defaultProps = {};

export { BigTemplate };
