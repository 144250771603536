import { useCurrentLanguage } from '../../api/hooks/useCurrentLanguage.hook';
import { ProductAttribute } from '../_models/product-attribute';

export const selectLanguageForProductAttribute = (
    productAttribute: ProductAttribute | undefined
): string | undefined => {
    const language = useCurrentLanguage?.()?.slice(0, 2) ?? 'en';

    if (!productAttribute) return undefined;

    if (language === 'de') return productAttribute?.translationDE;
    else return productAttribute?.translationEN;
};
