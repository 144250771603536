import { CSSObject } from '@emotion/css/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import escapeRegExp from 'lodash/escapeRegExp';
import React, { useMemo, useState } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import ReactSelect, { components, Styles } from 'react-select';
import {OptionTypeBase} from 'react-select/src/types';

import variables from '../../../themes/variables.module.scss';
import { ValueStringMapping } from '../../../utils/_models/value-string-mapping';
import { ErrorMessage } from '../error/error-message';
import ToolTip from '../tool-tip/tool-tip';
import styles from './select.module.scss';

export interface CustomSelectProps<T> {
    control?: Control<any>;
    value?: ValueStringMapping<T>;
    onChange?: (value: ValueStringMapping<T> & ValueStringMapping<T>[]) => void;
    isMulti?: boolean;
    name: string;
    options: ValueStringMapping<T>[] | undefined;
    fieldToolTip?: string;
    label?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    isOptionDisabled?:(option: OptionTypeBase) => boolean | false;
    errorMessage?: string;
    className?: string;
    inverted?: boolean;
    noOptionsMessage?: string;
    onInputChange?: (value: string) => void;
}
const MAX_DISPLAYED_OPTIONS = 100;

export const Select = <T extends unknown>(
    props: CustomSelectProps<T>
): JSX.Element => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <div
                    className={`${styles['dropdown-indicator']} ${
                        isMenuOpen ? styles['open'] : styles['closed']
                    }`}
                >
                    {isMenuOpen ? (
                        <FontAwesomeIcon icon={['far', 'times']} />
                    ) : (
                        <FontAwesomeIcon icon={['far', 'chevron-down']} />
                    )}
                </div>
            </components.DropdownIndicator>
        );
    };

    const customStyles: Partial<Styles<any, any>> = {
        input: (base: CSSObject) => ({
            ...base,
            color: props?.inverted ? variables.greys_light : base?.color
        }),
        singleValue: (base: CSSObject) => ({
            ...base,
            color: props?.inverted ? variables.greys_light : base.color
        }),
        valueContainer: (base: CSSObject): CSSObject => ({
            ...base,
            height: '55px',
            marginLeft: '8px'
        }),
        control: (base: CSSObject, state): CSSObject => ({
            ...base,
            borderRadius: 0,
            cursor: 'pointer',
            borderColor: props.errorMessage
                ? variables.signalColors_error
                : variables.greys_middleLight,
            boxShadow: 'none',
            backgroundColor: state.isDisabled
                ? variables.greys_lightDark
                : props?.inverted
                ? 'transparent'
                : props?.inverted
                ? 'transparent'
                : base.backgroundColor
        }),
        menu: (base: CSSObject): CSSObject => ({
            ...base,
            zIndex: 50,
            borderRadius: 0
        }),
        menuList: (base: CSSObject): CSSObject => ({
            ...base,
            height: '203px',
            scrollbarWidth: 'thin',
            '::-webkit-scrollbar': {
                width: '9px'
            },
            '::-webkit-scrollbar-track': {
                background: variables.greys_light
            },
            '::-webkit-scrollbar-thumb': {
                background: variables.greys_middleLight
            }
        }),
        dropdownIndicator: (base: CSSObject): CSSObject => ({
            ...base,
            backgroundColor: props?.inverted ? 'white' : variables.greys_dark,
            color: props?.inverted ? 'black' : variables.greys_light,
            clipPath: 'polygon(100% 0%,30% 0%,0% 50%,30% 100%,100% 100%)',
            paddingRight: '8px',
            paddingLeft: '16px',
            paddingTop: '16px',
            height: '100%',
            '&:hover': {
                color: variables.primary_middleDark
            }
        }),
        indicatorSeparator: (base: CSSObject): CSSObject => ({
            ...base,
            visibility: 'hidden'
        }),
        option: (base: CSSObject, state): CSSObject => ({
            ...base,
            paddingTop: '13px',
            paddingBottom: '13px',
            backgroundColor: state.isSelected || state.isDisabled
                ? variables.greys_lightDark
                : variables.greys_light,
            color: state.isSelected || state.isDisabled
                ? variables.greys_dark
                : variables.greys_middleLight,
            '&:hover': {
                backgroundColor: variables.greys_lightDark
            },
            paddingLeft: '16px',
            borderBottom: '1px solid ' + variables.greys_lightDark,
            '&:last-child': {
                borderBottom: 'none'
            }
        }),
        placeholder: (base: CSSObject): CSSObject => ({
            ...base,
            color: variables.greys_middleLight
        })
    };

    const filteredOptions = useMemo(() => {
        if (!inputValue) {
            return props.options;
        }
        const matchByStart = [];
        const matchByInclusion = [];

        const regByInclusion = new RegExp(escapeRegExp(inputValue), 'i');
        const regByStart = new RegExp(`^${escapeRegExp(inputValue)}`, 'i');

        if (props.options) {
            for (const option of props.options) {
                if (regByInclusion.test(option.label)) {
                    if (regByStart.test(option.label)) {
                        matchByStart.push(option);
                    } else {
                        matchByInclusion.push(option);
                    }
                }
            }
        }

        return [...matchByStart, ...matchByInclusion];
    }, [inputValue, props.options]);

    const slicedOptions = useMemo(
        () =>
            filteredOptions && filteredOptions.length > MAX_DISPLAYED_OPTIONS
                ? filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS)
                : filteredOptions,
        [filteredOptions]
    );

    const renderSelectComponent = (
        field?: ControllerRenderProps
    ): JSX.Element => {
        return (
            <ReactSelect
                data-testid="select"
                {...field}
                components={{
                    DropdownIndicator
                }}
                className={styles['select']}
                styles={customStyles}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                options={slicedOptions}
                isOptionDisabled={props.isOptionDisabled ? props.isOptionDisabled : undefined }
                filterOption={() => true}
                value={
                    field
                        ? props.isMulti
                            ? field?.value?.map?.((v: number) => {
                            return props?.options?.find?.((val) => {
                                if (v === Number(val.value)) {
                                    return val;
                                }
                            });
                        }) ?? []
                            : props.options?.filter(
                                  (option) => option.value === field.value
                              )
                        : props.value
                }
                onChange={
                    field
                        ? (values) => {
                              if (props.isMulti) {
                                  field.onChange(
                                      (values as ValueStringMapping[])?.map((value) => Number(value.value))
                                  );
                                  props?.onChange?.(values);
                              } else {
                                  field.onChange(
                                      (values as ValueStringMapping)?.value
                                  );
                                  props?.onChange?.(values);
                              }
                          }
                        : props.onChange
                }
                placeholder={props.placeholder}
                closeMenuOnSelect={!props.isMulti}
                noOptionsMessage={() => props.noOptionsMessage ?? null}
                onInputChange={(value) => {
                    setInputValue(value);
                    if (props.onInputChange) {
                        props.onInputChange(value);
                    }
                }}
                isMulti={props.isMulti}
                isSearchable={props.isSearchable}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
            />
        );
    };

    return (
        <div
            data-testid="select"
            className={`
    ${styles['select-wrapper']}
    ${props.isDisabled ? styles['disabled'] : ''}
    ${props.className ? props.className : ''}
    `}
        >
            <div
                className={
                    props.errorMessage
                        ? styles['input-label-error']
                        : styles['input-label']
                }
            >
                <label>
                    <span>{props.label}</span>
                    <span>
                        {props.fieldToolTip ? (
                            <ToolTip toolTip={props.fieldToolTip} />
                        ) : undefined}
                    </span>
                </label>
            </div>
            {props.control ? (
                <Controller
                    control={props.control}
                    name={props.name}
                    render={({ field }) => renderSelectComponent(field)}
                />
            ) : (
                renderSelectComponent()
            )}
            <ErrorMessage>{props.errorMessage}</ErrorMessage>
        </div>
    );
};
