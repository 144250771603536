import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGlobal } from '../../../api/hooks/useGlobal.hook';
import { addToBasket, resetAddToBasketState, RootState } from '../../../redux';
import { resetWalletModel } from '../../../redux/slices/wallet-slice';
import { ResponseCodes } from '../../../utils/_enums/response-codes.enum';
import { RoutePath } from '../../../utils/_enums/route-path.enum';
import { SalesType } from '../../../utils/_enums/sales-type';
import { mapStrapiHeadline } from '../../../utils/_helper/strapi-mapper';
import { useAppSelector } from '../../../utils/_hooks/hooks';
import { LoginForm } from '../../login/login-form/login-form';
import { Button } from '../button/button';
import Dialog from '../dialog/dialog';
import TextSection from '../text-section/text-section';
import WalletConnectButton from '../wallet-connect-button/wallet-connect-button';

interface LoginDialogProps {
    withBuyOption?: {
        setIdToBuy: (id?: number) => void;
        idToBuy?: number;
        salesType: SalesType;
    }
    onClose?: () => void;
}

const LoginDialog = (props: LoginDialogProps): JSX.Element => {
    const router = useRouter();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(!!props.onClose);
    const data = useGlobal();

    const {
        instanceInBasket,
        loggedIn,
        addToBasketStatus,
        addWalletStatus
    } = useAppSelector((state: RootState) => ({
        instanceInBasket: state.checkoutSlice.basket,
        loggedIn: state.authentification.loggedIn,
        addToBasketStatus: state.checkoutSlice.addToBasketStatus,
        addWalletStatus: state.walletSlice.addWalletModel.status
    }));

    useEffect(() => {
        if (props.withBuyOption && props.withBuyOption.idToBuy) {
            if (loggedIn) {
                dispatch(addToBasket({
                    id: props.withBuyOption.idToBuy,
                    idType: props.withBuyOption.salesType === SalesType.T_DROP
                        ? 'VARIANT'
                        : 'INSTANCE'
                }));
            } else {
                setShowDialog(true);
            }
        }
    }, [props.withBuyOption?.idToBuy, loggedIn]);

    useEffect(() => {
        if (props.withBuyOption && props.withBuyOption.idToBuy && loggedIn && addWalletStatus === ResponseCodes.OK) {
            dispatch(addToBasket({
                id: props.withBuyOption.idToBuy,
                idType: props.withBuyOption.salesType === SalesType.T_DROP
                    ? 'VARIANT'
                    : 'INSTANCE'
            }));
        }
    }, [addWalletStatus]);

    useEffect(() => {
        if (instanceInBasket && props.withBuyOption?.idToBuy) {
            setShowDialog(false);
            router.push(RoutePath.CHECKOUT);
        }
    }, [instanceInBasket]);

    useEffect(() => {
        return () => {
            dispatch(resetAddToBasketState());
        };
    }, []);

    useEffect(() => {
        if (addToBasketStatus === ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL
            || addToBasketStatus === ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL_AND_WALLET) {
            setShowDialog(true);
        }
        if (addToBasketStatus
            && addToBasketStatus !== ResponseCodes.OK
            && addToBasketStatus !== ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL) {
            props.withBuyOption?.setIdToBuy(undefined);
        }
    }, [addToBasketStatus]);

    const onDialogClosed = () => {
        props.withBuyOption?.setIdToBuy(undefined);
        dispatch(resetWalletModel());
        if (props.onClose) {
            props.onClose();
        } else {
            setShowDialog(false);
        }
    };

    const renderDialogComponent = (): JSX.Element | undefined => {
        if (!loggedIn) {
            return <>
                <TextSection
                    title={data?.dialogs?.loginDialogHeadline?.headlineContent || 'Login'}
                    titleVariant={mapStrapiHeadline(data?.dialogs?.loginDialogHeadline?.style)}
                    description={data?.dialogs?.loginDialogDescription?.content}
                />
                <LoginForm withRegisterLink/>
            </>
        } else if (addToBasketStatus === ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL) {
            return <>
                <TextSection
                    title={data?.dialogs?.connectWalletHeadline?.headlineContent || 'Login'}
                    titleVariant={mapStrapiHeadline(data?.dialogs?.connectWalletHeadline?.style)}
                    description={data?.dialogs?.connectWalletDescription?.content}
                />
                <WalletConnectButton/>

            </>
        } else if (addToBasketStatus === ResponseCodes.CHECKOUT_NOT_ON_ALLOWLIST_MAIL_AND_WALLET) {
            return <>
                <TextSection
                    title={data?.dialogs?.allowListNotAllowedHeadline?.headlineContent || 'Login'}
                    titleVariant={mapStrapiHeadline(data?.dialogs?.allowListNotAllowedHeadline?.style)}
                    description={data?.dialogs?.allowListNotAllowed?.content}
                />
                <Button onClick={() => {
                    setShowDialog(false);
                }}
                        variant={data?.buttons.confirmation.buttonStyle}
                >{data?.buttons.confirmation.buttonText}</Button></>
        } else if (addToBasketStatus === ResponseCodes.CHECKOUT_MAX_INSTANCES_BOUGHT) {
            return <>
                <TextSection
                    title={data?.dialogs?.maxInstancesBoughtHeadline?.headlineContent || 'Login'}
                    titleVariant={mapStrapiHeadline(data?.dialogs?.maxInstancesBoughtHeadline?.style)}
                    description={data?.dialogs?.maxInstancesBought?.content}
                />
                <Button
                    onClick={() => {
                        setShowDialog(false);
                    }}
                    variant={data?.buttons.confirmation.buttonStyle}
                >{data?.buttons.confirmation.buttonText}</Button>
            </>
        }
    }

    return (
        <div>
            {showDialog
                ? <Dialog onClose={onDialogClosed}>
                    {renderDialogComponent()}
                </Dialog>
                : undefined}
        </div>
    )
};

export default LoginDialog;
