import React from 'react';

import {TypoFieldMultirow} from '../../../../api/models/typo/field/typo-field-multirow';
import {getMediaType, Media} from '../../../_shared/media/media';
import styles from './multirow.module.scss';

const Multirow = (props: TypoFieldMultirow): JSX.Element => {

    const {
        images, rowsDesktop, rowsTablet, rowsMobile, galleryOptions, inverted
    } = props;

    const elementsPerRow = (cols: number) => {
        if (cols < 1) {
            cols = 1;
        }
        return cols;
    }
    
    return (
        <div className={styles['typoimage-container']}>
            {images && images.map((image, i) => {
                {
                    if (image.publicUrl) {
                        return (
                            <div key={i} className={`
                                ${styles['multi-item-container']} 
                                ${styles['multi-item-row-' + elementsPerRow(rowsDesktop)]}
                                ${styles['mobile-multi-item-row-' + elementsPerRow(rowsMobile)]}
                                ${styles['tablet-multi-item-row-' + elementsPerRow(rowsTablet)]}
                            `}>
                                <Media
                                    mediaId={i}
                                    contentType={getMediaType(image.properties.mimeType)}
                                    src={image.publicUrl}
                                    alt={image.properties.alternative ?? ''}
                                    caption={image.properties.description ?? ''}
                                    title={image.properties.title ?? ''}
                                    redirectUrl={image.properties.linkData?.href}
                                    linkClassName={image.properties.linkData?.class}
                                    imageEffect={galleryOptions?.imageEffect}
                                    enlargeOnClick={galleryOptions?.enlargeOnClick}
                                    inverted={inverted}
                                />
                            </div>
                        );
                    }
                }
            })}
        </div>  
    );
};

Multirow.defaultProps = {};

export { Multirow };
