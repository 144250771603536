import { useLanguageDependentDataSSr } from './useLanguageDependentDataSsr.hook';

export const useAdminHeader = useLanguageDependentDataSSr<UseHeaderData>('admin-header');

interface UseHeaderData {
    navLinks: {
        label: string;
        link: string;
        external: boolean;
        role: string;
    }[];
}
