import React from 'react';

export interface Props {
    id?: string;
    width?: string;
    height?: string;
}

export const Spacer: React.FC<Props> = ({ width, height, id }) => {
    return <div id={id} style={{ width, height }}></div>;
};

Spacer.defaultProps = { width: 'auto', height: 'auto' };
