import { useCurrentLanguage } from '../../api/hooks/useCurrentLanguage.hook';
import { RoutePath } from '../_enums/route-path.enum';

const generateUrlToDetailedPage = (
    productName: string,
    variantTitle: string,
    productId: number,
    variantId: number,
    instanceId?: number,
    isFromProductsPage?: boolean,
    abs?: boolean,
    toSalesBox?: boolean
): string => {
    let url = '';

    const productTitleString = productName?.replaceAll?.(' ', '-');
    const variantTitleString = variantTitle?.replaceAll?.(' ', '-');

    if (typeof window !== 'undefined') {
        const absolutePrefix = `${window?.location?.protocol}//${
            window?.location?.hostname
        }${window?.location?.port ? ':' : ''}${
            window?.location?.port
        }/${useCurrentLanguage()}`;

        url = `${abs ? absolutePrefix : ''}${
            !isFromProductsPage ? RoutePath.PRODUCTS : ''
        }/${productTitleString}-${variantTitleString}-${productId}-${variantId}/${
            instanceId ? instanceId : ''
        }${toSalesBox ? '#salesbox' : ''}`;
    }

    return url;
};

export { generateUrlToDetailedPage };
