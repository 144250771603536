import React from 'react';

interface Props
    extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height'> {
    size?: string;
}

const CreatokenSVGComponent: React.FC<Props> = ({fill, size, ...props}) => {
    return (
        <svg
            id="Creatoken"
            xmlns="http://www.w3.org/2000/svg"
            width={135}
            height={140.295}
            viewBox="0 0 144.46 139.008"
            {...props}
        >
            <defs>
                <style>{'\n      .cls-1 {\n        fill: #fff;\n      }\n    '}</style>
            </defs>
            <path
                id="Path_1427"
                data-name="Path 1427"
                className="cls-1"
                d="M83.4,0H27.8L0,48.153,27.8,96.307H83.4l27.8-48.153Z"
                transform="translate(0 55.603) rotate(-30)"
            />
            <path
                id="Path_1747"
                data-name="Path 1747"
                d="M70.024,0H23.341L0,40.428,23.341,80.856H70.024L93.365,40.428Z"
                transform="translate(11.945 58.163) rotate(-30)"
            />
            <g
                id="Group_16615"
                data-name="Group 16615"
                transform="translate(46.508 54.712)"
            >
                <g
                    id="Group_16618"
                    data-name="Group 16618"
                    transform="translate(-284.277 -2345.048)"
                >
                    <g
                        id="Group_16618-2"
                        data-name="Group 16618"
                        transform="translate(0 10)"
                    >
                        <ellipse
                            id="Ellipse_3"
                            data-name="Ellipse 3"
                            className="cls-1"
                            cx={1.869}
                            cy={1.869}
                            rx={1.869}
                            ry={1.869}
                            transform="translate(311.311 2333.585)"
                        />
                        <path
                            id="Path_109"
                            data-name="Path 109"
                            className="cls-1"
                            d="M3938.809,2400.6l-4.046,2.923.272.167,7.8-4.773,3.983,2.437.111-.113-2.007-1.826,3.551-3.656,6.278,7.347-5.991,3.878.219.176,7.364-4.238,2.7,2.894,2.307-1.365,7.692,7.638-55.553-.006,8.855-10.659,3.615,3.374.194-.316-1.884-2.188,7.846-11.589Z"
                            transform="translate(-3629.574 -55.246)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

CreatokenSVGComponent.defaultProps = {
    fill: '#fff'
};

export default CreatokenSVGComponent;
