import React from 'react';

import {TypoElementParallax} from '../../../api/models/typo/typo-element-parallax';
import {ParallaxContainer} from './container/Parallax';
import styles from './parallax.module.scss';

const Parallax = (props: TypoElementParallax): JSX.Element => {
    const {
        containerSize,
        scrollStrength,
        inverted,
        blur,
        imageUrl,
        imageAlt,
        className,
        id,
        children,
        height,
        style,
        withTextBackground,
        fullwidth
    } = props;

    const invertedClass = inverted ? styles.inverted : '';
    const textBackgroundClass = withTextBackground ? styles.withBackground : '';
    const fullwidthClass = fullwidth ? styles.fullwidth : '';

    if (imageUrl && imageUrl !== '') {
        return (
            <ParallaxContainer
                key={`typo-parallax-${id}`}
                containerSize={containerSize}
                scrollStrength={scrollStrength}
                blur={blur}
                className={className}
                image={imageUrl}
                imageAlt={imageAlt}
                height={height}
                style={style}
                contentClassName={`${styles.content} ${invertedClass} ${textBackgroundClass} ${fullwidthClass}`}
            >
                {typeof children === 'string' ? (
                    <div id={`c${id}`} className={`bodytext-container ${styles.wrapper}`}
                         dangerouslySetInnerHTML={{__html: children}}/>
                ) : (
                    <div id={`c${id}`} className={`bodytext-container ${styles.wrapper}`}>
                        {children}
                    </div>
                )}
            </ParallaxContainer>
        )
    } else return (<></>)
}

export { Parallax };