import {LookupTableBaseModel} from '../models/lookup-table-base-model';
import {useCurrentLanguage} from './useCurrentLanguage.hook';

export const mapLookupToLanguageHook = <T extends LookupTableBaseModel[]>(data: T) => {
    if(data){
        const language = useCurrentLanguage?.()?.slice(0, 2) ?? 'en';
        return data.map(item => {
            return {
                ...item,
                text: language === 'de' ? item.trans_de : item.trans_en
            }
        }).filter(item => !!item.text);
    }
    return [];
}
