import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import {useHeader} from '../../../../../api/hooks/useHeader.hook';
import {useLoginPage} from '../../../../../api/hooks/useLoginPage.hook';
import {LoginData} from '../../../../../api/models/login-data';
import {login, RootState, useAppSelector} from '../../../../../redux';
import {DefaultHeaderMobileLoginFormContextEnum} from '../../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';
import {LinkVariants} from '../../../../../utils/_enums/link-variants.enum';
import {RoutePath} from '../../../../../utils/_enums/route-path.enum';
import {useDefaultHeaderMobileLoginContext} from '../../../../../utils/_hooks/useDefaultHeaderMobileLoginContext.hook';
import {Link} from '../../../link/link';
import {HeaderMobileForm} from '../../header-mobile/header-mobile-form/header-mobile-form';
import styles from './default-header-mobile-login-form.module.scss';

export const DefaultHeaderMobileLoginForm: React.FC = () => {
    const data = useHeader();

    const loginData = useLoginPage();

    if (!data || !loginData) return null;

    const {
        loginButton,
        emailOrUsername,
        password,
        forgotPassword
    } = loginData;

    const {
        mobileLoginFormTitle,
        mobileLoginFormErrorMessage,
        registerLinkText
    } = data;
    const dispatch = useDispatch();

    const {
        loginFormOpen,
        dispatch: contextDispatch
    } = useDefaultHeaderMobileLoginContext();

    const schema = yup.object({
        emailOrUsername: yup
            .string()
            .required(emailOrUsername?.fieldErrorMessages?.required ?? ' '),
        password: yup
            .string()
            .required(password?.fieldErrorMessages?.required ?? ' ')
    });
    const resolver = yupResolver(schema);

    const {loggedIn, pending} = useAppSelector(
        (state: RootState) => state.authentification
    );

    const dataSubmitted = (data: LoginData) => {
        dispatch(login(data));
    };

    useEffect(() => {
        if (loggedIn) {
            contextDispatch({
                type: DefaultHeaderMobileLoginFormContextEnum.RESET
            });
        }
    }, [loggedIn]);

    return (
        <HeaderMobileForm
            globalError={{message: mobileLoginFormErrorMessage ?? ''}}
            open={loginFormOpen ?? false}
            className={`${styles['default-header-mobile-login-form']} ${styles['showOnlyLeastError']}`}
            formConfig={{
                resolver,
                mode: 'onChange'
            }}
            onCancelClick={() =>
                contextDispatch({
                    type:
                    DefaultHeaderMobileLoginFormContextEnum.TOGGLELOGINFORM
                })
            }
            fields={[
                {
                    name: 'emailOrUsername',
                    variant: 'input',
                    placeholder: emailOrUsername?.fieldHint ?? '',
                    title: emailOrUsername?.fieldLabel ?? ''
                },
                {
                    name: 'password',
                    variant: 'input',
                    placeholder: password?.fieldHint ?? '',
                    title: password?.fieldLabel ?? '',
                    type: 'password'
                }
            ]}
            forgotPassword={forgotPassword?.linkText ?? ''}
            submitButtonProps={{
                children: loginButton?.buttonText ?? '',
                pending: pending ?? false
            }}
            formtitle={mobileLoginFormTitle}
            onSubmit={dataSubmitted as any}
        >
            <div className={styles['register-link-container']}>
                <Link
                    variant={LinkVariants.READ_MORE}
                    key={'register-link'}
                    className={styles.registerLink}
                    href={RoutePath.REGISTER}
                >
                    {registerLinkText ?? ''}
                </Link>
            </div>
        </HeaderMobileForm>
    );
};
