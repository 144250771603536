import 'animate.css';

import React from 'react';
import { useInView } from 'react-intersection-observer';

import { TypoElementTextimg } from '../../../api/models/typo/typo-element-textimg';
import { HorizontalPosition } from '../../../utils/_enums/horizontal-position.enum';
import { Headline } from '../_shared/headline/headline';
import {Multirow} from '../_shared/multirow/multirow';
import { TypoButtons } from '../_shared/typo_buttons/typo_buttons';
import styles from './textimg.module.scss';

const Textimg = (props: TypoElementTextimg): JSX.Element => {

    const {
        id,
        inverted,
        gallery,
        images,
        headerPosition,
        buttons,
        textAnimationClass,
        textAnimationSpeedClass,
        imageAnimationClass,
        imageAnimationSpeedClass,
        columnsMobile,
        columnsTablet,
        fullwidth,
        enlargeImageOnClick,
        wrapper,
        imageEffect,
        withSubline
    } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const imagePosition = headerPosition ? headerPosition.toLowerCase() : '';

    const textClass = (imagePosition: string) => {
        if (imagePosition === HorizontalPosition.LEFT) {
            return styles['left-wrapper']
        } else if (imagePosition === HorizontalPosition.CENTER) {
            return styles['center-wrapper']
        } else {
            return styles['right-wrapper']
        }
    }

    const imageClass = (imagePosition: string) => {
        if (imagePosition === HorizontalPosition.LEFT) {
            return styles['right-wrapper-img']
        } else if (imagePosition === HorizontalPosition.CENTER) {
            return styles['center-wrapper-img']
        } else {
            return styles['left-wrapper-img']
        }
    }

    const { ref, inView } = useInView({
        /* Optional options */
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['text-image-teaser']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
                ${imagePosition === HorizontalPosition.CENTER ? styles['text-image-center'] : ''}
            `}
            ref={textAnimationClass !== '' || imageAnimationClass !== '' ? ref : undefined}>
            <div
                className={`
                    ${textClass(imagePosition)}
                    ${textAnimationClass !== '' && inView ? `animate__animated animate__${textAnimationClass}` : ''}
                    ${textAnimationSpeedClass !== '' && inView ? `animate__${textAnimationSpeedClass}` : ''}
                `}
            >
                <Headline {...props} noHeadlineSpacing={true} noTextAlignment={true} variant="teaser" />
                <TypoButtons inverted={inverted} buttons={buttons} />
            </div>
            <div
                className={`
                    ${imageClass(imagePosition)}
                    ${imageAnimationClass !== '' && inView ? `animate__animated animate__${imageAnimationClass}` : ''}
                    ${imageAnimationSpeedClass !== '' && inView ? `animate__${imageAnimationSpeedClass}` : ''}
                `}
            >
                {images && (
                    <div className={styles['images']}>
                        <Multirow 
                            images={images} 
                            rowsDesktop={gallery.count.columns} 
                            rowsTablet={columnsTablet} 
                            rowsMobile={columnsMobile}
                            galleryOptions={{
                                imageEffect: {
                                    effect: imageEffect ?? '',
                                    withSubline: withSubline ?? false
                                },
                                enlargeOnClick: enlargeImageOnClick
                            }}
                            inverted={inverted} 
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

Textimg.defaultProps = {};

export { Textimg };
