import { CSSObject } from '@emotion/css/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/dist/client/router';
import React, { useState } from 'react';
import ReactSelect, { components, Styles } from 'react-select';

import { useCurrentLanguage } from '../../../api/hooks/useCurrentLanguage.hook';
import variables from '../../../themes/variables.module.scss';
import { ArticlePageTypes } from '../../../utils/_enums/article-page-types';
import { useMounted } from '../../../utils/_hooks/useMounted.hook';
import { LanguageSelectorOptions } from '../../../utils/_models/languageSelectorOptions';
import styles from './language-selector.module.scss';

interface LanguageSelectorProps {
    languageOptions: LanguageSelectorOptions[];
    isStaticPage?: boolean;
    isInverted?: boolean;
    pageType?: string;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const currLang = useCurrentLanguage();
    const router = useRouter();
    const isMounted = useMounted();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const changeLanguage = (lang: {value: string, label: string}) => {
        const locale: string = lang?.value ?? '';
        if (props.isStaticPage) {

            // Typo3 Routing
            if (router.query.typoPage !== undefined || router.pathname === '/page') {
                const { pathname, asPath, query } = router;
                router.push({ pathname, query }, asPath, { locale: lang.label.toLowerCase() });
                return;
            }

            const pageTypePrefix: string = props.pageType === ArticlePageTypes.CONFIRMATION_PAGE ? '/c/' : '/s/';
            const queryString = props.pageType === ArticlePageTypes.CONFIRMATION_PAGE ? router.query.confirmationPage : router.query.articlePage;
            router.push(
                { pathname: pageTypePrefix + queryString + '/' + locale },
                { pathname: pageTypePrefix + queryString + '/' + locale },
                { locale: props.languageOptions ? props.languageOptions.find(it => it.id.toString() === locale)?.locale : 'en' }
            );
        } else if (locale !== 'en') {
            router.push(router.asPath, router.asPath, { locale });
        } else {
            router.push(router.asPath, router.asPath, { locale: 'en' });
        }
    }

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <div
                    className={`${styles['dropdown-indicator']} ${isMenuOpen ? styles['open'] : styles['closed']
                        }`}
                >
                    {isMenuOpen ? (
                        <FontAwesomeIcon icon={['far', 'chevron-up']} />
                    ) : (
                        <FontAwesomeIcon icon={['far', 'chevron-down']} />
                    )}
                </div>
            </components.DropdownIndicator>
        );
    };

    const customStyles: Partial<Styles<any, any>> = {
        singleValue: (base: CSSObject): CSSObject => ({
            ...base,
            color: props.isInverted ? variables.greys_dark : variables.greys_light,
        }),
        control: (base: CSSObject): CSSObject => ({
            ...base,
            width: '64px',
            borderRadius: 0,
            cursor: 'pointer',
            borderColor: props.isInverted ? variables.greys_dark : variables.greys_light,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:hover *': {
                color: variables.primary_middleDark
            }
        }),
        menu: (base: CSSObject): CSSObject => ({
            ...base,
            marginBottom: 'unset',
            marginTop: 'unset',
            zIndex: 50,
            boxShadow: 'unset',
            backgroundColor: 'transparent',
        }),
        menuList: (base: CSSObject): CSSObject => ({
            ...base,
            paddingTop: 'unset',
            paddingBottom: 'unset',
            backgroundColor: 'transparent',
        }),
        dropdownIndicator: (base: CSSObject): CSSObject => ({
            ...base,
            color: props.isInverted ? variables.greys_dark : variables.greys_light,
        }),
        indicatorSeparator: (base: CSSObject): CSSObject => ({
            ...base,
            visibility: 'hidden'
        }),
        option: (base: CSSObject): CSSObject => ({
            ...base,
            backgroundColor: 'transparent',
            cursor: 'pointer',
            marginTop: '-12px',
            marginLeft: '-2px',
            color: props.isInverted ? variables.greys_dark : variables.greys_light,
            '&:hover': {
                color: variables.primary_middleDark
            },
            '&:active': {
                backgroundColor: 'transparent',
            }
        })
    };

    if (isMounted.hasMounted) {
        return (
            <div className={styles['language-switch-wrapper']}>
                <ReactSelect
                    onChange={lang => changeLanguage(lang ?? '')}
                    options={props.languageOptions.map(lang => {
                        return {
                            value: props.isStaticPage ? lang.id.toString() : lang.locale,
                            label: lang.locale.toUpperCase()
                        }
                    })}
                    components={{
                        DropdownIndicator
                    }}
                    onMenuOpen={() => setIsMenuOpen(true)}
                    onMenuClose={() => setIsMenuOpen(false)}
                    styles={customStyles}
                    isSearchable={false}
                    defaultValue={{
                        value: currLang,
                        label: currLang.toUpperCase()
                    }}
                />
            </div>
        )
    } else {
        return null;
    }

}

export default LanguageSelector;
