import { useEffect, useState } from 'react';

//@ts-ignore
import {
    desktop,
    desktopSmall,
    mobile
} from '../../themes/variables.module.scss';
import { isServer } from '../_helper/isServer';

const removePxAndConvertToNumber = (value: string): number => {
    const length = value.length;

    const newValue = value.slice(0, length - 2);

    return Number(newValue);
};

const getDeviceConfig = (
    width: number
): 'tablet' | 'mobile' | 'desktop' | 'desktopSmall' => {
    const desktopNumber = removePxAndConvertToNumber(desktop);
    const desktopSmallNumber = removePxAndConvertToNumber(desktopSmall);
    const mobileNumber = removePxAndConvertToNumber(mobile);

    if (width <= mobileNumber) {
        return 'mobile';
    } else if (width >= desktopNumber) {
        return 'desktop';
    } else if (width >= desktopSmallNumber && width < desktopNumber) {
        return 'desktopSmall';
    } else {
        return 'tablet';
    }
};

const useBreakpoint = (): 'tablet' | 'mobile' | 'desktop' | 'desktopSmall' => {
    if (isServer()) return 'desktop';

    const [brkPnt, setBrkPnt] = useState(() =>
        getDeviceConfig(window.innerWidth)
    );

    useEffect(() => {
        const calcInnerWidth = function () {
            setBrkPnt(getDeviceConfig(window.innerWidth));
        };
        window.addEventListener('resize', calcInnerWidth, { passive: true });
        calcInnerWidth();
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
};
export default useBreakpoint;
