import React from 'react';

import styles from './seperator.module.scss';

export interface Props {
    margin?: string;
    width?: string;
    height?: string;
    color?: string;
}

export const Seperator: React.FC<Props> = ({
    margin,
    height,
    width,
    color
}) => {
    return (
        <div
            data-testid="seperator"
            className={styles.seperator}
            style={{ width, margin, height, backgroundColor: color ?? 'black' }}
        />
    );
};

Seperator.defaultProps = { margin: '0', height: '0px', width: 'auto' };
