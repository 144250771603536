import React from 'react';

import { TypoElementTextpic } from '../../../api/models/typo/typo-element-textpic';
import {Textimg} from '../textimg/textimg';

const Textpic = (props: TypoElementTextpic): JSX.Element => {
  return (
      <Textimg {...props} />
  );
};

export { Textpic };
