import {AxiosResponse} from 'axios';
import {useQuery, UseQueryResult} from 'react-query';

import { backend } from '../../utils/_config/axios';

export const useLanguageIndependentData = <T>(
    basepath: string
) => (): UseQueryResult<AxiosResponse<T>, unknown> => {
    return useQuery(
        [basepath],
        async ({queryKey: [_]}) =>
            await backend().get<T>(`api/strapi/${basepath}`)
    );
};
