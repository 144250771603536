import React from 'react';

import { TypoElementTextmedia } from '../../../api/models/typo/typo-element-textmedia';
import {Textimg} from '../textimg/textimg';

const Textmedia = (props: TypoElementTextmedia): JSX.Element => {
  return (
      <Textimg {...props} />
  );
};

export { Textmedia };
