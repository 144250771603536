import React from 'react';

export const MenuIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" width="21.1" height="11.872" 
            viewBox="0 0 21.1 11.872"
            {...props}
            >
                <g id="menu-bar-ico" data-name="menu bar-ico" 
                transform="translate(-1 11.872) rotate(-90)"
                >
                    <path  d="M0,0,1.545,1.03V19.089H0Z" 
                    transform="translate(10.327 3.009)" fill="#fff"/>
                    <path d="M0,1l1.545.949V22.1H0Z" 
                    transform="translate(5.164)" fill="#fff"/>
                    <path d="M0,1l1.545.949V16.035H0Z" 
                    transform="translate(0 6.063)" fill="#fff"/>
                </g>
            </svg>
    )
}
