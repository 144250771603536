import React from 'react';

export const LogoHorizontalSVGComponent: React.FC<
    React.SVGProps<SVGSVGElement>
> = (props) => {
    return (
        <svg viewBox="0 0 501 162" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M220.325 107.36C223.084 107.36 225.884 106.658 228.004 105.435H228.007C229.737 104.531 231.329 103.194 232.607 101.928C232.594 101.947 236.379 105.613 236.379 105.613L235.962 106.072C231.876 110.554 226.753 112.827 220.734 112.827H220.003C214.381 112.827 209.488 110.73 205.459 106.596C201.441 102.476 199.405 97.5761 199.405 92.0064C199.405 86.4367 201.476 81.5608 205.559 77.5192C209.64 73.4776 214.695 71.4285 220.65 71.4285C226.604 71.4285 231.751 73.6963 235.94 78.1645L236.336 78.6072C236.336 78.6072 232.385 82.4518 232.399 82.4788C231.264 81.4798 228.99 79.598 227.549 78.7827C225.307 77.5139 222.943 76.8956 220.325 76.8956C216.296 76.8956 212.732 78.4128 209.732 81.4042C206.732 84.3956 205.21 88.0026 205.21 92.1279C205.21 96.2532 206.732 99.8601 209.732 102.852C212.732 105.843 216.296 107.36 220.325 107.36Z" fill="white"/>
            <path d="M279.446 72.2762H302.272V77.3572H285.283V89.3741H301.21V94.4038H285.283V106.844H302.54V111.98H279.446V72.2762Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M321.35 72.2762H328.889L342.162 111.98H335.896L332.764 101.974H317.313L314.126 111.98H308.074L321.35 72.2762ZM318.962 96.839H331.12L325.174 77.9944H324.963L318.962 96.839Z" fill="white"/>
            <path d="M341.569 77.3572H352.186V111.977H358.027V77.3572H368.647V72.2762H341.569V77.3572Z" fill="white"/>
            <path d="M453.862 72.2762H459.702V111.98H453.862V72.2762Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M479.656 72.0888H487.194L500.467 111.792H494.202L491.069 101.787H475.618L472.431 111.792H466.38L479.656 72.0888ZM477.267 96.6516H489.425L483.479 77.8069H483.268L477.267 96.6516Z" fill="white"/>
            <path d="M402.353 85.8374L406.755 81.496H406.758C408.434 84.5522 409.371 88.1618 409.371 92.1279C409.371 104.093 401.034 112.827 389.141 112.827C377.249 112.827 368.86 104.093 368.86 92.1279C368.86 80.1624 377.409 71.4285 389.141 71.4285C393.82 71.4285 397.982 72.8216 401.294 75.2514L397.359 79.1445C395.098 77.5327 392.301 76.6175 389.139 76.6175C380.696 76.6175 374.855 83.0214 374.855 92.0738C374.855 101.126 380.696 107.53 389.139 107.53C397.581 107.53 403.422 101.126 403.422 92.0738C403.422 89.8169 403.046 87.7191 402.353 85.8374Z" fill="white"/>
            <path d="M261.561 95.6727C267.347 94.3498 271.171 89.8492 271.171 84.1337C271.171 77.4113 265.701 72.2222 258.057 72.2222H243.351V111.926H249.192V77.3032H257.475C262.306 77.3032 265.227 80.3729 265.227 84.185C265.227 87.9971 262.308 91.1208 257.475 91.1208H252.36L255.247 96.0453L264.856 111.926H271.599L261.564 95.6727H261.561Z" fill="white"/>
            <path d="M444.597 111.791H437.715L426.122 94.5337H421.993V111.791H416.17V72.0888H421.993V89.5048H426.175L437.715 72.0888H444.438L430.939 91.9928L444.597 111.791Z" fill="white"/>
            <path d="M133.756 91.6545C137.469 91.6545 140.479 88.6496 140.479 84.9429C140.479 81.2362 137.469 78.2313 133.756 78.2313C130.043 78.2313 127.033 81.2362 127.033 84.9429C127.033 88.6496 130.043 91.6545 133.756 91.6545Z" fill="white"/>
            <path d="M201.095 134.285L192.799 139.184L183.135 128.86L183.078 128.798L156.597 144.017L155.813 143.384L177.351 129.47H177.36L177.351 129.453V43.3115L177.355 42.0603L165.711 46.1544V0H0V104.936L0.00885802 105.692L11.635 101.603V161.706H228.754L201.09 134.29L201.095 134.285ZM10.9795 10.9561H154.741V50.0187L10.9795 100.577V10.9561ZM149.231 122.763L148.832 123.165L134.509 114.416L106.465 131.553L105.486 130.951L120.035 120.46L95.9634 84.9297L67.7462 126.535L74.5181 134.391L73.8183 135.528L60.8192 123.413L38.0762 150.746H22.619V97.7383L166.38 47.1669V116.622L154.785 103.079L142.016 116.202L149.236 122.759L149.231 122.763Z" fill="white"/>
        </svg>
    );
};
