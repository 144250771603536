import {StrapiHeadlineStyles} from '../_enums/strapi-headline-styles.enum';
import {TypographyVariantsEnum} from '../_enums/typeography-variants.enum';

const mapStrapiHeadline = (headlineString?: StrapiHeadlineStyles): TypographyVariantsEnum => {
    switch (headlineString) {
        case StrapiHeadlineStyles.H1:
            return TypographyVariantsEnum.HEADING1;
        case StrapiHeadlineStyles.H2:
            return TypographyVariantsEnum.HEADING2_BOLD;
        case StrapiHeadlineStyles.H3:
            return TypographyVariantsEnum.HEADING3_BOLD;
        case StrapiHeadlineStyles.H4:
            return TypographyVariantsEnum.HEADING4_BOLD;
        case StrapiHeadlineStyles.H5:
            return TypographyVariantsEnum.HEADING5;
        case StrapiHeadlineStyles.H6:
            return TypographyVariantsEnum.HEADING6;
        case StrapiHeadlineStyles.H2_LIGHT:
            return TypographyVariantsEnum.HEADING2_LIGHT;
        case StrapiHeadlineStyles.H3_LIGHT:
            return TypographyVariantsEnum.HEADING3_LIGHT;
        case StrapiHeadlineStyles.H4_LIGHT:
            return TypographyVariantsEnum.HEADING4_LIGHT;
        default:
            return TypographyVariantsEnum.HEADING6;
    }
}

export {mapStrapiHeadline}