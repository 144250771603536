import React, { CSSProperties } from 'react';

import { TypoFieldHeadline } from '../../../../api/models/typo/field/typo-field-headline';
import { BasicCardVariants } from '../../../../utils/_enums/basic-card-variants.enum';
import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import TeaserCardText from '../../../_shared/teaser-card-components/teaser-card-text/teaser-card-text';
import { Typography } from '../../../_shared/typography/typography';
import { Link } from '../link/link';
import styles from './headline.module.scss';

const Headline = (props: TypoFieldHeadline): JSX.Element => {

    const {
        inverted, headerPosition, headerClass, headerLink, headerLayout, header, noHeadlineSpacing, noContentSpacing,
        noTextAlignment, subheader, variant, bodytext, contentTextPosition, subHeaderClass
    } = props;

    if (!header) {
        return <div />;
    }

    if (headerLayout == 100 && variant !== 'teaser') {
        return <div />;
    }

    const variantHeader = () => {
        switch (headerLayout) {
            case 11: {
                return TypographyVariantsEnum.HEADING1;
            }
            case 12: {
                return TypographyVariantsEnum.HEADING2_BOLD;
            }
            case 13: {
                return TypographyVariantsEnum.HEADING3_BOLD;
            }
            case 14: {
                return TypographyVariantsEnum.HEADING4_BOLD;
            }
            case 15: {
                return TypographyVariantsEnum.HEADING5;
            }
            case 16: {
                return TypographyVariantsEnum.HEADING6;
            }
            default: {
                return TypographyVariantsEnum.HEADING3_BOLD
            }
        }
    }

    const variantSubHeader = () => {
        switch (headerLayout) {
            case 11: {
                return TypographyVariantsEnum.HEADING2_BOLD;
            }
            case 12: {
                return TypographyVariantsEnum.HEADING3_BOLD;
            }
            case 13: {
                return TypographyVariantsEnum.HEADING4_BOLD;
            }
            case 14: {
                return TypographyVariantsEnum.HEADING5;
            }
            case 15: {
                return TypographyVariantsEnum.HEADING6;
            }
            case 16: {
                return TypographyVariantsEnum.HEADING6;
            }
            default: {
                return TypographyVariantsEnum.HEADING4_BOLD
            }
        }
    }

    const headerAlignment = headerPosition ? 'text-' + headerPosition : '';
    const hasHeaderLink = headerLink !== undefined && headerLink !== null;
    const contentAlignment = contentTextPosition ? {
        textAlign: contentTextPosition,
        display: 'block',
        width: '100%',
        paddingBottom: '0'
    } as CSSProperties : { paddingBottom: '0' } as CSSProperties;
    const headlineStyle = noHeadlineSpacing ? {
        paddingBottom: '0'
    } as CSSProperties : undefined;

    const getContent = (): JSX.Element => {
        if (!header || headerLayout == 100) {
            return <></>
        }

        if (hasHeaderLink && headerLink !== null && headerLink !== '') {
            if (typeof headerLink !== 'string') {
                return (
                    <Link
                        target={headerLink.target ?? ''}
                        href={headerLink.href}
                        title={headerLink.title ?? ''}
                        class={headerLink.class ?? ''}
                    >
                        {getTypography()}
                    </Link>
                )
            } else {
                return (
                    <Link
                        href={headerLink}
                    >
                        {getTypography()}
                    </Link>
                )
            }
        } else {
            return getTypography()
        }
    }

    const getTypography = (): JSX.Element => {
        if (!header || headerLayout == 100) {
            return <></>
        }

        if (variant === 'teaser') {
            return (
                <TeaserCardText
                    headline={header}
                    topline={subheader}
                    headlineStyle={contentAlignment}
                    toplineStyle={contentAlignment}
                    headlineVariant={variantHeader()}
                    subHeadlineVariant={variantSubHeader()}
                    variant={BasicCardVariants.TEXT_IMAGE_TEASER}
                    inverted={inverted}
                />
            )
        } else {
            return (
                <>
                    <Typography variant={variantHeader()} style={headlineStyle} className={headerClass}
                                inverted={inverted}>{header}</Typography>
                    {subheader ? (
                        <Typography variant={variantSubHeader()} className={`${styles['subheader']} ${subHeaderClass}`}
                                    inverted={inverted}>{subheader}</Typography>) : <></>}
                </>
            )
        }
    }

    return (
        <div className={`
            ${styles['headline-section']}
            ${inverted ? styles['inverted'] : ''}
            ${noTextAlignment ? '' : styles[headerAlignment]}
        `}>
            <div className={`${styles['headline']} ${(variant && variant === 'teaser' || noContentSpacing) ? styles['nospace'] : ''}`}>
                {getContent()}
            </div>
            {variant === 'teaser' && (
                <TeaserCardText
                    description={bodytext}
                    variant={BasicCardVariants.TEXT_IMAGE_TEASER}
                    inverted={inverted}
                />
            )}
        </div>
    );
};

Headline.defaultProps = {
    variantHeader: TypographyVariantsEnum.HEADING2_BOLD,
    variantSubHeader: TypographyVariantsEnum.HEADING3_BOLD,
    headerPosition: 'left',
    inverted: false
};

export { Headline };
