import * as React from 'react';
import {SVGProps} from 'react';

const ColorPaletteSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20.176}
        height={20.219}
        {...props}
    >
        <g data-name="Gruppe 18303">
            <path
                data-name="Vereinigungsmenge 25"
                d="M12.321 18.537c1.668-.512 3.768-1.8 3.716-4.037.343-.322 1.479-1.467 1.479-1.467.845 4.651-1.923 6.7-5.92 7.186a5.114 5.114 0 0 0 .725-1.682ZM3.7 18c3.489.312 2.325-3.462 5.554-3.5l.888 1.7c.2 2.6-1.256 3.827-2.923 3.827A4.343 4.343 0 0 1 3.7 18ZM0 9.768C-.037 4.314 3.983.052 9.019.007a9.668 9.668 0 0 1 6.733 2.322L14.5 3.556a9.841 9.841 0 0 0-5.481-1.793c-3.772-.128-7.235 3.47-7.2 8a7.328 7.328 0 0 0 1.831 5.556l-1.713.793A10.367 10.367 0 0 1 0 9.768Zm10.79 5.305-.649-1.408c1.474-1.491 10.035-11.9 10.035-11.9V4.2S15 11.585 13.912 13.427a4.989 4.989 0 0 1-3.012 1.878ZM4.593 13.2l-.7-1.208.7-1.209h1.394l.7 1.209-.7 1.208Zm-.231-4.1-.7-1.208.7-1.207h1.393l.7 1.207-.7 1.208Zm7.063-2.417-.7-1.208.7-1.209h1.393l.7 1.209-.7 1.208Zm-4.044-.616-.7-1.207.7-1.208h1.393l.7 1.208-.7 1.207Z"
            />
        </g>
    </svg>
)

export default ColorPaletteSVG;