import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, {useState} from 'react';
// @ts-ignore
import Slider from 'react-slick';

import {TypoElementAnimatedStage} from '../../../api/models/typo/typo-element-animated_stage';
import CreatokenSVGComponent from '../../../assets/icons/creatoken.svg';
import {Alignment} from '../../../utils/_enums/alignment.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {Headline} from '../_shared/headline/headline';
import {TypoButtons} from '../_shared/typo_buttons/typo_buttons';
import styles from '../stage/stage.module.scss';
import {
    Props as StageImageButtonTokenProps,
    StageImageButtonToken
} from '../stage/stage-image-button-token/stage-image-button-token';
import containerStyles from './animated_stage.module.scss';

const AnimatedStage = (props: TypoElementAnimatedStage): JSX.Element => {
    
    const {
        animationSpeedClass,
        animationClass,
        contentVertical,
        contentHorizontal,
        contentSize,
        inverted,
        id,
        stages,
        fullwidth,
        wrapper
    } = props;
    
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const [sliderInverted, setSliderInverted] = useState<boolean>();

    const getAutoplaySpeed = () => {
        if (animationSpeedClass === 'slow') {
            return 10000;
        } else if (animationSpeedClass === 'slower') {
            return 8000;
        } else if (animationSpeedClass === 'fast') {
            return 6000;
        } else if (animationSpeedClass === 'faster') {
            return 4000;
        } else {
            return 5000;
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        afterChange: function(i: number) {
            if (stages && stages[i].contentStyle == 'inverted') {
                setSliderInverted(true);
            } else {
                setSliderInverted(false);
            }
        },
        autoplaySpeed: getAutoplaySpeed(),
    };

    const decButton = {
        icon: {
            element: <CreatokenSVGComponent />,
            align: 'right'
        }
    }

    if (stages && stages.length > 0) {
        return (
            <div
                id={`c${id}`}
                className={`
                    ${containerStyles['animated-stages-section']}
                    ${'spaceBefore' + appearance.spaceBefore} 
                    ${'spaceAfter' + appearance.spaceAfter} 
                    ${inverted ? containerStyles['inverted'] : ''}
                    ${sliderInverted ? containerStyles['slider-inverted'] : ''}
                    ${fullwidth ? containerStyles['fullwidth'] : ''}
                    ${wrapper !== undefined ? containerStyles[wrapper] : ''}
                `}
            >
                <div className={styles['headline']}>
                    <Headline {...props} />
                </div>
                <Slider {...settings}>
                    {
                        stages.map((element, i) => {
                            const verticalOrientation = contentVertical.toUpperCase() as 'TOP' | 'CENTER' | 'BOTTOM';
                            const size = contentSize.charAt(0).toUpperCase() + contentSize.slice(1) as 'Large' | 'Normal' | 'Small';
                            const orientation = contentHorizontal.toUpperCase() as 'LEFT' | 'RIGHT' | 'CENTER';
                            const textDirection = 'horizontal' as Alignment;
                            const buttons = element.buttons;
                            const img = element.media[0] ?? [];
                            const imgProps = img.properties ?? [];
                            const backgrounds = imgProps && imgProps.dimensions ? {
                                large: {
                                    ext: '.' + imgProps.extension,
                                    hash: 'large_typo_' + id,
                                    height: imgProps.dimensions.height,
                                    mime: imgProps.mimeType,
                                    name: imgProps.filename,
                                    path: null,
                                    size: parseFloat(imgProps.size),
                                    url: img.publicUrl,
                                    width: imgProps.dimensions.width
                                },
                            } : {};
                            return (
                                <div key={`animated-stage-${i}`} className={`${styles['stage']}  ${styles[size as string]} ${containerStyles['stage']}`}>
                                    <div
                                        className={`${styles['stage-background']} ${backgrounds || img.publicUrl ? '' : styles['default-background']}}`}
                                        style={backgrounds
                                            ? { backgroundImage: `url(${img.publicUrl ? img.publicUrl : ''})` }
                                            : undefined}
                                    >
                                        <div
                                            className={`${styles[orientation as string]} ${
                                                element.contentStyle == 'inverted' ? styles['inverted'] : ''
                                            } ${
                                                element.contentStyle == 'inverted' ? containerStyles['inverted'] : ''
                                            } ${styles[`vert-${verticalOrientation?.toLowerCase()}`]}`}
                                        >
                                            <div
                                                className={
                                                    textDirection === Alignment.HORIZONTAL
                                                        ? styles['horizontal-text-alignment']
                                                        : ''
                                                }
                                            >
                                                {element.headline && (
                                                    <div className={`${styles['title']}`}>
                                                        <Headline
                                                            {...props}
                                                            {...element}
                                                            header={element.headline}
                                                            subheader={element.subline}
                                                            headerPosition={element.headlineAlign}
                                                            headerLayout={0}
                                                            inverted={element.contentStyle == 'inverted'}
                                                            headerClass={`
                                    ${animationClass !== '' ? `animate__animated animate__${animationClass}` : ''}
                                     ${animationSpeedClass !== '' ? `animate__${animationSpeedClass}` : ''}
                                    `}
                                                            subHeaderClass={`
                                    ${animationClass !== '' ? `animate__animated animate__${animationClass}` : ''}
                                     ${animationSpeedClass !== '' ? `animate__${animationSpeedClass}` : ''}
                                    `}
                                                            variantHeader={'heading1' as TypographyVariantsEnum}
                                                            variantSubHeader={'introtext' as TypographyVariantsEnum}
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        textDirection === Alignment.HORIZONTAL
                                                            ? styles['flex-container']
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <TypoButtons inverted={element.contentStyle == 'inverted'} buttons={buttons} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Slider>
                <div className={containerStyles['decButtonContainer']}>
                    {(decButton as StageImageButtonTokenProps)?.image ? (
                        <div className={styles.decButtonCustomContainer}>
                            <StageImageButtonToken
                                {...(decButton as StageImageButtonTokenProps)}
                                shadow
                            />
                        </div>
                    ) : (
                        <div className={styles.decButtonContainer}>
                            {decButton?.icon?.element}
                        </div>
                    )}
                </div>
            </div>
        )
    } else return (<></>)
}

export { AnimatedStage };
