import Image from 'next/image';
import React from 'react';

import { useTypoContent } from '../../../../api/hooks/useTypoContent.hook';
import { TypoService } from '../../../../api/services/typo.service';
import discord from '../../../../assets/icons/discord.png';
import facebook from '../../../../assets/icons/facebook.png';
import instagram from '../../../../assets/icons/instagram.png';
import linkedin from '../../../../assets/icons/linkedin.png';
import twitter from '../../../../assets/icons/twitter.png';
import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../../typography/typography';
import styles from './footer-additional-links.module.scss';

interface Props {
    notReduced?: boolean;
    typoService?: TypoService;
}

export const FooterCopyright: React.FC<Props> = ({ notReduced, typoService }) => {

    if (!typoService) {
        typoService = new TypoService(useTypoContent());
    }

    return (
        <div className={styles['footer-additional-links']}>
            <div>
                <Typography
                    variant={TypographyVariantsEnum.INPUT}
                    element="span"
                >
                    {typoService.getLabel('page', 'footer-copyright')}
                </Typography>
            </div>
            {notReduced && (
                <div className={styles['footer-social-links']}>
                    <a
                        href={typoService.getLabel('page', 'url-facebook')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={facebook.src}
                            width={facebook.width}
                            height={facebook.height}
                            alt={'facebook'}
                        />
                    </a>
                    <a
                        href={typoService.getLabel('page', 'url-linkedin')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={linkedin.src}
                            width={linkedin.width}
                            height={linkedin.height}
                            alt={'linkedin'}
                        />
                    </a>

                    <a
                        href={typoService.getLabel('page', 'url-instagram')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={instagram.src}
                            width={instagram.width}
                            height={instagram.height}
                            alt={'instagram'}
                        />
                    </a>
                    <a
                        href={typoService.getLabel('page', 'url-discord')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={discord.src}
                            width={discord.width}
                            height={discord.height}
                            alt={'discord'}
                        />
                    </a>
                    <a
                        href={typoService.getLabel('page', 'url-twitter')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={twitter.src}
                            width={twitter.width}
                            height={twitter.height}
                            alt={'twitter'}
                        />
                    </a>
                </div>
            )}
        </div>
    );
};

FooterCopyright.defaultProps = { notReduced: false };
