import React from 'react';

import BookSVG from '../../../../assets/icons/book.svg';
import ColorPaletteSVG from '../../../../assets/icons/color-palette.svg';
import HeadphonesSVG from '../../../../assets/icons/headphones.svg';
import MenuSVG from '../../../../assets/icons/menu.svg';
import UnlockablesSVG from '../../../../assets/icons/unlockables.svg';
import {UniversalTeaserProps} from '../../../../utils/_models/universal-teaser-props';
import ToolTip from '../../tool-tip/tool-tip';
import styles from './card-header.module.scss';

interface CardHeaderProps extends UniversalTeaserProps {
    onToggleOverlay: () => void;
    isOverlayActive: boolean;
}

const CardHeader = (props: CardHeaderProps) => {

    const categorySVGs = [
        {id: 1, value: <HeadphonesSVG key={'headphones-svg'} fill={'white'}/>, label: props.productCategories?.audio},
        {id: 2, value: <BookSVG key={'book-svg'} fill={'white'}/>, label: props.productCategories?.text},
        {id: 3, value: <ColorPaletteSVG key={'color-palette-svg'} fill={'white'}/>, label: props.productCategories?.art}
    ];

    const renderCategories = () => {
        return (
            <div className={styles['categories']}>
                {categorySVGs
                    .filter(item => item.id === props.category)
                    .map((item) => (
                        <div
                            key={`category-icon-${item.id}`}>
                            <ToolTip toolTip={item.label ?? ''}>
                                {item.value}
                            </ToolTip>
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div className={styles['header']}>
            <div className={styles['left-header']}>
                {renderCategories()}
            </div>
            {props.unlockables
                ? <div className={styles['unlockables']}>
                    <ToolTip toolTip={props.generalStrings?.unlockables ?? ''}>
                        <UnlockablesSVG/>
                    </ToolTip>
                </div>
                : undefined}
            <MenuSVG
                className={`${styles['menu']} ${props.isOverlayActive ? styles['active'] : ''}`}
                onClick={props.onToggleOverlay}
            />
        </div>
    );
};

export default CardHeader;
