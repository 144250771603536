import {useEffect, useState} from 'react';

import {toCountdown} from '../_helper/time-helper';

export const useTimer = (timestamp?: number): {timerString: string; timer: number; done: boolean} => {
    if (!timestamp) {
        return {timerString: '', timer: 0, done: false};
    }
    const [timer, setTimer] = useState(Math.round((timestamp - Date.now()) / 1000));
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (Date.now() > timestamp) {
            setDone(true);
        } else {
            setTimer(Math.round((timestamp - Date.now()) / 1000));
            setDone(false);
        }
    }, [timestamp]);

    useEffect(() => {
        const timeout = setTimeout(() => setTimer(timer - 1), 1000);
        if (timer === -1) {
            setTimer(0);
            setDone(true);
        }
        return () => {
            clearTimeout(timeout);
        }
    }, [timer]);

    return {timerString: toCountdown(timer), timer, done};
};
