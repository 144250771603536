import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import {useTypoContent} from '../../../api/hooks/useTypoContent.hook';
import {CommentFormData} from '../../../api/models/typo/form-data/comment-form-data';
import {
    TypoElementComment,
    TypoElementCommentGroup,
    TypoElementComments
} from '../../../api/models/typo/typo-element-comments';
import {TypoService} from '../../../api/services/typo.service';
import {RootState} from '../../../redux';
import {sendCommentForm} from '../../../redux/slices/comment.slice';
import {useAppSelector} from '../../../utils/_hooks/hooks';
import {Button} from '../../_shared/button/button';
import TextArea from '../../_shared/input-fields/text-area/text-area';
import {Typography} from '../../_shared/typography/typography';
import {Accord} from '../accord/accord';
import {Comment} from './comment/comment';
import styles from './comments.module.scss';

const Comments = (props: TypoElementComments): JSX.Element => {

    const { id, pid, header, comments, allowedCommentGroups, commentLimit, inverted, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    const dispatch = useDispatch();
    const typoService = new TypoService(useTypoContent());
    const [commentList, setCommentList] = useState<TypoElementComment[]>(comments);
    const {executeRecaptcha} = useGoogleReCaptcha();

    const {user, supportModel} = useAppSelector((state: RootState) => ({
        user: state.userSlice.user,
        supportModel: state.supportSlice.supportModel
    }));
    const userid = user?.id;

    const schema = yup.object({
        comment: yup
            .string()
            .trim()
            .test(
                'len',
                typoService.getLabel('comments', 'form-comment-length'),
                (val) => {
                    if (val === undefined) {
                        return true;
                    }
                    return val.length === 0 || (val.length >= 2 && val.length <= 800);
                }
            )
            .required(
                typoService.getLabel('comments', 'form-comment-required')
            )
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<CommentFormData>({
        mode: 'onTouched',
        // @ts-ignore
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        reset({
            comment: ''
        });
    }, [commentList]);

    const saveComment: SubmitHandler<CommentFormData> = (form) => {
        if (executeRecaptcha && userid) {
            const token = executeRecaptcha('comment');
            token.then(async (res) => {

                const resp: any = await dispatch(
                    sendCommentForm({
                        ...form,
                        username: user.username,
                        parent: id,
                        replyid: 0,
                        pid: pid,
                        token: res
                    } as CommentFormData)
                );

                if (resp.payload.success && resp.payload.data) {
                    const newList = [resp.payload.data].concat(commentList);
                    setCommentList(newList);
                }
            })
        }
    };

    const allowCommentaring = (): boolean => {
        // Todo: Feature request pushed back in priority. Only allow user that owns tokens in allowedCommentGroups to comment
        // Also make the template below work
        if (allowedCommentGroups && allowedCommentGroups.length > 0 && userid) {
            return true;
        } else if (userid) {
            return true;
        }
        return false;
    }
    
    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['comments-section']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <Accord id={99999} childrenHeadline={header} inverted={inverted}>
                <div className={styles['comments']}>
                    {commentList && commentList.length > 0 ? (
                        Object.keys(commentList).map((key: string) => {
                            if (commentLimit !== undefined && commentLimit > 0 && (key as unknown as number) >= commentLimit) {
                                return;
                            }
                            const element = commentList[key] as TypoElementComment;
                            return (<Comment key={key} inverted={props.inverted} {...element} />);
                        })
                    ) : (
                        <div className={styles['noComments']}>
                            <Typography
                                inverted={inverted}
                            >
                                {typoService.getLabel('comments', 'no-comments')}
                            </Typography>
                        </div>
                    )}
                </div>
                {allowCommentaring() ? (
                    <div className={styles['controls']}>
                        <TextArea
                            name={'comment'}
                            className={styles['text-area']}
                            register={register}
                            inverted={props.inverted}
                            errorMessage={errors.comment?.message}
                            placeholder={typoService.getLabel('comments', 'form-comment-placeholder')}
                        />
                        <div className={styles['button-wrapper']}>
                            <Button
                                inverted={props.inverted}
                                onClick={handleSubmit(saveComment)}
                                pending={supportModel.pending}
                            >{typoService.getLabel('comments', 'form-comment-button')}</Button>
                        </div>
                    </div>
                ) : (
                    <div className={styles['login-message']}>
                        {false ? (
                            <>
                                <Typography
                                    inverted={inverted}
                                >
                                    {typoService.getLabel('comments', 'login-message-token')}
                                </Typography>
                                <ul>
                                    {
                                        Object.keys(allowedCommentGroups).map((key: string) => {
                                            const group = allowedCommentGroups[key] as TypoElementCommentGroup;
                                            return (
                                                <li key={key}>
                                                    <Typography
                                                        inverted={inverted}
                                                    >{group.title}</Typography>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </>
                        ) : (
                            <Typography
                                inverted={inverted}
                            >
                                {typoService.getLabel('comments', 'login-message')}
                            </Typography>
                        )}
                    </div>
                )}
            </Accord>
        </div>
    );
};

Comments.defaultProps = {};

export { Comments };
