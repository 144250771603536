import supportedLang from '../../config/supportedLang.json';
import { isServer } from '../../utils/_helper/isServer';
import { useIsStorybook } from './useIsStorybook.hook';

export const useCurrentLanguage = (): string => {
    const isStorybook = useIsStorybook();
    if (isServer() || isStorybook) return 'en';
    const currentLang = location.pathname.split('/')?.[1];

    if (supportedLang.includes(currentLang)) return currentLang;
    else return 'en';
};
