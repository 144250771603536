import React from 'react';

import {TypoElementImage} from '../../../api/models/typo/typo-element-image';
import {Headline} from '../_shared/headline/headline';
import {Multirow} from '../_shared/multirow/multirow';
import styles from './image.module.scss';

export const Image = (props: TypoElementImage): JSX.Element => {
    const { id, inverted, images, gallery, fullwidth, wrapper, columnsMobile, columnsTablet, imageEffect, withSubline, enlargeImageOnClick } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['typoimage']} 
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className={styles['typoimage-container']}>
                {images && (
                    <div className={styles['images']}>
                        <Multirow
                            images={images}
                            rowsDesktop={gallery.count.columns}
                            rowsTablet={columnsTablet}
                            rowsMobile={columnsMobile}
                            galleryOptions={{
                                imageEffect: {
                                    effect: imageEffect ?? '',
                                    withSubline: withSubline ?? false
                                },
                                enlargeOnClick: enlargeImageOnClick
                            }}
                            inverted={inverted}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};