import {NextSeo} from 'next-seo';
import React from 'react';

import {useContextSsr} from '../../../api/hooks/useContextSSr.hook';
import {StrapiMeta} from '../../../api/models/strapi/strapi-meta';
import {ArticlePageTypes} from '../../../utils/_enums/article-page-types';

export interface Props {
    tags?: StrapiMeta;
    title?: string;
    articlePageType?: ArticlePageTypes;
    languageAlternates?: | ReadonlyArray<{ hrefLang: string; href: string; }> | undefined;
    locale?: string;
    localeAlternate?: string;
    isTypoPage?: boolean;
}

export const MetaTagsResolver: React.FC<Props> & { dataHooks: any } = ({
   tags,
   title,
   articlePageType,
   isTypoPage,
   languageAlternates
}) => {
    const {host, locale} = useContextSsr();

    const protocol = host?.includes('creatokia') ? 'https://' : 'http://';

    if (tags) {
        return (
            <NextSeo
                title={title ?? tags?.metaTagTitle}
                description={!isTypoPage ? tags?.metaOgDescription : tags?.metaTagDescription}
                canonical={tags?.metaTagCanonical}
                openGraph={{
                    description: tags?.metaOgDescription,
                    title: tags?.metaOgTitle,
                    site_name: tags?.metaOgSiteName,
                    locale: locale,
                    images: [
                        {
                            url: tags?.MetaOgImage?.url ?? '',
                            alt: tags?.MetaOgImage?.alternativeText,
                            height: tags?.MetaOgImage?.height,
                            width: tags?.MetaOgImage?.width
                        }
                    ],
                    url: tags?.metaOgUrl,
                    type: tags?.metaOgType
                }}
                twitter={{site: tags?.metaTwitterSite}}
                nofollow={tags?.metaNoFollow}
                noindex={tags?.metaNoIndex}
                languageAlternates={
                    languageAlternates && languageAlternates.length > 0
                        ? languageAlternates
                        : tags?.alternateLinkEn && tags?.alternateLinkDe
                        ? [
                            {
                                hrefLang: 'de',
                                href: `${protocol}${host}/de/${articlePageType ? 
                                    (articlePageType === ArticlePageTypes.ARTICLE_PAGE || 
                                        articlePageType === ArticlePageTypes.ARTICLE_PAGE_STRAPIID) ? 's/' : 'c/' : ''
                                }${tags?.alternateLinkDe}`
                            },
                            {
                                hrefLang: 'en',
                                href: `${protocol}${host}/en/${articlePageType ? 
                                    (articlePageType === ArticlePageTypes.ARTICLE_PAGE ||
                                        articlePageType === ArticlePageTypes.ARTICLE_PAGE_STRAPIID) ? 's/' : 'c/' : ''
                                }${tags?.alternateLinkEn}`
                            }
                        ]
                        : []
                }
                additionalMetaTags={[
                    {
                        property: 'og:locale:alternative',
                        content: locale === 'de' ? 'en' : 'de'
                    }
                ]}
            />
        );
    } else {
        return <></>;
    }
};

MetaTagsResolver.dataHooks = [useContextSsr];
