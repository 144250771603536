import React, {Reducer, useReducer} from 'react';

import {DefaultHeaderMobileLoginFormContextEnum} from '../../../../../../utils/_enums/DefaultHeaderMobileLoginFormContext.enum';

interface DefaultHeaderMobileLoginFormContextData {
    loginFormOpen: boolean;
    mobileNavOpen: boolean;
    myAccountNavOpen: boolean;
    dispatch: React.Dispatch<{ type: DefaultHeaderMobileLoginFormContextEnum }>;
}

const initialData = {
    loginFormOpen: false,
    mobileNavOpen: false,
    myAccountNavOpen: false,
    dispatch: () => {
        alert('Error');
        return;
    }
};

const reducer: Reducer<DefaultHeaderMobileLoginFormContextData,
    { type: DefaultHeaderMobileLoginFormContextEnum }> = (state = initialData, action) => {
    switch (action.type) {
        case DefaultHeaderMobileLoginFormContextEnum.TOGGLELOGINFORM: {
            return {
                ...state,
                mobileNavOpen: !state.loginFormOpen ? false : state.mobileNavOpen,
                loginFormOpen: !state.loginFormOpen
            }
        }
        case DefaultHeaderMobileLoginFormContextEnum.TOGGLEMOBILENAVIGATION: {
            return {
                ...state,
                loginFormOpen: !state.mobileNavOpen ? false : state.loginFormOpen,
                mobileNavOpen: !state.mobileNavOpen
            }
        }
        case DefaultHeaderMobileLoginFormContextEnum.TOGGLEMYACCOUNTNAVIGATION: {
            return {
                ...state,
                myAccountNavOpen: !state.myAccountNavOpen
            }
        }
        case DefaultHeaderMobileLoginFormContextEnum.RESET: {
            return {
                ...state,
                loginFormOpen: false,
                mobileNavOpen: false,
                myAccountNavOpen: false
            }
        }
        default:
            return state;
    }
};

export const DefaultHeaderMobileLoginFormContext = React.createContext<DefaultHeaderMobileLoginFormContextData>(
    initialData
);

export const DefaultHeaderMobileLoginFormContextProvider: React.FC = ({
                                                                          children
                                                                      }) => {
    const [state, dispatch] = useReducer(reducer, initialData);

    return (
        <DefaultHeaderMobileLoginFormContext.Provider
            value={{...state, dispatch}}
        >
            {children}
        </DefaultHeaderMobileLoginFormContext.Provider>
    );
};
