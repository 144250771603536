import {useRouter} from 'next/dist/client/router';
import React, {useEffect, useState} from 'react';

import {useTypoContent} from '../../../api/hooks/useTypoContent.hook';
import {TypoElementCountdown} from '../../../api/models/typo/typo-element-countdown';
import {TypoLink} from '../../../api/models/typo-link';
import {TypoService} from '../../../api/services/typo.service';
import {ButtonVariantsEnum} from '../../../utils/_enums/button-variants.enum';
import {Button} from '../../_shared/button/button';
import {LinkBase} from '../../_shared/link/link-base/link-base';
import {Headline} from '../_shared/headline/headline';
import styles from './countdown.module.scss';

const Countdown = (props: TypoElementCountdown): JSX.Element => {

    const { id, inverted, contentTextTag, contentTextLink, contentTextPosition, label, datetimer } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const headerAlignment = contentTextPosition ? 'text-' + contentTextPosition : '';
    const typoService = new TypoService(useTypoContent());

    const date = new Date(datetimer * 1000)
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const dateWithOffset = new Date(date.getTime() + timezoneOffset);
    const countDownDate = dateWithOffset.getTime();

    const [stopCounter, setStopCounter] = useState<boolean>();
    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    let headerLink: TypoLink | undefined = undefined;
    if (stopCounter) {
        const router = useRouter();
        const lang = router.locale ? router.locale.toLowerCase() : 'en';
        headerLink = contentTextLink && typeof contentTextLink.href !== 'undefined' ? contentTextLink : {
            'href': `/${lang}/digital-originals`,
            'target': null,
            'class': null,
            'title': null,
            'linkText': '',
            'additionalAttributes': []
        };
    }

    useEffect(() => {
        if (!stopCounter) {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDownDate]);

    const getReturnValues = (countDown: number) => {
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        const generateLabel = (label: number): string => {
            if (label <= 0) {
                return '00';
            } else if (label <= 9 && label > 0) {
                return `0${label}`;
            }
            return label.toString();
        }

        if (!stopCounter && days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
            setStopCounter(true);
        }

        const text = label && label !== '' ? label : typoService.getLabel('content-elements', 'buy-now-countdown');
        return stopCounter ? text : `${generateLabel(days)}:${generateLabel(hours)}:${generateLabel(minutes)}:${generateLabel(seconds)}`;
    };

    const getButton = () => {
        return (
            <Button
                variant={ButtonVariantsEnum.PRIMARY}
                disabled={!stopCounter}
                inverted={inverted}
                className="height-auto"
            >
                {stopCounter ? (
                    getReturnValues(countDown)
                ) : (
                    <Headline
                        {...props}
                        header={getReturnValues(countDown)}
                        bodytext=""
                        subheader=""
                        headerPosition="center"
                        headerLink=""
                        headerLayout={contentTextTag}
                        noContentSpacing={true}
                        inverted={true}
                        noHeadlineSpacing={true}
                    />
                )}
            </Button>
        );
    }

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['countdown']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className="bodytext-container">
                <div className={`${styles[headerAlignment]} ${stopCounter ? '' : styles['monospace']}`}>
                    {stopCounter && headerLink ? (
                        <LinkBase
                            href={headerLink.href}
                            external={headerLink.target !== '' && headerLink.target !== null}
                            title={typeof headerLink.title === 'string' ? headerLink.title : ''}
                            className={typeof headerLink.class === 'string' ? headerLink.class : ''}
                            locale={false}
                        >
                            {getButton()}
                        </LinkBase>
                    ) : getButton()}
                </div>
            </div>
        </div>
    );
};

Countdown.defaultProps = {};

export { Countdown };
