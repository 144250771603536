import React from 'react';

import { TypoElementQuote } from '../../../api/models/typo/typo-element-quote';
import { TypographyVariantsEnum } from '../../../utils/_enums/typeography-variants.enum';
import { Typography } from '../../_shared/typography/typography';
import { Headline } from '../_shared/headline/headline';
import styles from './quote.module.scss';

const Quote = (props: TypoElementQuote): JSX.Element => {

    const { id, inverted, bodytext } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    return (
        <div id={`c${id}`}
             className={`              
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
             `}
        >
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className={styles['quote-section']}>
                <div className={styles['quote-wrapper']}>
                    <Typography
                        className={`${styles['quote']} ${inverted ? styles['inverted'] : ''}`}
                        variant={TypographyVariantsEnum.QUOTE}
                        inverted={inverted}
                    >
                        {bodytext}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

Quote.defaultProps = {};

export { Quote };
