import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import {
    LoadError,
    LocalizationMap,
    PageChangeEvent,
    ProgressBar,
    RenderPageProps,
    ScrollMode,
    SpecialZoomLevel,
    Viewer
} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin, ToolbarProps, ToolbarSlot} from '@react-pdf-viewer/default-layout';
import de_DE from '@react-pdf-viewer/locales/lib/de_DE.json';
import en_US from '@react-pdf-viewer/locales/lib/en_US.json';
import es_ES from '@react-pdf-viewer/locales/lib/es_ES.json';
import {themePlugin} from '@react-pdf-viewer/theme';
import {useRouter} from 'next/dist/client/router';
import React, {ReactElement, useLayoutEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import {TypoElementPdf} from '../../../api/models/typo/typo-element-pdf';
import {Headline} from '../_shared/headline/headline';
import styles from './pdf.module.scss';

export const Pdf = (props: TypoElementPdf): JSX.Element => {

    const { id, inverted, height, width, scrollMode, media, fullwidth, wrapper } = props;
    const [navbarSpace, setNavbarSpace] = useState(120);
    const [resized, setResized] = useState(false);
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const pdfUrl = media && media[0] && media[0].publicUrl ? media[0].publicUrl : '';
    const [signedPdfUrl, setSignedPdfUrl] = useState();
    const scroll = scrollMode && scrollMode === 'horizontal' ? ScrollMode.Horizontal : ScrollMode.Vertical;
    const router = useRouter();
    const lang = router.locale ? router.locale.toLowerCase() : 'en';
    const locale = () => {
        switch (lang) {
            case 'de': return de_DE;
            case 'es': return es_ES;
            default: return en_US;
        }
    }
    const themePluginInstance = themePlugin();
    const [cookies, setCookie] = useCookies(['pdf_token']);
    const tokenName = 'pdf-' + (id ?? 0);

    // Get signed pdf url
    if (!signedPdfUrl) {
        try {
            if (pdfUrl) {
                const AWS = require('aws-sdk');
                const s3 = new AWS.S3({
                    signatureVersion: 'v4',
                    accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
                    region: process.env.NEXT_PUBLIC_AWS_REGION
                });
                const params = {
                    Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET,
                    Key: encodeURIComponent(pdfUrl.replace(/^.*\/\/[^\/]+\/?/, '').trim()),
                    Expires: 60
                };
                const url = s3.getSignedUrl('getObject', params);
                if (url) {
                    setSignedPdfUrl(url);
                }
            }
        } catch (err) {
            console.error(`SignedURL: ${err}`);
        }
    }

    const handleResize = () => {
        if (typeof document !== 'undefined') {
            const height = document.getElementById('main-navbar')?.offsetHeight;
            setNavbarSpace(height ?? 120);
            setResized(true);
        }
    }

    useLayoutEffect(() => {
        if (!resized) {
            handleResize();
        }
        window.addEventListener('resize', handleResize, false);
    }, []);

    /**
     * Save current page for user to come back to after visiting the viewer again
     *
     * @param e
     */
    const onPageChange = (e: PageChangeEvent) => {
        if (tokenName === 'pdf-0') {
            return;
        }

        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        setCookie('pdf_token', {
            ...cookies.pdf_token,
            [tokenName]: e.currentPage
        }, { path: '/', expires });
    }

    /**
     * Top toolbar
     *
     * @param Toolbar
     */
    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(props: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ZoomIn,
                    Zoom,
                    ZoomOut,
                    ShowSearchPopover,
                    EnterFullScreen
                } = props;
                return (
                    <>
                        <div className={styles['toolbar-left']}>
                            <div className={`${styles['toolbar-item']} ${styles['hide-mobile']}`}>
                                <ShowSearchPopover />
                            </div>
                            <div className={styles['toolbar-item']}>
                                <GoToPreviousPage />
                            </div>
                            <div className={styles['toolbar-item']}>
                                <CurrentPageInput />
                            </div>
                            <div className={`${styles['toolbar-item']} ${styles['hide-mobile']}`}>
                                / <NumberOfPages />
                            </div>
                            <div className={styles['toolbar-item']}>
                                <GoToNextPage />
                            </div>
                        </div>
                        <div className={styles['toolbar-center']}>
                            <div className={styles['toolbar-item']}>
                                <ZoomOut />
                            </div>
                            <div className={styles['toolbar-item']}>
                                <Zoom />
                            </div>
                            <div className={styles['toolbar-item']}>
                                <ZoomIn />
                            </div>
                        </div>
                        <div className={styles['toolbar-right']}>
                            <div className={styles['toolbar-item']}>
                                <EnterFullScreen />
                            </div>
                        </div>
                    </>
                );
            }}
        </Toolbar>
    );

    /**
     * Render page without the ability that user can highlight text
     *
     * @param props
     */
    const renderPage = (props: RenderPageProps) => {
        return (
            <>
                {props.canvasLayer.children}
                <div style={{ userSelect: 'none' }}>
                    {props.textLayer.children}
                </div>
                {props.annotationLayer.children}
            </>
        );
    };

    /**
     * Shows a loading bar while loading the pdf file
     *
     * @param percentages
     */
    const renderLoader = (percentages: number) => (
        <div className={styles['loader']}>
            <ProgressBar progress={Math.round(percentages)} />
        </div>
    )

    /**
     * Display error while trying to load the pdf file
     *
     * The `LoadError` type consists of two properties:
     * - `message` which indicates the error message
     * - `name` which indicates the name of exception
     *
     * The `name` property can take one of the values below:
     * - `AbortException`
     * - `FormatError`
     * - `InvalidPDFException`
     * - `MissingPDFException`
     * - `PasswordException`
     * - `UnexpectedResponseException`
     * - `UnknownErrorException`
     *
     * @param error
     */
    const renderError = (error: LoadError) => {
        let message: string;
        switch (error.name) {
            case 'InvalidPDFException':
                message = 'The document is invalid or corrupted';
                break;
            case 'MissingPDFException':
                message = 'The document is missing';
                break;
            case 'UnexpectedResponseException':
                message = 'Unexpected server response';
                break;
            case 'UnknownErrorException':
                message = 'An unknown error occurred';
                break;
            default:
                message = 'Cannot load the document';
                break;
        }

        return (
            <div className={styles['error-container']}>
                <div className={styles['error']}>
                    {message}
                </div>
            </div>
        );
    }

    /**
     * Complete toolbar inclusive sidebar
     */
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [
            defaultTabs[0], // Bookmarks tab
            defaultTabs[1], // Thumbnails tab
        ],
    });

    return (
        <div
            id={`c${id}`}
            className={`
                rpv-core__viewer
                ${styles['pdf-container']} 
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <div className={styles['headline']}>
                <Headline {...props} />
            </div>
            <div className={`${styles['pdf']}`} style={{ height: (height ? `calc(${height}vh - ${navbarSpace}px)` : 'auto'), maxWidth: (width ? width + 'px' : 'none') }}>
                <Viewer
                    fileUrl={signedPdfUrl ?? pdfUrl}
                    renderLoader={renderLoader}
                    renderError={renderError}
                    renderPage={renderPage}
                    onPageChange={onPageChange}
                    theme={inverted ? 'light' : 'dark'}
                    scrollMode={scroll}
                    defaultScale={SpecialZoomLevel.PageFit}
                    localization={locale as unknown as LocalizationMap}
                    initialPage={cookies.pdf_token && cookies.pdf_token[tokenName] && tokenName !== 'pdf-0' ? cookies.pdf_token[tokenName] : undefined}
                    plugins={[defaultLayoutPluginInstance, themePluginInstance]}
                />
            </div>
        </div>
    );
};