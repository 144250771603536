import React from 'react';

import styles from './audio-bar.module.scss';

interface AudioBarInterface {
    trackProgress: number;
    duration: number;
    onScrub: (value: number) => void;
    onScrubEnd: () => void;
    trackStyling: string;
}

const AudioBar = (props: AudioBarInterface): JSX.Element => {

    const { trackProgress, duration, onScrub,
        onScrubEnd, trackStyling } = props;

    return (
        <div className={styles['bar']}>
            <input
                type="range"
                value={trackProgress}
                step="1"
                min="0"
                max={duration ? duration : `${duration}`}
                className={styles['progress']}
                onChange={(e) => onScrub(e.target.value as unknown as number)}
                onMouseUp={onScrubEnd}
                onKeyUp={onScrubEnd}
                style={{ background: trackStyling }}
            />
        </div>
    );
};

AudioBar.defaultProps = {};

export { AudioBar };
