import React from 'react';
import {UseFormRegister} from 'react-hook-form';

import {Alignment} from '../../../utils/_enums/alignment.enum';
import {ValueStringMapping} from '../../../utils/_models/value-string-mapping';
import {ErrorMessage} from '../error/error-message';
import styles from './radio-button-group.module.scss';

export interface RadioButtonGroupProps<T> {
    options: ValueStringMapping<T>[];
    register: UseFormRegister<any>;
    name: string;
    label: string;
    disabled?: boolean;
    direction?: Alignment;
    errorMessage?: string;
}

const RadioButtonGroup = <T extends unknown>(props: RadioButtonGroupProps<T>): JSX.Element => {
    return (
        <div className={`${styles['radio-button-group']} ${props.disabled ? styles['disabled'] : ''}`}>
            <div className={styles['label']}>
                <label>
                    <span>{props.label}</span>
                </label>
            </div>
            <div
                className={`${styles['radio-buttons']}
                ${props.direction === Alignment.VERTICAL ? styles[Alignment.VERTICAL] : ''}`}
            >
                {props.options.map((option) => (
                    <label
                        key={option.value as any}
                        className={`${styles['container']} ${props.disabled ? styles['disabled'] : ''}`}>
                        <div className={styles['checkmark-outer-border-wrapper']}>
                            <div className={styles['checkmark-inner-border-wrapper']}>
                                <input
                                    disabled={props.disabled}
                                    {...props.register(props.name)}
                                    type="radio"
                                    value={option.value as any}
                                />
                                <span className={styles['checkmark']}/>
                            </div>
                        </div>
                        {option.label}
                    </label>
                ))}
            </div>
            <ErrorMessage>{props.errorMessage}</ErrorMessage>
        </div>
    );
};

export default RadioButtonGroup;
