import React from 'react';

import { TypoElementDiv } from '../../../api/models/typo/typo-element-div';
import styles from './div.module.scss';

const DivTypo = (props: TypoElementDiv): JSX.Element => {

    const { id, inverted , fullwidth, wrapper} = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['div']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
            `}
        >
            <hr />
        </div>
    );
};

DivTypo.defaultProps = {};

export { DivTypo };
