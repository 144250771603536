import React from 'react';

import {StrapiLinkProps} from '../../../../api/models/strapi/strapi-link-props';
import {TypographyVariantsEnum} from '../../../../utils/_enums/typeography-variants.enum';
import {LinkBase} from '../../link/link-base/link-base';
import {Spacer} from '../../spacer/spacer';
import {Typography} from '../../typography/typography';
import styles from './footer-link-container.module.scss';

export interface Props {
    headline: string;
    links: StrapiLinkProps[];
}

export const FooterLinkContainer: React.FC<Props> = ({links, headline}) => {
    return (
        <div className={styles['footer-link-container']}>
            <Typography
                style={{color: 'white'}}
                disableMarkdown
                variant={TypographyVariantsEnum.HEADING5}
            >
                {headline}
            </Typography>
            <Spacer height="40px"/>
            <div className={styles['footer-link-body']}>
                {links?.map(({linkText: value, url: href, external}, index) => (
                    <LinkBase
                        key={href ?? index}
                        href={href ?? ''}
                        target={external ? '_target' : undefined}
                    >
                        <Typography
                            disableMarkdown
                            variant={TypographyVariantsEnum.FOOTER_LINK}
                        >
                            {value}
                        </Typography>
                    </LinkBase>
                ))}
            </div>
        </div>
    );
};
