// @ts-nocheck
import React from 'react';

import { TypoElementBullets } from '../../../api/models/typo/typo-element-bullets';
import { Headline } from '../_shared/headline/headline';
import styles from '../text/text.module.scss';
import { TypoOrderedList } from './ordered-list/ordered-list';
import { TypoUnorderedList } from './unordered-list/unordered-list';

const TypoBullets = (props: TypoElementBullets): JSX.Element => {
    const { id, inverted, bodytext, bulletsType, fullwidth, wrapper } = props;
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };

    let list;

    switch (bulletsType) {
        case 0:
            list = <TypoUnorderedList id={id} bulletsType={bulletsType} bodytext={bodytext} />;
            break;
        case 1:
            list = <TypoOrderedList id={id} bulletsType={bulletsType} bodytext={bodytext} />;
            break;
        default:
            list = <TypoUnorderedList id={id} bulletsType={bulletsType} bodytext={bodytext} />;
    }

    return (
        <div
            id={`c${id}`}
      className={`
        ${'spaceBefore' + appearance.spaceBefore} 
        ${'spaceAfter' + appearance.spaceAfter} 
        ${inverted ? styles['inverted'] : ''}
        ${fullwidth ? styles['fullwidth'] : ''}
        ${wrapper !== undefined ? styles[wrapper] : ''}
      `}
    >
      <div className={styles['headline']}>
        <Headline {...props} />
      </div>
      {list}
    </div>
  );
};

TypoBullets.defaultProps = {};

export { TypoBullets };