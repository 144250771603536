import React, { useEffect, useState } from 'react';

import { Close } from '../../../../assets/icons/close.svg';
import { ButtonVariantsEnum } from '../../../../utils/_enums/button-variants.enum';
import { Button } from '../../button/button';
import styles from './header-mobile-nav.module.scss';
export interface Props {
    className?: string;
    backgroundColor?: string;
    onClick: () => void;
    cancelButtonOffset?: number;
}

export const HeaderMobilePopup: React.FC<Props> = ({
    children,
    onClick,
    backgroundColor,
    className,
    cancelButtonOffset
}) => {
    const ref = React.createRef<HTMLDivElement>();
    const [containerHeight, setContainerHeight] = useState(0);
    useEffect(() => {
        const height = ref.current?.clientHeight;

        if (height && height < 400) {
            setContainerHeight(400);
        } else if (height) {
            setContainerHeight(height);
        }
    }, [children]);

    return (
        <div
            className={`${styles['header-mobile-container']} ${
                className ?? ''
            }`}
        >
            <div
                ref={ref}
                className={styles['header-mobile-nav']}
                style={{
                    clipPath: `polygon(0 0, 100% 0, 100% calc(calc(100% - calc(calc(${containerHeight}px / 100) * 10)) + 0.5px), calc(calc(100% - calc(calc(${
                        containerHeight - (containerHeight / 100) * 45 
                    }px / 100) * 10)) + 0.5px) 100%, 0 100%)`,
                    backgroundColor
                }}
            >
                <div
                    className={styles['header-mobile-nav-container']}
                    style={{
                        clipPath: `polygon(0 50%,1px 50%,1px 1px,calc(100% - 1px) 1px,calc(100% - 1px) calc(100% - calc(calc(${containerHeight}px / 100) * 10)),calc(100% - calc(calc(${
                            containerHeight - (containerHeight/ 100) * 45 
                        }px / 100) * 10)) calc(100% - 1px),1px calc(100% - 1px),50% calc(100% - 1px),1px calc(100% - 1px),1px 50%,0 50%,0 100%,100% 100%,100% 50%,100% 0,0 0)`
                    }}
                />
                {children}
            </div>
            <Button
                style={
                    cancelButtonOffset
                        ? {
                              right: cancelButtonOffset - 44
                          }
                        : undefined
                }
                onClick={onClick}
                className={styles['cancelButton']}
                variant={ButtonVariantsEnum.SECONDARY_FILLED}
                icon={{
                    element: (
                        <Close/>
                    ),
                    align: 'right'
                }}
            />
        </div>
    );
};

HeaderMobilePopup.defaultProps = { cancelButtonOffset: 0 };
