import {Tooltip} from '@mui/material';
import React from 'react';

import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {Typography} from '../typography/typography';
import styles from './tool-tip.module.scss';

interface ToolTipProps {
    children?: React.ReactNode;
    toolTip: string;
}

const ToolTip = (props: ToolTipProps): JSX.Element => {
    return (
        <Tooltip title={
            <Typography inverted variant={TypographyVariantsEnum.CAPTION} className={styles['tool-tip-typo']}>
                {props.toolTip}
            </Typography>
        }
                 placement={'top-start'} arrow>
            <div>
                {props.children ? props.children : '?'}
            </div>
        </Tooltip>
    );
};

export default ToolTip;
