import React, { CSSProperties } from 'react';

import { useCardText } from '../../../../api/hooks/useCardText.hook';
import { BasicCardVariants } from '../../../../utils/_enums/basic-card-variants.enum';
import { Currency } from '../../../../utils/_enums/currency.enum';
import { TypographyVariantsEnum } from '../../../../utils/_enums/typeography-variants.enum';
import { getLocaleEthPrice, getLocalePrice } from '../../../../utils/_helper/locale-helper';
import { Link } from '../../link/link';
import { LinkBase } from '../../link/link-base/link-base';
import { Typography } from '../../typography/typography';
import styles from './teaser-card-text.module.scss';

export interface TeaserCardTextProps {
    variant: BasicCardVariants;
    headline?: string;
    subHeadline?: string;
    topline?: string;
    toplineStyle?: CSSProperties;
    description?: string;
    author?: string;
    serialNumber?: number;
    paidPrice?: number;
    currency?: Currency;
    tokenId?: number;
    redirectUrl?: string;
    readMore?: () => void;
    inverted?: boolean;
    headlineElement?: string;
    headlineVariant?: TypographyVariantsEnum;
    headlineStyle?: CSSProperties;
    subHeadlineElement?: string;
    subHeadlineVariant?: TypographyVariantsEnum;
    subHeadlineStyle?: CSSProperties;
}

const TeaserCardText = (props: TeaserCardTextProps): JSX.Element => {
    const data = useCardText();

    const getSubHeadlineVariant = (): TypographyVariantsEnum => {
        switch (props.variant) {
            case BasicCardVariants.TEXT_ONLY_TEASER_NORMAL:
                return TypographyVariantsEnum.HEADING5_LIGHT;
            case BasicCardVariants.TEXT_ONLY_TEASER_LARGE:
                return TypographyVariantsEnum.HEADING4_LIGHT;
            default:
                return TypographyVariantsEnum.HEADING5_LIGHT;
        }
    };

    const generateDescription = (): JSX.Element => {
        let updatedDescription;

        if (props.description && props.readMore) {
            updatedDescription = (
                <div>
                    <Typography inverted={props.inverted}>
                        {props.description}
                    </Typography>
                    {props.readMore &&
                        <Link
                            onClick={props.readMore}
                            href={''}
                            className={styles['read-more']}
                        >
                            {data?.readMore ?? ''}
                        </Link>
                    }
                </div>
            );
        } else {
            updatedDescription = (
                <Typography inverted={props.inverted}>{props.description ?? ''}</Typography>
            );
        }

        return updatedDescription;
    };

    return (
        <div
            className={`${styles['product-card-details-wrapper']} ${styles[`${props.variant ? props.variant : ''}`]
            }`}
        >
            {props.topline && (
                <div
                    className={`${styles['topline']} ${styles[`topline-${props.variant}`]
                    }`}
                >
                    <Typography
                        variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                        inverted={props.inverted}
                        disableMarkdown={true}
                        style={props.toplineStyle ?? undefined}
                    >
                        {props.topline}
                    </Typography>
                </div>
            )}

            {props.variant === BasicCardVariants.TEXT_IMAGE_TEASER ?
                (
                    <>
                        {props.headline && (
                            <Typography
                                inverted={props.inverted}
                                variant={props.headlineVariant ? props.headlineVariant : TypographyVariantsEnum.HEADING3_BOLD}
                                element={props.headlineElement ?? undefined}
                                style={props.headlineStyle ?? undefined}
                            >
                                {props.headline}
                            </Typography>
                        )}
                    </>
                ) :
                (
                    <div className={styles['headline-wrapper']}>

                        {props.redirectUrl ?
                            <LinkBase href={props.redirectUrl ?? ''}>
                                <Typography
                                    disableMarkdown={true}
                                    className={`${styles['headline']} ${props.redirectUrl
                                        ? styles['headline-clickable']
                                        : ''
                                    }`}
                                    inverted={props.inverted}
                                    variant={props.headlineVariant ? props.headlineVariant : TypographyVariantsEnum.HEADING6}
                                    element={props.headlineElement ?? 'span'}
                                    style={props.headlineStyle ?? undefined}
                                >
                                    {props.headline}
                                </Typography>
                            </LinkBase> :
                            <Typography
                                disableMarkdown={true}
                                inverted={props.inverted}
                                className={`${styles['headline']} ${props.redirectUrl
                                    ? styles['headline-clickable']
                                    : ''
                                }`}
                                variant={props.headlineVariant ? props.headlineVariant : TypographyVariantsEnum.HEADING6}
                                element={props.headlineElement ?? 'span'}
                                style={props.headlineStyle ?? undefined}
                            >
                                {props.headline}
                            </Typography>
                        }
                    </div>
                )}

            {props.subHeadline && (
                <div className={styles['sub-headline']}>
                    <Typography
                        inverted={props.inverted}
                        disableMarkdown={true}
                        variant={props.subHeadlineVariant ? props.subHeadlineVariant : getSubHeadlineVariant()}
                        element={props.subHeadlineElement ?? 'span'}
                        style={props.subHeadlineStyle ?? undefined}
                    >
                        {props.subHeadline}
                    </Typography>
                </div>
            )}

            {props.description && (
                <div
                    className={`${styles['description-wrapper']} ${styles[`description-wrapper-${props.variant}`]
                    }`}
                >
                    <div
                        className={`${styles['description']} ${styles[`description-${props.variant}`]}`}
                    >
                        {generateDescription()}
                    </div>
                </div>
            )}

            {props.serialNumber && props.variant === BasicCardVariants.PURCHASE_CARD_NORMAL &&
                <div className={styles['optional-info']}>
                    <Typography
                        inverted={props.inverted}
                        variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                        disableMarkdown={true}
                    >
                        {props.serialNumber?.toString()}
                    </Typography>
                </div>
            }

            {props.paidPrice &&
                (
                    <div className={styles['paid-price-wrapper']}>
                        <Typography
                            inverted={props.inverted}
                            variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                            disableMarkdown={true}
                        >
                            {data?.pricePaid ?? ''}
                        </Typography>
                        <Typography
                            inverted={props.inverted}
                            disableMarkdown={true}
                            variant={TypographyVariantsEnum.HEADING4_BOLD}
                            className={styles['paid-price']}
                            element={'span'}
                        >
                            {props.paidPrice === -1
                                ? '-'
                                : props.currency === Currency.EUR
                                    ? getLocalePrice(props.paidPrice) + ' €'
                                    : getLocaleEthPrice(props.paidPrice) + 'Ξ'}
                        </Typography>
                    </div>
                )
            }

            {props.tokenId && (
                <div className={styles['token-id-wrapper']}>
                    <Typography
                        inverted={props.inverted}
                        variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                        disableMarkdown={true}
                    >
                        {data?.tokenId ?? ''}
                    </Typography>
                    <Typography
                        inverted={props.inverted}
                        disableMarkdown={true}
                        variant={TypographyVariantsEnum.HEADING6}
                        className={styles['token-id']}
                        element={'span'}
                    >
                        {props.tokenId.toString()}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default TeaserCardText;
