import React, {useEffect, useRef, useState} from 'react';

import {TypoElementMp3} from '../../../api/models/typo/typo-element-mp3';
import {Tracks} from '../../../api/models/typo/typo-element-mp3-item';
import { HorizontalPosition } from '../../../utils/_enums/horizontal-position.enum';
import { getMediaType, Media } from '../../_shared/media/media';
import { Headline } from '../_shared/headline/headline';
import { TypoButtons } from '../_shared/typo_buttons/typo_buttons';
import styles from './mp3.module.scss';
import {BigTemplate} from './templates/big/big-template';
import {SmallTemplate} from './templates/small/small-template';

const Mp3 = (props: TypoElementMp3): JSX.Element => {

    const {
        id,
        inverted,
        headerPosition,
        template,
        media,
        gallery,
        buttons,
        fullwidth,
        wrapper
    } = props;

    if(media.length <= 0) {
        return <div/>;
    }

    /*--Element container specific--*/
    const appearance = props.appearance ?? { spaceBefore: '', spaceAfter: '' };
    const imagePosition = headerPosition ? headerPosition.toLowerCase() : '';

    const textClass = (imagePosition: string) => {
        if (imagePosition === HorizontalPosition.LEFT) {
            return styles['left-wrapper'];
        } else if (imagePosition === HorizontalPosition.CENTER) {
            return styles['center-wrapper'];
        } else {
            return styles['right-wrapper'];
        }
    }

    const imageClass = (imagePosition: string) => {
        if (imagePosition === HorizontalPosition.LEFT) {
            return styles['right-wrapper-img'];
        } else if (imagePosition === HorizontalPosition.CENTER) {
            return styles['center-wrapper-img'];
        } else {
            return styles['left-wrapper-img'];
        }
    }

    const generateTrackList = (): Tracks[] => {
        const mp3MediaTracks: Tracks[] = [];
        if (media && media.length > 0) {
            for (const key in media) {
                const m = media[key];
                mp3MediaTracks[key] = {
                    title: m.properties.title as string,
                    description: m.properties.description as string,
                    audioSrc: m.publicUrl,
                    image: gallery?.rows[1]?.columns[trackIndex+1]?.publicUrl,
                    autoplay: m.properties.autoplay
                };
            }
        }

        return mp3MediaTracks;
    }

    /*--Audio/MP3 controls--*/
    const [trackIndex, setTrackIndex] = useState(0);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(60);
    const [muteVolume, setMuteVolume] = useState(false);
    const [autoplayUsed, setAutoplayUsed] = useState(false);

    const tracks = generateTrackList();
    const { audioSrc, autoplay } = tracks[trackIndex];

    const audioRef = useRef(null as unknown as HTMLAudioElement);
    useEffect(() => {
        audioRef.current = new Audio(audioSrc);
    }, [])

    const intervalRef = useRef();
    const isReady = useRef(false);

    const { duration } = audioRef.current ?? 0;
    const currentPercentage = duration ? `${Math.ceil((trackProgress / duration) * 100)}%` : '0%';
    const colorGrading = inverted ? '#ffffff' : '#000000';
    const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, ${colorGrading}), color-stop(${currentPercentage}, #bababa))`;

    useEffect(() => {
        if (!autoplayUsed && autoplay) {
            audioRef.current.play();
            setAutoplayUsed(true);
        }

        // Pause and clean up on unmount
        return () => {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        }
    }, []);

    // Handle setup when changing tracks
    useEffect(() => {
        audioRef.current.pause();

        audioRef.current = new Audio(audioSrc);
        setTrackProgress(audioRef.current.currentTime);

        if (isReady.current) {
            audioRef.current.play();
            setIsPlaying(true);
            startTimer();
        } else {
            // Set the isReady ref as true for the next pass
            isReady.current = true;
        }
    }, [trackIndex]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        } else {
            clearInterval(intervalRef.current);
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        if (audioRef) {
            audioRef.current.volume = volume / 100;
            audioRef.current.muted = muteVolume;
        }
    }, [volume, audioRef, muteVolume]);

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);

        // @ts-ignore
        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                toNextTrack();
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, 100);
    }

    const onScrub = (value: number) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    }

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    }

    const toPrevTrack = () => {
        if (trackIndex - 1 < 0) {
            setTrackIndex(tracks.length - 1);
        } else {
            setTrackIndex(trackIndex - 1);
        }
    }

    const toNextTrack = () => {
        if (trackIndex < tracks.length - 1) {
            setTrackIndex(trackIndex + 1);
        } else if (tracks.length === 1 && trackIndex === tracks.length - 1) {
            setIsPlaying(false);
        } else {
            setTrackIndex(0);
        }
    }

    const formatTime = (timeInSeconds: number): string => {
        if (!timeInSeconds) {
            return '00:00';
        }

        const minutes = Math.floor(Math.ceil(timeInSeconds) / 60);
        const seconds = Math.floor(Math.ceil(timeInSeconds) % 60);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const mp3Template = () => {
        if (template === 'smallCover') {
            return <SmallTemplate
                track={tracks[trackIndex]}
                tracks={tracks.length}
                onScrub={onScrub}
                onScrubEnd={onScrubEnd}
                toPrevTrack={toPrevTrack}
                toNextTrack={toNextTrack}
                formatTime={formatTime}
                currentPercentage={currentPercentage}
                trackStyling={trackStyling}
                colorGrading={colorGrading}
                audioRef={audioRef}
                trackProgress={trackProgress}
                duration={duration}
                muteVolume={muteVolume}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                volume={volume}
                setVolume={setVolume}
                setMuteVolume={setMuteVolume}
            />;
        } else if (template === 'bigCover') {
            return <BigTemplate
                track={tracks[trackIndex]}
                tracks={tracks.length}
                onScrub={onScrub}
                onScrubEnd={onScrubEnd}
                toPrevTrack={toPrevTrack}
                toNextTrack={toNextTrack}
                formatTime={formatTime}
                currentPercentage={currentPercentage}
                trackStyling={trackStyling}
                colorGrading={colorGrading}
                audioRef={audioRef}
                trackProgress={trackProgress}
                duration={duration}
                muteVolume={muteVolume}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                volume={volume}
                setVolume={setVolume}
                setMuteVolume={setMuteVolume}
            />;
        } else {
            if (!media || media.length <= 0) {
                return;
            }

            const mp3Media = media[0];
            return (
                <Media
                    mediaId={id}
                    contentType={getMediaType(mp3Media.properties.mimeType)}
                    src={mp3Media.publicUrl}
                    alt={mp3Media.properties.alternative ?? ''}
                    autoplay={mp3Media.properties.autoplay ?? false}
                    caption={mp3Media.properties.description ?? ''}
                    title={mp3Media.properties.title ?? ''}
                    redirectUrl={mp3Media.properties.link ?? ''}
                    inverted={inverted}
                />
            );
        }
    }

    return (
        <div
            id={`c${id}`}
            className={`
                ${styles['text-image-teaser']}
                ${'spaceBefore' + appearance.spaceBefore} 
                ${'spaceAfter' + appearance.spaceAfter} 
                ${inverted ? styles['inverted'] : ''}
                ${fullwidth ? styles['fullwidth'] : ''}
                ${wrapper !== undefined ? styles[wrapper] : ''}
                ${imagePosition === HorizontalPosition.CENTER ? styles['text-image-center'] : ''}
            `}>
            <div className={`${textClass(imagePosition)}`}>
                <Headline {...props} noHeadlineSpacing={true} noTextAlignment={true} variant="teaser" />
                <TypoButtons inverted={inverted} buttons={buttons} />
            </div>
            <div
                className={`${imageClass(imagePosition)}`}>
                {mp3Template()}
            </div>
        </div>
    );
};

Mp3.defaultProps = {};

export { Mp3 };
